import React, { useState, useEffect } from "react";
import { Input, Select, Radio, Button, Switch, Space, Checkbox } from "antd";
import {
  MdRadioButtonChecked,
  MdCheckBox,
  MdKeyboardArrowDown,
  MdOutlineDeleteOutline,
  MdCheckBoxOutlineBlank,
  MdOutlineTextFields,
} from "react-icons/md";
import { IoIosRadioButtonOff, IoIosAddCircleOutline } from "react-icons/io";
import { RxTextAlignRight } from "react-icons/rx";
import { BsTextParagraph } from "react-icons/bs";
import { FaCloudUploadAlt, FaRegCopy } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "../../../hooks/ReorderIcon";

const { TextArea } = Input;

const CreateNewSurvey = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [formTemplateObject, setFormTemplateObject] = useState({
    id: `${Date.now()}`,
    surveyName: "",
    surveyType: "",
    canBeAnonymous: false,
    dateCreated: `${new Date().toLocaleDateString("en-GB")}`,
    status: "inactive",
    surveyUrl: "",
    respondentInfo: [
      {
        id: 1,
        questionTitle: "Gender",
        questionOptions: ["Male", "Female"],
        required: true,
        answer: "",
      },
      {
        id: 2,
        questionTitle: "Age",
        questionOptions: [
          "Under 18",
          "18 - 24",
          "25 - 34",
          "35 - 44",
          "45 - 54",
          "55 and above",
        ],
        required: true,
        answer: "",
      },
      {
        id: 3,
        questionTitle: "Occupation",
        questionOptions: [
          "Student",
          "Self-employed",
          "Employed",
          "Partially Employed",
        ],
        required: true,
        answer: "",
      },
      {
        id: 4,
        questionTitle: "Income",
        questionOptions: [
          "Under 100k",
          "100k - 200k",
          "200k - 350k",
          "350k - 1M",
        ],
        required: true,
        answer: "",
      },
    ],
    destination: {
      start_date: "",
      end_date: "",
      recipient: "",
      selected_departments: [],
      selected_employees: [],
    },
    questionsItems: [
      {
        id: 1,
        questionTitle: "Question 1",
        questionDescription: "",
        questionType: "radio",
        questionOptions: ["Option 1"],
        file: {
          maximum_no_of_files: 0,
          file_size: 0,
        },
        required: false,
        isQuestionDescription: false,
        answer: "",
      },
    ],
  });

  const [editQuestionOption, setEditQuestionOption] = useState({
    id: "",
    option: "",
  });

  const dragControls = useDragControls();

  const sharedInputProps = (props) => {
    return {
      style: {
        width: props === "file" ? "10rem" : "20rem",
        marginLeft: 10,
        borderBottom: "5px solid var(--clr-primary)",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderRadius: props === "file" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  const questionTypeOptions = [
    {
      value: "radio",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdRadioButtonChecked size={20} />
          <span className="fs-6">Radio Button</span>
        </div>
      ),
    },
    {
      value: "checkbox",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdCheckBox size={20} />
          <span className="fs-6">Checkbox</span>
        </div>
      ),
    },
    {
      value: "short",
      label: (
        <div className="d-flex align-items-center gap-1">
          <RxTextAlignRight size={20} />
          <span className="fs-6">Short Answer</span>
        </div>
      ),
    },
    {
      value: "paragraph",
      label: (
        <div className="d-flex align-items-center gap-1">
          <BsTextParagraph size={20} />
          <span className="fs-6">Paragraph</span>
        </div>
      ),
    },
    {
      value: "dropdown",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdKeyboardArrowDown size={20} />
          <span className="fs-6">Dropdown</span>
        </div>
      ),
    },
    {
      value: "file",
      label: (
        <div className="d-flex align-items-center gap-1">
          <FaCloudUploadAlt size={20} />
          <span className="fs-6">File Upload</span>
        </div>
      ),
    },
  ];

  const postTemplate = async (survey) => {
    const response = await fetch("http://localhost:8000/survey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(survey),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleCreateTemplate = async () => {
    setIsLoading(true);

    try {
      const result = await postTemplate(formTemplateObject);
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleQuestionItemChange = (id, name, value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            [name]: value,
          };
        }
        return question;
      }),
    }));
  };

  const handleQuestionOptionsChange = (id, value, index) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            questionOptions: question.questionOptions.map((option, i) => {
              if (i === index) {
                return value;
              }
              return option;
            }),
          };
        }
        return question;
      }),
    }));
  };

  const handleAddQuestionOptions = (id) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            questionOptions: [
              ...question.questionOptions,
              `Option ${question.questionOptions.length + 1}`,
            ],
          };
        }
        return question;
      }),
    }));
  };

  const handleFileOptionsSettingsChange = (id, name, value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            file: {
              ...question.file,
              [name]: value,
            },
          };
        }
        return question;
      }),
    }));
  };

  const handleRemoveQuestionOptions = (id, index) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            questionOptions: question.questionOptions.filter(
              (_, i) => i !== index
            ),
          };
        }
      }),
    }));
  };

  const handleAddQuestionItem = () => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: [
        ...prevFormTemplateObject.questionsItems,
        {
          id: prevFormTemplateObject.questionsItems.length + 1,
          questionTitle: `Question ${
            prevFormTemplateObject.questionsItems.length + 1
          }`,
          questionDescription: "",
          questionType: "radio",
          questionOptions: ["Option 1"],
          file: {
            maximum_no_of_files: 0,
            file_size: 0,
          },
          required: false,
          isQuestionDescription: false,
          answer: "",
        },
      ],
    }));
  };

  const handleRemoveQuestionItem = (id) => {
    if (formTemplateObject.questionsItems.length === 1) {
      return;
    }
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.filter(
        (question) => question.id !== id
      ),
    }));
  };

  const handleDuplicateQuestionItem = (id) => {
    setFormTemplateObject((prevFormTemplateObject) => {
      const questionToDuplicate = prevFormTemplateObject.questionsItems.find(
        (question) => question.id === id
      );

      const duplicatedQuestion = {
        ...questionToDuplicate,
        id: prevFormTemplateObject.questionsItems.length + 1,
        questionTitle: `${questionToDuplicate.questionTitle} (copy)`,
        questionDescription: questionToDuplicate.questionDescription,
        questionOptions: questionToDuplicate.questionOptions.map(
          (option) => `${option}`
        ),
        file: questionToDuplicate.file,
        required: questionToDuplicate.required,
        isQuestionDescription: questionToDuplicate.isQuestionDescription,
      };

      return {
        ...prevFormTemplateObject,
        questionsItems: [
          ...prevFormTemplateObject.questionsItems,
          duplicatedQuestion,
        ],
      };
    });
  };

  const handleEditQuestionOption = (id, option) => {
    setEditQuestionOption({ id, option });
  };

  useEffect(() => {
    console.log("formTemplateObject", formTemplateObject);
  }, [formTemplateObject]);

  const handleReorder = (newOrder) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: newOrder,
    }));
  };

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3" id="create__template__body">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Create Survey Template</h3>
            <p>Build your custom survey form</p>
          </div>
        </div>

        <form className="create__request__form">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Survey Name:</label>
            <Input
              placeholder="Survey Name"
              size="large"
              value={formTemplateObject?.surveyName}
              onChange={(e) => {
                setFormTemplateObject((prevFormTemplateObject) => ({
                  ...prevFormTemplateObject,
                  surveyName: e.target.value,
                }));
              }}
              style={{
                border: "2px solid black",
                width: "20rem",
              }}
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Survey Type:</label>
            <Radio.Group
              onChange={(e) => {
                setFormTemplateObject((prevFormTemplateObject) => ({
                  ...prevFormTemplateObject,
                  surveyType: e.target.value,
                }));
              }}
              value={formTemplateObject?.surveyType}
            >
              <Radio value={"internal"}>
                <span className="fs-6">Internal</span>
              </Radio>
              <Radio value={"external"}>
                <span className="fs-6">External</span>
              </Radio>
            </Radio.Group>
          </div>

          <div className="">
            <Checkbox
              onChange={(e) => {
                setFormTemplateObject((prevFormTemplateObject) => ({
                  ...prevFormTemplateObject,
                  canBeAnonymous: e.target.checked,
                }));
              }}
              checked={formTemplateObject?.canBeAnonymous}
            >
              <span className="fs-6">Respondents can opt to be Anonymous</span>
            </Checkbox>
          </div>

          <div className="d-flex flex-column gap-3 mt-3">
            <Reorder.Group
              axis="y"
              onReorder={handleReorder}
              values={formTemplateObject.questionsItems}
              className="d-flex flex-column gap-5 mt-5"
            >
              {formTemplateObject.questionsItems.map((question, index) => (
                <Reorder.Item
                  className="d-flex gap-3 relative"
                  id={question.id}
                  value={question}
                  key={question.id}
                  dragListener={true}
                  dragControls={dragControls}
                >
                  <div className="form__item">
                    <div className="d-flex justify-content-center">
                      <ReorderIcon dragControls={dragControls} />
                    </div>
                    <div className="d-flex justify-content-between align-items-end gap-4">
                      <Input
                        placeholder="Question"
                        size="large"
                        value={question.questionTitle}
                        onChange={(e) => {
                          handleQuestionItemChange(
                            question.id,
                            "questionTitle",
                            e.target.value
                          );
                        }}
                        style={{
                          borderBottom: "5px solid var(--clr-primary)",
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          width: "70%",
                        }}
                      />
                      <Select
                        showSearch
                        placeholder="Select an option"
                        size="large"
                        optionFilterProp="children"
                        value={question?.questionType}
                        onChange={(value) => {
                          handleQuestionItemChange(
                            question.id,
                            "questionType",
                            value
                          );
                        }}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        defaultValue={"radio"}
                        style={{
                          width: "15rem",
                          marginLeft: 10,
                          borderBottom: "5px solid var(--clr-primary)",
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          borderRadius: "10px",
                        }}
                        options={questionTypeOptions}
                      />
                    </div>
                    {question?.isQuestionDescription && (
                      <TextArea
                        rows={3}
                        placeholder="Question Description"
                        value={question?.questionDescription}
                        onChange={(e) => {
                          handleQuestionItemChange(
                            question.id,
                            "questionDescription",
                            e.target.value
                          );
                        }}
                        {...sharedInputProps("paragraph")}
                      />
                    )}

                    <div className="form__item__answer__section">
                      {question?.questionType === "radio" ? (
                        <div className="">
                          <Space direction="vertical">
                            {question?.questionOptions?.map((option, index) => (
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ width: "25rem" }}
                                key={index}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <IoIosRadioButtonOff size={20} />
                                  <span className="fs-6">
                                    {editQuestionOption?.id === question.id &&
                                    editQuestionOption?.option === index ? (
                                      <Input
                                        autoFocus
                                        placeholder={`Option ${index + 1}`}
                                        size="large"
                                        value={option}
                                        onChange={(e) => {
                                          handleQuestionOptionsChange(
                                            question.id,
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            handleAddQuestionOptions(
                                              question.id
                                            );
                                            handleEditQuestionOption(
                                              question.id,
                                              index + 1
                                            );
                                          }
                                        }}
                                        onBlur={() =>
                                          handleEditQuestionOption(
                                            question.id,
                                            undefined
                                          )
                                        }
                                        {...sharedInputProps()}
                                      />
                                    ) : (
                                      <label
                                        className="fs-6"
                                        style={{
                                          cursor: "pointer",
                                          width: "20rem",
                                        }}
                                        onClick={() =>
                                          handleEditQuestionOption(
                                            question.id,
                                            index
                                          )
                                        }
                                      >
                                        {option}
                                      </label>
                                    )}
                                  </span>
                                </div>
                                {question?.questionOptions?.length > 1 && (
                                  <IoCloseOutline
                                    size={25}
                                    cursor={"pointer"}
                                    onClick={() =>
                                      handleRemoveQuestionOptions(
                                        question.id,
                                        index
                                      )
                                    }
                                  />
                                )}
                              </div>
                            ))}
                            <div
                              className="d-flex align-items-center gap-2"
                              onClick={() => {
                                handleAddQuestionOptions(question.id);
                                handleEditQuestionOption(
                                  question.id,
                                  question?.questionOptions?.length
                                );
                              }}
                            >
                              <IoIosRadioButtonOff size={20} />
                              <span className="fs-6">Add Option</span>
                            </div>
                          </Space>
                        </div>
                      ) : question?.questionType === "checkbox" ? (
                        <div className="">
                          <Space direction="vertical">
                            {question?.questionOptions?.map((option, index) => (
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ width: "25rem" }}
                                key={index}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <MdCheckBoxOutlineBlank size={20} />
                                  <span className="fs-6">
                                    {editQuestionOption?.id === question.id &&
                                    editQuestionOption?.option === index ? (
                                      <Input
                                        autoFocus
                                        placeholder={`Option ${index + 1}`}
                                        size="large"
                                        value={option}
                                        onChange={(e) => {
                                          handleQuestionOptionsChange(
                                            question.id,
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            handleAddQuestionOptions(
                                              question.id
                                            );
                                            handleEditQuestionOption(
                                              question.id,
                                              index + 1
                                            );
                                          }
                                        }}
                                        onBlur={() =>
                                          handleEditQuestionOption(
                                            question.id,
                                            undefined
                                          )
                                        }
                                        {...sharedInputProps()}
                                      />
                                    ) : (
                                      <label
                                        className="fs-6"
                                        style={{
                                          cursor: "pointer",
                                          width: "20rem",
                                        }}
                                        onClick={() =>
                                          handleEditQuestionOption(
                                            question.id,
                                            index
                                          )
                                        }
                                      >
                                        {option}
                                      </label>
                                    )}
                                  </span>
                                </div>
                                {question?.questionOptions?.length > 1 && (
                                  <IoCloseOutline
                                    size={25}
                                    cursor={"pointer"}
                                    onClick={() =>
                                      handleRemoveQuestionOptions(
                                        question.id,
                                        index
                                      )
                                    }
                                  />
                                )}
                              </div>
                            ))}
                            <div
                              className="d-flex align-items-center gap-2"
                              onClick={() => {
                                handleAddQuestionOptions(question.id);
                                handleEditQuestionOption(
                                  question.id,
                                  question?.questionOptions?.length
                                );
                              }}
                            >
                              <MdCheckBoxOutlineBlank size={20} />
                              <span className="fs-6">Add Option</span>
                            </div>
                          </Space>
                        </div>
                      ) : question?.questionType === "dropdown" ? (
                        <div className="d-flex flex-column gap-2">
                          {question?.questionOptions?.map((option, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center fs-6 justify-content-between"
                              style={{ width: "25rem" }}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <span className="">{index + 1}.</span>
                                {editQuestionOption?.id === question.id &&
                                editQuestionOption?.option === index ? (
                                  <Input
                                    autoFocus
                                    placeholder={`Option ${index + 1}`}
                                    size="large"
                                    value={option}
                                    onChange={(e) => {
                                      handleQuestionOptionsChange(
                                        question.id,
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleAddQuestionOptions(question.id);
                                        handleEditQuestionOption(
                                          question.id,
                                          index + 1
                                        );
                                      }
                                    }}
                                    onBlur={() =>
                                      handleEditQuestionOption(
                                        question.id,
                                        undefined
                                      )
                                    }
                                    {...sharedInputProps()}
                                  />
                                ) : (
                                  <label
                                    className="fs-6"
                                    style={{
                                      cursor: "pointer",
                                      width: "20rem",
                                    }}
                                    onClick={() =>
                                      handleEditQuestionOption(
                                        question.id,
                                        index
                                      )
                                    }
                                  >
                                    {option}
                                  </label>
                                )}
                              </div>
                              {question?.questionOptions?.length > 1 && (
                                <IoCloseOutline
                                  size={25}
                                  cursor={"pointer"}
                                  onClick={() =>
                                    handleRemoveQuestionOptions(
                                      question.id,
                                      index
                                    )
                                  }
                                />
                              )}
                            </div>
                          ))}
                          <div
                            className="d-flex align-items-center gap-2"
                            onClick={() => {
                              handleAddQuestionOptions(question.id);
                              handleEditQuestionOption(
                                question.id,
                                question?.questionOptions?.length
                              );
                            }}
                          >
                            <span className="">
                              {question?.questionOptions?.length + 1}.
                            </span>
                            <span className="fs-6">Add Option</span>
                          </div>
                        </div>
                      ) : question?.questionType === "file" ? (
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex justify-content-between align-items-center gap-3 fs-6">
                            <label className="fs-6">
                              Maximum number of files
                            </label>
                            <Select
                              size="large"
                              optionFilterProp="children"
                              onSearch={onSearch}
                              filterOption={filterOption}
                              value={question?.file?.maximum_no_of_files}
                              onChange={(value) => {
                                handleFileOptionsSettingsChange(
                                  question.id,
                                  "maximum_no_of_files",
                                  value
                                );
                              }}
                              {...sharedInputProps("file")}
                              options={[
                                {
                                  value: 1,
                                  label: 1,
                                },
                                {
                                  value: 5,
                                  label: 5,
                                },
                                {
                                  value: 10,
                                  label: 10,
                                },
                              ]}
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-3 fs-6">
                            <label className="fs-6">Maximum file size</label>
                            <Select
                              size="large"
                              optionFilterProp="children"
                              onSearch={onSearch}
                              filterOption={filterOption}
                              value={question?.file?.file_size}
                              onChange={(value) => {
                                handleFileOptionsSettingsChange(
                                  question.id,
                                  "file_size",
                                  value
                                );
                              }}
                              {...sharedInputProps("file")}
                              options={[
                                {
                                  value: 1,
                                  label: "1 MB",
                                },
                                {
                                  value: 5,
                                  label: "5 MB",
                                },
                                {
                                  value: 10,
                                  label: "10 MB",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ) : question?.questionType === "short" ? (
                        <Input
                          size="large"
                          value={"Short Answer Here"}
                          {...sharedInputProps()}
                          disabled
                        />
                      ) : question?.questionType === "paragraph" ? (
                        <TextArea
                          rows={4}
                          value={"Paragraph Answer Here"}
                          maxLength={6}
                          {...sharedInputProps("paragraph")}
                          disabled
                        />
                      ) : null}
                    </div>

                    <div className="form__item__footer">
                      <div className="d-flex gap-3">
                        <FaRegCopy
                          size={22}
                          cursor={"pointer"}
                          onClick={() =>
                            handleDuplicateQuestionItem(question.id)
                          }
                        />
                        <MdOutlineDeleteOutline
                          size={25}
                          cursor={"pointer"}
                          onClick={() => handleRemoveQuestionItem(question.id)}
                        />
                      </div>
                      <div className="form__item__footer__divider"></div>
                      <div className="d-flex align-items-center gap-3">
                        <span className="fs-6">Required</span>
                        <Switch
                          defaultChecked={question?.required}
                          onChange={(checked) =>
                            handleQuestionItemChange(
                              question.id,
                              "required",
                              checked
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__item__side__menu">
                    <IoIosAddCircleOutline
                      size={30}
                      cursor={"pointer"}
                      onClick={handleAddQuestionItem}
                    />
                    <MdOutlineTextFields
                      size={30}
                      cursor={"pointer"}
                      onClick={() =>
                        handleQuestionItemChange(
                          question.id,
                          "isQuestionDescription",
                          !question.isQuestionDescription
                        )
                      }
                    />
                  </div>
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </div>

          <div className="d-flex justify-content-center gap-3 mt-3 w-100">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
                width: "5rem",
              }}
              onClick={handleCreateTemplate}
              // loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewSurvey;
