import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  useGetAllBenefitsInKind,
  useGetAllSystemGrades,
  useGetAllSystemSteps,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  all_system_grades,
  user_errors,
} from "../../../util/selectors/userSelectors";
import {
  addEditGrades,
  deleteGrade,
  gradeGrade,
} from "../../../redux/users/userActions";

import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { formatMoney } from "../../../util/helpers";

function GradesList() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled_ben, setEnabledBen] = useState(true);
  const [enabled_stp, setEnabledStp] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const [addDept, setAddDept] = useState(false);

  const [values, setValues] = useState({
    name: "",
    code: "",
  });


  const { refetch, isLoading } = useGetAllSystemGrades(enabled, setEnabled);

  const { data: ben_data } = useGetAllBenefitsInKind(
    enabled_ben,
    setEnabledBen,
    ""
  );
  const { data: stp_data } = useGetAllSystemSteps(enabled_stp, setEnabledStp);
  const back_errors = useShallowEqualSelector(user_errors);

  const sys_grades = useShallowEqualSelector(all_system_grades);

  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    Table.EXPAND_COLUMN,
    {
      title: " Grade",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Pay grade",
      dataIndex: "pay_grade_count",
      key: "pay_grade_count",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Action",
      dataIndex: "code",
      key: "code",
      render: (_, item) => {
        return (
          <GradesEachList
            item={item}
            handleSelected={handleSelected}
            ben_data={ben_data}
            stp_data={stp_data}
            refetch={refetch}
          />
        );
      },
    },
  ];

  const clearSelected = () => {
    setSelected({});
    setValues({
      name: "",
      code: "",
    });
  };

  const handleAddDept = () => {
    clearSelected();
    setAddDept(!addDept);
  };

  const handleSelected = (item) => {
    // console.log(item);
    setSelected(item);
    setValues(item);
    setAddDept(true);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(values);
    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditGrades(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          name: "",
          code: "",
        });

        if (Object.keys(selected).length) {
          // change steps to grades later when it has been corrected form the back end
          dispatch({
            type: "UPDATE_TO_GRADES",
            payload: res.payload.grade,
          });
        } else {
          dispatch({
            type: "ADD_TO_GRADES",
            payload: res.payload.grade,
          });
        }
        setAddDept(false);
      }
      // console.log({ back_errors });
    });
  };

  useEffect(() => {
    if (sys_grades.length) {
      const modData = sys_grades.map((item, index) => {
        return {
          ...item,
          key: index + 1,
          pay_grade_count: item.gradings.length ? item.gradings.length : "0",
        };
      });
      setData(modData);
    } else {
      setData([]);
    }
  }, [sys_grades]);

  console.log({ sys_grades });
  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4 ">
          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                padding: "1rem",
              }}
            >
              <div>
                <div className="d-flex gap-3 align-items-center">
                  <div className="circle__bagde fw-bold">{data?.length}</div>
                  <h6 className="mb-0"> Grades</h6>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {!addDept && (
                  <Button className="myBtn  mt-3" onClick={handleAddDept}>
                    Add Grade
                  </Button>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}
                {addDept ? (
                  <form className="onboarding-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="name">Name</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.name && back_errors.name[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Grade name"
                            type="text"
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="code">Code</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.code && back_errors.code[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            type="text"
                            id="code"
                            name="code"
                            value={values.code || ""}
                            onChange={handleChange}
                            placeholder="Grade code"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mt-2">
                      {/* {Object.keys(selected).length ? (
                    
                  ) : null} */}
                      <div>
                        <Button
                          className="myBtn mt-3 mx-2"
                          onClick={() => {
                            clearSelected();
                            handleAddDept();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="myBtn  mt-3"
                          htmlType="submit"
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div
                    // style={{
                    //   maxHeight: 500,
                    //   overflowY: "auto",
                    // }}
                    className="my-3"
                  >
                    {isLoading ? (
                      <SkeletonUI number={2} />
                    ) : data.length ? (
                      <>
                        <Table
                          dataSource={data}
                          columns={columns}
                          expandable={{
                            expandedRowRender: (record) => (
                              <Card
                                title={`${record.name} details`}
                                bordered={false}
                                style={{
                                  maxHeight: 300,
                                  overflowY: "auto",
                                }}
                              >
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Step</th>
                                      <th scope="col">
                                        {" "}
                                        Gross salary (&#x20A6;)
                                      </th>
                                      <th scope="col">Benefits</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {record.gradings.map((item, ind) => (
                                      <>
                                        <tr>
                                          <th scope="row" key={item.id}>
                                            {ind + 1}
                                          </th>
                                          <td>{item?.step?.name || "N/A"}</td>
                                          <td>
                                            {item?.salary
                                              ? formatMoney(item?.salary)
                                              : "N/A"}
                                          </td>
                                          <td>
                                            <Space wrap>
                                              {item?.benefits?.length
                                                ? item.benefits.map((itm) => (
                                                    <>
                                                      <div className="badge bg-primary p-1">
                                                        <span>
                                                          {itm.benefit}
                                                        </span>{" "}
                                                        --{" "}
                                                        <span>{itm.value}</span>{" "}
                                                      </div>
                                                    </>
                                                  ))
                                                : "N/A"}
                                            </Space>
                                          </td>
                                          <td>
                                            <EachTableAction
                                              item={item}
                                              ben_data={ben_data}
                                              stp_data={stp_data}
                                              refetch={refetch}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </Card>
                            ),
                            rowExpandable: (record) => record.gradings.length,
                          }}
                          pagination={data.length > 10 ? true : false}
                        />
                      </>
                    ) : (
                      <NoContent
                        message={"No system grade available."}
                        width={"400px"}
                      />
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  {!addDept && (
                    <Button className="myBtn  mt-3" onClick={handleAddDept}>
                      Add Grade
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const GradesEachList = ({
  item,
  handleSelected,
  refetch,
  ben_data,
  stp_data,
}) => {
  const record = item;
  const dispatch = useDispatch();

  const mod_benefit = record?.benefit?.map((item) => item.id) || [];

  // console.log({ ben_data, mod_benefit });
  const location = useLocation();

  console.log("location", location?.pathname);

  const back_errors = useShallowEqualSelector(user_errors);
  const [loadingDel, setLoadingDel] = useState(false);
  const [loading, setLoading] = useState(false);

  const [step_id, setStepId] = useState("");
  const [salary, setSalary] = useState("");
  const [benefits, setBenefit] = useState([]);
  const [ben_options, setBenOptions] = useState([]);
  const [stp_options, setStpOptions] = useState([]);

  const [open_modal, setOpenModal] = useState(false);

  const [grade_arry, setGradeArry] = useState([
    {
      grade_id: record.id,
      step_id: "",
      benefit_ids: [],
      salary: "",
      has_steps: 1,
    },
  ]);

  function handleModal() {
    setOpenModal(!open_modal);
  }

  function updateGrade(e) {
    e.preventDefault();

    setLoading(true);
    gradeGrade(dispatch, { grading: [...grade_arry] }).then(() => {
      setLoading(false);
      refetch();
    });
  }

  const handleSelChange = (value) => {
    setBenefit([...value]);
  };

  useEffect(() => {
    if (ben_data && Object.keys(ben_data).length) {
      const opt = ben_data.payload.map((item) => {
        return {
          label: `${item.benefit}[${item.value}]`,
          value: item.id,
        };
      });

      setBenOptions(opt);
    }
  }, [ben_data]);
  useEffect(() => {
    if (stp_data && Object.keys(stp_data).length) {
      setStpOptions(stp_data.payload);
    }
  }, [stp_data]);

  const confirm = (item) => {
    setLoadingDel(true);
    deleteGrade(dispatch, item).then((res) => {
      setLoadingDel(false);
      if (res?.status === "success") {
        dispatch({ type: "REMOVE_FROM_GRADE", payload: item.id });
        refetch();
      }
    });
  };

  function addNewComponent(e) {
    const new_arry = [...grade_arry];
    new_arry.splice(new_arry.length, 1, {
      grade_id: record.id,
      step_id: "",
      benefit_ids: [],
      salary: "",
      has_steps: 1,
    });
    setGradeArry(new_arry.reverse());
  }

  function removeComponent(e, index) {
    const new_arry = [...grade_arry];
    new_arry.splice(index, 1);
    setGradeArry(new_arry);
  }

  function handleChangeDyn(value, index, name) {
    const new_arry = [...grade_arry];
    const affected = new_arry[index];
    affected[name] = value;
    setGradeArry(new_arry);
  }
  return (
    <>
      <Space wrap>
        <Button
          type="primary"
          className=""
          onClick={() => handleSelected(item)}
        >
          Edit
        </Button>

        {location?.pathname === "/modules/compensation/pay-grades" ? (
          <Button type="primary" className="" onClick={handleModal}>
            Set pay grades
          </Button>
        ) : null}
        <Popconfirm
          title="Delete entries"
          description="Do you what to delete this entry?"
          onConfirm={() => confirm(item)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="list-loadmore-edit"
            className=""
            loading={loadingDel}
            type="primary"
            danger
          >
            Delete
          </Button>
        </Popconfirm>
      </Space>

      <Modal
        open={open_modal}
        footer={null}
        onCancel={handleModal}
        width={1000}
        className="over"
        centered
      >
        <h5 className="birthday__title">{record.name}</h5>
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <form className="performance-form" onSubmit={updateGrade}>
            <>
              <div className="row px-3 ">
                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="step">Step</label>

                    <small className="text-danger">
                      {back_errors.step_id && back_errors.step_id[0]}
                    </small>
                  </div>

                  <Select
                    className="w-100 mt-2"
                    type="text"
                    size="large"
                    name="step_id"
                    id="step_id"
                    value={grade_arry[0].step_id}
                    onChange={(val) => handleChangeDyn(val, 0, "step_id")}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value={""}>--</option>
                    {stp_options.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </Select>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="salary">Gross Salary</label>

                    <small className="text-danger">
                      {back_errors.salary && back_errors.salary[0]}
                    </small>
                  </div>

                  <InputNumber
                    className="w-100 ant__number"
                    style={{ border: "none" }}
                    value={grade_arry[0].salary}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(val) => handleChangeDyn(val, 0, "salary")}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="salary">Benefits</label>

                    <small className="text-danger">
                      {back_errors.salary && back_errors.salary[0]}
                    </small>
                  </div>

                  <Select
                    value={grade_arry[0].benefit_ids}
                    className="w-100 mt-2"
                    mode="multiple"
                    options={ben_options}
                    onChange={(val) => handleChangeDyn(val, 0, "benefit_ids")}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </div>
              </div>
            </>

            <div className="d-flex justify-content-end px-3 mb-4 mt-2">
              <Button htmlType="submit" className="myBtn" loading={loading}>
                Add
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

function EachTableAction({ item, ben_data, stp_data, refetch }) {
  // console.log({ item });
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const [loadingDel, setLoadingDel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [grade_arry, setGradeArry] = useState([
    {
      grade_id: item?.grade_id,
      step_id: item?.step_id || "",
      benefit_ids: item.benefits?.map((itm) => itm.id) || [],
      salary: item?.salary || "",
      has_steps: 1,
    },
  ]);

  const [open_modal, setOpenModal] = useState(false);
  const [ben_options, setBenOptions] = useState([]);
  const [stp_options, setStpOptions] = useState([]);

  function handleModal() {
    setOpenModal(!open_modal);
  }

  function handleChangeDyn(value, index, name) {
    const new_arry = [...grade_arry];
    const affected = new_arry[index];
    affected[name] = value;
    setGradeArry(new_arry);
  }

  function updateGrade(e) {
    e.preventDefault();

    setLoading(true);
    gradeGrade(dispatch, { grading: [...grade_arry] }).then(() => {
      setLoading(false);
      refetch();
    });
  }

  useEffect(() => {
    setGradeArry([
      {
        grade_id: item?.grade_id,
        step_id: item?.step_id || "",
        benefit_ids: item.benefits?.map((itm) => itm.id) || [],
        salary: item?.salary || "",
        has_steps: 1,
      },
    ]);
  }, [item]);

  useEffect(() => {
    if (ben_data && Object.keys(ben_data).length) {
      const opt = ben_data.payload.map((item) => {
        return {
          label: `${item.benefit}[${item.value}]`,
          value: item.id,
        };
      });

      setBenOptions(opt);
    }
  }, [ben_data]);
  useEffect(() => {
    if (stp_data && Object.keys(stp_data).length) {
      setStpOptions(stp_data.payload);
    }
  }, [stp_data]);
  return (
    <>
      <Space wrap>
        <Button
          size="small"
          className="bg-warning text-white"
          onClick={handleModal}
        >
          Edit
        </Button>
        <Button size="small" className="bg-danger text-white">
          Delete
        </Button>
      </Space>

      <Modal
        open={open_modal}
        footer={null}
        onCancel={handleModal}
        width={1000}
        className="over"
        maskClosable={false}
      >
        <h5 className="birthday__title">{item?.grade?.name}</h5>
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <form className="performance-form" onSubmit={updateGrade}>
            <>
              <div className="row px-3 ">
                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="step">Step</label>

                    <small className="text-danger">
                      {back_errors.step_id && back_errors.step_id[0]}
                    </small>
                  </div>

                  <Select
                    className="w-100 mt-2"
                    type="text"
                    size="large"
                    name="step_id"
                    id="step_id"
                    value={grade_arry[0].step_id}
                    onChange={(val) => handleChangeDyn(val, 0, "step_id")}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value={""}>--</option>
                    {stp_options.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </Select>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="salary">Gross Salary</label>

                    <small className="text-danger">
                      {back_errors.salary && back_errors.salary[0]}
                    </small>
                  </div>

                  <InputNumber
                    className="w-100 ant__number"
                    style={{ border: "none" }}
                    value={grade_arry[0].salary}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(val) => handleChangeDyn(val, 0, "salary")}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <div className="d-flex justify-content-between flex-wrap">
                    <label htmlFor="salary">Benefits</label>

                    <small className="text-danger">
                      {back_errors.salary && back_errors.salary[0]}
                    </small>
                  </div>

                  <Select
                    value={grade_arry[0].benefit_ids}
                    className="w-100 mt-2"
                    mode="multiple"
                    options={ben_options}
                    onChange={(val) => handleChangeDyn(val, 0, "benefit_ids")}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </div>
              </div>
            </>

            <div className="d-flex justify-content-end px-3 mb-4 mt-2">
              <Button htmlType="submit" className="myBtn" loading={loading}>
                Update
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default GradesList;
