import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import { Input } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const KPIForm = ({
  KPINumber,
  handleAddForm,
  handleRemoveForm,
  id,
  KPIName,
  weight,
  description,
  availableWeight,
  onChange,
  onWeightChange,
  exceedsMax,
}) => {
  const handleInputChange = (name, value) => {
    onChange(id, name, value);
  };

  const handleQuillChange = (content, delta, source, editor) => {
    handleInputChange("description", content);
  };

  const handleWeightInputChange = (e) => {
    const newWeight = parseInt(e.target.value, 10) || "";
    console.log("newWeight", newWeight);
    onWeightChange(id, newWeight);
  };

  return (
    <div className="planning__form__wrapper">
      <div className="planning__form__top__buttons">
        <span>
          <AiFillPlusCircle
            size={32}
            color={"green"}
            style={{
              cursor: "pointer",
            }}
            onClick={handleAddForm}
          />
          <IoIosRemoveCircle
            size={32}
            color={"red"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleRemoveForm(KPINumber)}
          />
        </span>
      </div>
      <div className="planning__form">
        <h5 className="planning__form__title">{`KPI ${KPINumber}`}</h5>
        <div className="planning__form__content">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">KPI Name</label>
            <Input
              size="large"
              id="kpi"
              value={KPIName}
              style={{
                border: "2px solid black",
              }}
              onChange={(e) => onChange(id, "name", e.target.value)}
              required
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item__half">  
          <label className="fw-bold fs-6 d-flex justify-content-between">
              <span className="">Weight</span>
              {exceedsMax && (
                <small className="text-danger">
                  {`Weight cannot exceed available weight: ${availableWeight}`}
                </small>
              )}
            </label>
            <input
              type="number"
              min={1}
              max={availableWeight}
              size="large"
              id="weight"
              value={weight}
              style={{
                border: "2px solid black",
                width: "100%",
              }}
              onChange={handleWeightInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item">
            <label className="fw-bold fs-6">Description</label>
            <ReactQuill
              theme="snow"
              id="body"
              placeholder=""
              value={description}
              onChange={handleQuillChange}
              style={{
                border: "2px solid black",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPIForm;
