import {
  request,
  setPrivateRequest,
  setPrivateRequestMedia,
} from "../../util/requestMethods";
import { hashData, dehashData } from "../../util/hash";
import { storage_type } from "../../config";

const { REACT_APP_TOKEN: TOKEN, REACT_APP_AUTH_DETAILS: AUTH_DETAILS } =
  process.env;

const loginClient = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await request.post("login", creds);
      result = result.data;

      const hash_token = hashData(result.payload.token);

      let show_quick_set = false;
      if (
        result?.payload?.logoUploaded === false ||
        result?.payload?.CompanyDetail === false
      ) {
        show_quick_set = true;
      }
      const hash_auth_details = hashData({ ...result.payload, show_quick_set });
      storage_type.setItem(TOKEN, hash_token);
      storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_LOGIN_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "USER_LOGIN_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const setupClientLogin = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await request.post("/password/set-password", creds);
      result = result.data;

      const hash_token = hashData(result.payload.data.token);

      let show_quick_set = false;
      // if (
      //   result?.payload?.logoUploaded === false ||
      //   result?.payload?.CompanyDetail === false
      // ) {
      //   show_quick_set = true;
      // }
      const hash_auth_details = hashData({
        ...result.payload.data,
        show_quick_set,
      });
      storage_type.setItem(TOKEN, hash_token);
      storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_SETUP_LOGIN_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "USER_LOGIN_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const resetPassLogin = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await request.post("/password/reset-password", creds);
      result = result.data;

      // const hash_token = hashData(result.payload.data.token);

      // let show_quick_set = false;
      // if (
      //   result?.payload?.logoUploaded === false ||
      //   result?.payload?.CompanyDetail === false
      // ) {
      //   show_quick_set = true;
      // }
      // const hash_auth_details = hashData({
      //   ...result.payload.data,
      //   show_quick_set,
      // });
      // storage_type.setItem(TOKEN, hash_token);
      // storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_RESET_LOGIN_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "USER_LOGIN_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const logoutClient = async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post("logout");
      result = result.data;

      storage_type.removeItem(TOKEN);
      storage_type.removeItem(AUTH_DETAILS);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_LOGOUT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "USER_LOGOUT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const getResetLink = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.EmpID.toLowerCase() === "administrator") {
        result = await request.post("password/forgot-password?adm=true", creds);
      } else {
        result = await request.post("password/forgot-password", creds);
      }

      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "RESET_LINK_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "RESET_LINK_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const userCleanUp = async (dispatch) => {
  dispatch({ type: "CLEAR_USERS_ERRORS" });
};

const updateClientLogo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        "quick-setup/logo-upload",
        fd
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "LOGO_UPDATE_SUCCESS", payload: result });

      const auth_details = dehashData(AUTH_DETAILS);

      const new_auth_details = {
        ...auth_details,
        logo: result.payload.logo,
      };
      const hash_auth_details = hashData(new_auth_details);
      storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "LOGO_UPDATE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const uploadOnboardingSetup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        "hrm/onboarding/setup",
        fd
      );
      result = result.data;

      // console.log(result);
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_SETUP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_SETUP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateClientTheme = async (dispatch, creds) => {
  console.log(creds);
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        "quick-setup/display-settings",
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "LOGO_UPDATE_SUCCESS", payload: result });

      const auth_details = dehashData(AUTH_DETAILS);

      const new_auth_details = {
        ...auth_details,
        company_details_temp: result.payload,
      };

      const hash_auth_details = hashData(new_auth_details);
      storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "LOGO_UPDATE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateClientComDetails = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        "quick-setup/company-details",
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "LOGO_UPDATE_SUCCESS", payload: result });

      // const auth_details = dehashData(AUTH_DETAILS);

      // const new_auth_details = {
      //   ...auth_details,
      //   company_details_temp: result.payload,
      // };

      // const hash_auth_details = hashData(new_auth_details);
      // storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "LOGO_UPDATE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateClientPassDetails = async (dispatch, creds) => {
  delete creds.allow_self_recovery;
  delete creds.allow_self_unlock;
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        "quick-setup/password-settings",
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "LOGO_UPDATE_SUCCESS", payload: result });

      // const auth_details = dehashData(AUTH_DETAILS);

      // const new_auth_details = {
      //   ...auth_details,
      //   company_details_temp: result.payload,
      // };

      // const hash_auth_details = hashData(new_auth_details);
      // storage_type.setItem(AUTH_DETAILS, hash_auth_details);

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "LOGO_UPDATE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const callQuickSetupEndpoint = async (dispatch, endpoint, creds, name) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(endpoint, fd);
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_QUICK_SETUP_SUCCESS", payload: result });

      if (name === "Logo Upload") {
        const auth_details = dehashData(AUTH_DETAILS);

        const new_auth_details = {
          ...auth_details,
          logo: result.payload.logo,
        };
        const hash_auth_details = hashData(new_auth_details);
        storage_type.setItem(AUTH_DETAILS, hash_auth_details);
      }

      if (name === "Company Details") {
        const auth_details = dehashData(AUTH_DETAILS);

        const new_auth_details = {
          ...auth_details,
          company_details_temp: result.payload,
        };

        const hash_auth_details = hashData(new_auth_details);
        storage_type.setItem(AUTH_DETAILS, hash_auth_details);
      }

      if (name === "Display Settings") {
        const auth_details = dehashData(AUTH_DETAILS);

        const new_auth_details = {
          ...auth_details,
          company_color: result.payload,
        };
        const hash_auth_details = hashData(new_auth_details);
        storage_type.setItem(AUTH_DETAILS, hash_auth_details);
      }

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "USER_QUICK_SETUP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const uploadBulkEmployee = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        "quick-setup/employees-profile",
        fd
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "BULK_UPLOAD_SUCCESS", payload: result });
      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "BULK_UPLOAD_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const uploadMultiple = async (dispatch, url, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(url, fd);
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "BULK_UPLOAD_SUCCESS", payload: result });
      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "BULK_UPLOAD_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const markMessages = async (dispatch, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(`in-messages/${id}`);
      result = result.data;

      // console.log(result);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "MARK_READ_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "MARK_READ_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const changePassword = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await request.post("password/change", creds);
      result = result.data;

      console.log(result);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PASSWORD_CHANGE_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "PASSWORD_CHANGE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateUserContactInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/contact?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      console.log(result);

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log("here");

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserContactInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/contact/${creds.id}/approve`,
        {
          telephone: creds.telephone,
          mobile_number: creds.mobile_number,
          primary_email: creds.primary_email,
          secondary_email: creds.secondary_email,
          home_address: creds.home_address,
          except: creds.except,
          notify: creds.notify,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserFinancialsInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/financials/${creds.id}/approve`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserDocInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/upload-document/${creds.id}/approve?notify=${creds.notify}`,

        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserIdentityInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/identity/${creds.id}/approve`,
        {
          identity_type: creds.identity_type,
          identity_number: creds.identity_number,
          except: creds.except,
          notify: creds.notify,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserEduInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/educational_history/${creds.id}/approve`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserEmploymentInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/employment_history/${creds.id}/approve`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateUserDependentInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/dependant?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserEduInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/educational_history?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserEmploymentInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/employment_history?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserDependentInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/dependant/${creds.id}/approve`,
        {
          name: creds.name,
          genotype: creds.genotype,
          blood_group: creds.blood_group,
          telephone: creds.telephone,
          source_id: creds.source_id,
          notify: creds.notify,
          except: creds.except,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserNOKInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/next_of_kin/${creds.id}/approve`,
        {
          name: creds.name,
          email: creds.email,
          relationship: creds.relationship,
          telephone: creds.telephone,
          mobile_number: creds.mobile_number,
          home_address: creds.home_address,
          except: creds.except,
          notify: creds.notify,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserMedicalInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/medical?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserFinancialsinfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/financials?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveUserMedicalInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/medical/${creds.id}/approve`,
        {
          hmo_plan: creds.hmo_plan,
          hmo_id_number: creds.hmo_id_number,
          hmo_primary_number: creds.hmo_primary_number,
          hmo_secondary_number: creds.hmo_secondary_number,
          primary_hospital: creds.primary_hospital,
          primary_hospital_address: creds.primary_hospital_address,
          genotype: creds.genotype,
          blood_group: creds.blood_group,
          except: creds.except,
          notify: creds.notify,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserNOKInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/next_of_kin?user=${creds.user_id}`,
        creds
      );
      result = result.data;
      result.status = "success";

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteNextOfKin = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/profile/slave/next_of_kin/${creds.id}/delete?user=${creds.user_id}`
      );
      result = result.data;
      result.status = "success";

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserPersonalInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/personal?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const createUserPersonalInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(`hrm/profile/slave`, creds);
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateUserIdentityInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/identity?user=${creds.user_id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const uploadProfilePicEmployee = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        "hrm/profile/upload-avatar",
        fd
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PROFILE_PIC_SUCCESS", payload: result });
      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "PROFILE_PIC_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
export const uploadSignatureEmployee = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        "hrm/profile/upload-signature",
        fd
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SIGNATURE_PIC_SUCCESS", payload: result });
      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "SIGNATURE_PIC_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const uploadProfileDocumentEmployee = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    // console.log({ creds });
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        `hrm/profile/slave/upload-document?user=${creds.user_id}`,
        fd
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PROFILE_PIC_SUCCESS", payload: result });
      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "PROFILE_PIC_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const approveUserPersonalInfo = async (dispatch, creds, exempt) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/personal/${creds.id}/approve`,
        {
          nationality: creds.nationality,
          state_of_origin: creds.state_of_origin,
          province_lga: creds.province_lga,
          religion: creds.religion,
          gender: creds.gender,
          date_of_birth: creds.date_of_birth,
          title: creds.title,
          first_name: creds.first_name,
          last_name: creds.last_name,
          other_names: creds.other_names,
          marital_status: creds.marital_status,
          employee_id: creds.employee_id,
          employment_type: creds.employment_type,
          employment_date: creds.employment_date,
          designation_role: creds.designation_role,
          department: creds.department,
          except: exempt,
          notify: creds.notify,
        }
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const rejectUserInfo = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let { id, info_type, except, ...rest } = creds;
      let result = await setPrivateRequest().post(
        `hrm/profile/slave/${creds.info_type}/${creds.id}/approve?reject=true`,
        {
          ...rest,
        }
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const rejectUserDoc = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(
        `hrm/profile/slave/${creds.doc_id}/delete-document`
      );
      result = result.data;

      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const rejectUserInfo1 = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/profile/slave/${creds.info_type}/${creds.user_id}/delete?user=${creds.user_id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addToCategory = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(`settings/utils`, creds);
      result = result.data;
      result.status = "success";

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_TO_CAT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_TO_CAT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateCategory = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().put(`settings/utils/${id}`, creds);
      result = result.data;
      result.status = "success";

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_TO_CAT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "ADD_TO_CAT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteFromCategory = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `settings/utils/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_FROM_CAT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_FROM_CAT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteDepartment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/preferences/department/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_FROM_CAT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_FROM_CAT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteBank = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/preferences/bank/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_FROM_BANK_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_FROM_BANK_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteHoliday = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(`/holiday/${creds.id}`);
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_HOLIDAY_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_HOLIDAY_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteEventType = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `settings/event-type/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_HOLIDAY_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_HOLIDAY_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteAnniversary = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(`/anniversary/${creds.id}`);
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_ANNIVERSARY_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_ANNIVERSARY_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteRoleDesignation = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `/hrm/job-role/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_DESIGNATION_ROLE_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_DESIGNATION_ROLE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addNewHire = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/onboarding/pre-employment/new-hire`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_NEW_HIRE_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_NEW_HIRE_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const submitPrefillform = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await request.post(`hire/submit-pre-fill-form`, creds);
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SUBMIT_PREFILL_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "SUBMIT_PREFILL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEditDeptment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(
          `hrm/preferences/department/${creds.id}`,
          creds
        );
      } else {
        result = await setPrivateRequest().post(
          `hrm/preferences/department`,
          creds
        );
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_DEPT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEditGrades = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(
          `hrm/system-grade/${creds.id}`,
          creds
        );
      } else {
        result = await setPrivateRequest().post(`hrm/system-grade`, creds);
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_DEPT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addEditBanks = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;

      if (creds.id) {
        result = await setPrivateRequest().put(
          `hrm/preferences/bank/${creds.id}`,
          creds
        );
      } else {
        result = await setPrivateRequest().post(`hrm/preferences/bank`, creds);
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_BANK_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_EDIT_BANK_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const SheduledNewEvent = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(`/hrm/event`, creds);

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SCHEDULED_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "SCHEDULED_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const createNewGoal = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `/hrm/performance/initiate/store`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CREATE_NEW_GOAL_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CREATE_NEW_GOAL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const createNewGoalDept = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `/hrm/performance/department/kpi/store`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CREATE_NEW_DEPT_GOAL_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "CREATE_NEW_DEPT_GOAL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEditHoliday = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(`/holiday/${creds.id}`, creds);
      } else {
        result = await setPrivateRequest().post(`/holiday`, creds);
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_HOLIDAY_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERAL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEditEventype = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      // alert("here");
      if (creds.id) {
        result = await setPrivateRequest().put(
          `settings/event-type/${creds.id}?name=${creds.name}&code=${creds.code}`,
          creds
        );
      } else {
        delete creds.id;
        result = await setPrivateRequest().post(`settings/event-type`, {
          ...creds,
          category: "Event Type",
        });
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERAL_SUCCESS", payload: result });
      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERAL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addEditAnniversary = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(`/anniversary`, creds);
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_ANNIVERSARY_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_EDIT_ANNIVERSARY_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEditRoleDesignation = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(`/hrm/job-role/${creds.id}`, {
          role: creds.role,
          code: creds.code,
          category: creds.category,
        });
      } else {
        result = await setPrivateRequest().post(`/hrm/job-role`, {
          role: creds.role,
          code: creds.code,
          category: creds.category,
        });
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_DESIGNATION_ROLE_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({
        type: "ADD_EDIT_DESIGNATION_ROLE_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const acceptReject = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(`hire/offer/approve`, creds);
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SUBMIT_PREFILL_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "SUBMIT_PREFILL_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const onboardingAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.action === "delete") {
        result = await setPrivateRequest().delete(
          `hrm/onboarding/pre-employment/new-hire/${creds.id}/${creds.type}`
        );
      } else {
        if (
          creds?.hireProfile &&
          creds.hireProfile?.payload?.approval_status === "REJECT"
        ) {
          result = await setPrivateRequest().get(
            `hrm/onboarding/pre-employment/new-hire/${creds.id}/${creds.type}?reset=true`
          );
        } else {
          result = await setPrivateRequest().get(
            `hrm/onboarding/pre-employment/new-hire/${creds.id}/${creds.type}`
          );
        }
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const onboardingActionRes = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().get(
        `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const markAsRead = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().get(
        `hire/read-document?pre_employment_hire_id=${creds.pre_employment_hire_id}&setting_id=${creds.setting_id}&action=${creds.action}`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const createTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/${creds.type}/template/create`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const moveToStage = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().get(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/move-hire-stage/${creds.id}/${creds.type}`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const editTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/${creds.type}/template/${creds.id}/update`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const assignTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/assign-hire/${creds.type}-template`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addActivityTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/onboarding/${creds.type}/activity/create`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateActivityTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/onboarding/${creds.type}/activity/${creds.act_id}/update`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteTemple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().delete(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/${creds.type}/template/${creds.id}/delete`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteActivity = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().delete(
        // `hrm/onboarding/pre-employment/new-hire-trash/${creds.id}/restore`
        `hrm/onboarding/${creds.type}/activity/${creds.id}/delete`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteSetupDoc = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `settings/${creds.id}/delete`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_FROM_CAT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_FROM_CAT_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const setSettings = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(
        `settings/set?value=${creds.value}&type=${creds.type}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SET_SETTINGS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "SET_SETTINGS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const toggleActivies = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(
        `hrm/onboarding/change-hire-${creds.type}-activity-status/${creds.hire_id}/${creds.activity_id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARBING_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ONBOARBING_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const generalAction = async (obj) => {
  if (!window.navigator.onLine) {
    obj.dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      obj.dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      obj.dispatch({ type: "CLEAR_USERS_ERRORS" });
      // obj.dispatch({ type: "START_SPINNER" });
      let result;
      if (obj?.method.toLowerCase() === "post") {
        result = await setPrivateRequest().post(`${obj.address}`, obj.creds);
      } else {
        result = await setPrivateRequest().get(`${obj.address}`);
      }
      // console.log("here");

      result = result.data;

      // obj.dispatch({ type: "STOP_SPINNER" });
      obj.dispatch({ type: "GENERIC_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // obj.dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      obj.dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const submitPerformanceSettings = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/performance/settings`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PERFORMANCE_SETTINGS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "PERFORMANCE_SETTINGS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const approvePerformancePlan = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().get(
        `hrm/performance/plan/approve/${creds.id}`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const applyPerformanceFlow = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().get(
        `hrm/performance/plan/approval-workflow/${creds.id}/apply`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PERFORMANCE_WORK_FLOW_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const createPerformanceFlow = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/performance/plan/approval-workflow`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CREATE_PERFORMANCE_FLOW_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const postNudge = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/performance/plan/approvals/${creds.id}/nudge`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "NUDGE_ACTION_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteBenefits = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().delete(
        `hrm/compensation/benefits-in-kind/${creds.benefit}`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "REMOVE_DEL_BENEFIT", payload: creds.benefit });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addNewBenefits = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/benefits-in-kind/create`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateBenefits = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/benefits-in-kind/update/${creds.id}`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateComSettings = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `/hrm/compensation/settings`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "UPDATE_COM_SETTING", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateJobGrading = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().put(
        `hrm/compensation/job-role/${creds.id}`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "UPDATE_COM_SETTING", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addJobGrading = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/job-role`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "UPDATE_COM_SETTING", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateSalaryComponent = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/salary-structure/create-update`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "UPDATE_COM_SETTING", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const updateSalaryComponentOther = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/salary-structure/others/create-update`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      dispatch({ type: "UPDATE_COM_SETTING", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteLeaveGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/grade-leave-distribution/leave-groups/delete/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteGrade = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/system-grade/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addEditSystemSteps = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(`/hrm/system-step/${creds.id}`, {
          name: creds.name,
          code: creds.code,
        });
      } else {
        result = await setPrivateRequest().post(`/hrm/system-step`, {
          name: creds.name,
          code: creds.code,
        });
        console.log({ result });
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });

      dispatch({ type: "ADD_EDIT_SYSTEM_STEPS_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_EDIT_SYSTEM_STEPS_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const deleteSystemSteps = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `/hrm/system-step/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DELETE_SYSTEM_STEP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_SYSTEM_STEP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const requestidcardAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/identity-card/request`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const reportRequestAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        `hrm/identity-card/report/report-identity-card-lost`,
        fd
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveDeclineIDCardAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/identity-card/request/${creds.id}/approve?${creds.action}=${true}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const resovleIDCardAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/identity-card/report/${creds.id}/resolve?${creds.action}=${true}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const NugdeRequestAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/leave/nudge/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const approveRejectAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/leave/backup/leave-application/${creds.id}/${creds.action}`,
        { comment: creds.comment }
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const approveRejectActionPlan = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/leave/line-manager/annual-leave-plan/${creds.id}/${creds.action}`,
        { comment: creds.comment }
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addUserLeaveRequest = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds) {
        result = await setPrivateRequestMedia().post(`hrm/leave/apply`, fd);
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });

      dispatch({ type: "ADD_LEAVE_REQUEST_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_LEAVE_REQUEST_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const addUserLeaveRequestHr = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds) {
        result = await setPrivateRequestMedia().post(
          `/hrm/leave/hr/apply-for-employee`,
          fd
        );
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });

      dispatch({ type: "ADD_LEAVE_REQUEST_HR_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_LEAVE_REQUEST_HR_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const addUserLeaveRequestTeam = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const fd = new FormData();
      Object.entries(creds).forEach(([key, value]) => {
        fd.append(key, value);
      });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds) {
        result = await setPrivateRequestMedia().post(
          `hrm/leave/line-manager/apply-for-employee`,
          fd
        );
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });

      dispatch({ type: "ADD_LEAVE_REQUEST_TEAM_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_LEAVE_REQUEST_TEAM_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const addAnnualLeave = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds) {
        result = await setPrivateRequest().post(
          `hrm/leave/set-annual-leave-plan`,
          {
            dates: creds,
            batch:
              Date.now().toString(36) +
              Math.random().toString(36).substring(2, 10),
          }
        );
      }

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      console.log({ result });

      dispatch({ type: "ADD_ANNUAL_LEAVE_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_ANNUAL_LEAVE_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};
const updateAnnualLeave = async (dispatch, creds, batch, user_id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds) {
        result = await setPrivateRequest().put(
          `hrm/leave/line-manager/annual-leave-plan/${user_id}`,
          {
            dates: creds,
            batch,
          }
        );
      }

      // result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      console.log({ result });

      dispatch({ type: "ADD_ANNUAL_LEAVE_SUCCESS", payload: result });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      console.log({ resMessage });

      dispatch({
        type: "ADD_ANNUAL_LEAVE_ERROR",
        payload: resMessage,
      });
      return resMessage;
    }
  }
};

const addEditLeaveGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        result = await setPrivateRequest().put(
          `hrm/grade-leave-distribution/leave-groups/${creds.id}`,
          creds
        );
      } else {
        delete creds.id;
        result = await setPrivateRequest().post(
          `hrm/grade-leave-distribution/leave-groups`,
          creds
        );
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_DEPT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteLeaveGroupDistribution = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `/hrm/grade-leave-distribution/${creds.id}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addEditLeaveGroupDistribution = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      if (creds.id) {
        // delete creds.id;
        result = await setPrivateRequest().post(
          // `/hrm/grade-leave-distribution/${creds.id}`,
          `/hrm/grade-leave-distribution`,
          {
            grade_id: creds.grade_id,
            leave_group_id: creds.leave_group_id,
            available: creds.available,
            transferrable_amount: creds.transferrable_amount,
          }
        );
      } else {
        delete creds.id;
        result = await setPrivateRequest().post(
          `/hrm/grade-leave-distribution`,
          creds
        );
      }
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ADD_EDIT_DEPT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const activateDeactivateUser = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;

      result = await setPrivateRequest().get(
        `hrm/profile/activation/${creds.id}?activation_type=${creds.action}`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const gradeGrade = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;

      result = await setPrivateRequest().post(
        `/hrm/compensation/grading`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const gradeRole = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;

      result = await setPrivateRequest().post(
        `/hrm/compensation/pay-structure`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addCompany = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(`org`, creds);
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const editCompany = async (dispatch, creds, companyId) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `org/${companyId}/update`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteCompany = async (dispatch, companyId) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().delete(`org/${companyId}/delete`);
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addBranch = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(`branch`, creds);
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const editBranch = async (dispatch, creds, branchId) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `branch/${branchId}/update`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteBranch = async (dispatch, branchId) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().delete(
        `branch/${branchId}/delete`
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const setSystemHr = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(`settings/system-hr`, creds);
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
// const setLeaveapprovalFlow = async (dispatch, creds) => {
//   if (!window.navigator.onLine) {
//     dispatch({ type: "CLEAR_USERS_ERRORS" });
//     setTimeout(() => {
//       dispatch({ type: "USER_OFFLINE_USERS" });
//     }, 500);
//   } else {
//     try {
//       dispatch({ type: "CLEAR_USERS_ERRORS" });
//       let result = await setPrivateRequest().post(
//         `hrm/leave/leave-approval-setting`,
//         { leave_approval_worfklow_id: 1 }
//       );
//       result = result.data;
//

//       dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

//       return result;
//     } catch (error) {
//       let resMessage = error?.response?.data;
//
//       dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
//       return resMessage;
//     }
//   }
// };

const setLeaveapprovalFlow = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/leave/leave-approval-setting`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const savePerformanceSettings = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/settings`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addPerformanceMeasurementUnit = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/add-unit`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deletePerformanceMeasurementUnit = async (dispatch, unit_id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().delete(
        `hrm/performance/units/${unit_id}/delete`
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addPerformancePlanningSection = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/add-section`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deletePerformancePlanningSection = async (dispatch, section_id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().delete(
        `hrm/performance/sections/${section_id}/delete`
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addPerformanceGoal = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/goal-settings`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updatePerformanceGoal = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `hrm/performance/initiate/update-goal-settings/${id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addPerformanceGoalObjectives = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/store/objective`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updatePerformanceGoalObjectives = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `hrm/performance/initiate/update/objective/${id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addPerformanceObjectiveKPIs = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/store/kpi`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updatePerformanceObjectiveKPIs = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `hrm/performance/initiate/update/kpi/${id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};


const addPerformanceKPITargets = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/store/target`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updatePerformanceKPITargets = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().put(
        `hrm/performance/initiate/update/target/${id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const approveOrRejectTarget = async (dispatch, id, action) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/approve/target/${id}?action=${action}
        `
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const storeCascade = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/initiate/store/cascade`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const setAppraisalsSettings = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/settings`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const createAppraisal = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/create`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateAppraisal = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/update/${id}`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const authorizeOrUnautorizeAppraisal = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/authorise`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const endAppraisal = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/end-appraisal`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const restartLastAppraisal = async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/reopen`,
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const beginAppraisal = async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/begin`
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const restartEmployeeAppraisal = async (dispatch, appraisalId, employeeId) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/restart-appraisal?appraisal_id=${appraisalId}&employee_id=${employeeId}`
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const setEmployeeScore = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/employee-appraisal`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addEmployeeDeliverableComment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/employee-deliverable-comment`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const manageTeamMember = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/manage-team-appraisal`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const createSalaryComponent = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/payroll/wage-item-setting`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const upadateSalaryComponent = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });

      let result = await setPrivateRequest().post(
        `hrm/compensation/payroll/update-wage-item-setting`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });
      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteWageItem = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().delete(
        `/hrm/compensation/payroll/${creds.id}/delete-wage-item-setting`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const cancelMyLeaveRequest = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().post(
        `hrm/leave/cancel-leave-request/${creds.batch}/CANCELLED`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const addDeductionTemplate = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().post(
        `hrm/compensation/payroll/deduction-master`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteDeductionTemplate = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().delete(
        `hrm/compensation/payroll/${creds.id}/delete-deduction-master`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const nextLevelAppraiserDeliverableComment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/next-level-deliverable-comment`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const nextLevelAppraiser = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      let result = await setPrivateRequest().post(
        `hrm/performance/appraisal/next-level-appraisal`,
        creds
      );
      result = result.data;

      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const recallLeaveAction = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(
        `/hrm/leave/hr/recall-employee-action/${creds.id}?batch=${creds.batch}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const loadBatchPayment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/compensation/payroll/load-batch-payment`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const createBatchPayment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/compensation/payroll/create-batch-payment`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteBatchPayment = async (dispatch, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().delete(
        `/hrm/compensation/payroll/batch-payments/${id}/delete`
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const authorizeBatchPayment = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().put(
        `/hrm/compensation/payroll/batch-payments/authorize`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const sendPayslip = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().post(
        `/hrm/compensation/payroll/send-payslip`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};


const sendEmployeePayslip = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().post(
        `/hrm/compensation/payroll/employe-send-payslip`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};


const sendNewEmployeeMail = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;
      result = await setPrivateRequest().post(
        `/quick-setup/employee-upload-mail`,
        creds
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const createRequestTemplate = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/request-management/create-template`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateRequestTemplate = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().put(
        `/hrm/request-management/edit-template/${id}`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const makeARequest = async (dispatch, creds, id) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequestMedia().post(
        `/hrm/request-management/make-request/${id}`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const nudgeRequestApprover = async (dispatch, appproverID) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().get(
        `/hrm/request-management/nudge-request/${appproverID}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const updateRequestStatus = async (dispatch, creds, appprovalID) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/request-management/update-request-status/${appprovalID}`,
        creds
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deactivateRequestTemplate = async (dispatch, templateID) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().post(
        `/hrm/request-management/deactivate-template/${templateID}`
      );
      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const deleteUserDependant = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/profile/slave/dependant/${creds.id}/delete?user=${creds.user_id}`
      );
      result = result.data;
      result.status = "success";

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CONTACT_DETAILS_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "CONTACT_DETAILS_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const toggleUserLock = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_USERS" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      // dispatch({ type: "START_SPINNER" });
      let result;

      result = await setPrivateRequest().post(
        `hrm/employees/unlock-emmployee/${creds.id}`,
        { status: creds.status }
      );

      result = result.data;

      // dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ALERT_SUCCESS", payload: result });

      return result;
    } catch (error) {
      // dispatch({ type: "STOP_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

export {
  updateAppraisal,
  restartEmployeeAppraisal,
  updateCategory,
  deleteUserDependant,
  deactivateRequestTemplate,
  updateRequestStatus,
  nudgeRequestApprover,
  makeARequest,
  updateRequestTemplate,
  createRequestTemplate,
  sendNewEmployeeMail,
  sendEmployeePayslip,
  sendPayslip,
  authorizeBatchPayment,
  deleteBatchPayment,
  createBatchPayment,
  loadBatchPayment,
  beginAppraisal,
  nextLevelAppraiser,
  nextLevelAppraiserDeliverableComment,
  manageTeamMember,
  addEmployeeDeliverableComment,
  setEmployeeScore,
  upadateSalaryComponent,
  deleteWageItem,
  createSalaryComponent,
  addDeductionTemplate,
  deleteDeductionTemplate,
  updateAnnualLeave,
  approveRejectActionPlan,
  endAppraisal,
  restartLastAppraisal,
  authorizeOrUnautorizeAppraisal,
  createAppraisal,
  setAppraisalsSettings,
  storeCascade,
  approveOrRejectTarget,
  addPerformanceKPITargets,
  updatePerformanceKPITargets,
  addPerformanceObjectiveKPIs,
  updatePerformanceObjectiveKPIs,
  addPerformanceGoalObjectives,
  updatePerformanceGoalObjectives,
  addPerformanceGoal,
  updatePerformanceGoal,
  deletePerformancePlanningSection,
  addPerformancePlanningSection,
  deletePerformanceMeasurementUnit,
  addPerformanceMeasurementUnit,
  savePerformanceSettings,
  setLeaveapprovalFlow,
  setSystemHr,
  addCompany,
  editCompany,
  deleteCompany,
  addBranch,
  editBranch,
  deleteBranch,
  updateSalaryComponentOther,
  gradeRole,
  gradeGrade,
  activateDeactivateUser,
  deleteEventType,
  addEditLeaveGroupDistribution,
  deleteLeaveGroupDistribution,
  deleteLeaveGroup,
  addEditLeaveGroup,
  approveRejectAction,
  NugdeRequestAction,
  addUserLeaveRequestTeam,
  addUserLeaveRequestHr,
  resovleIDCardAction,
  approveDeclineIDCardAction,
  reportRequestAction,
  requestidcardAction,
  addAnnualLeave,
  addUserLeaveRequest,
  deleteSystemSteps,
  addEditSystemSteps,
  deleteGrade,
  addEditGrades,
  addJobGrading,
  updateSalaryComponent,
  updateJobGrading,
  updateComSettings,
  submitPerformanceSettings,
  updateBenefits,
  addNewBenefits,
  deleteBenefits,
  createPerformanceFlow,
  postNudge,
  applyPerformanceFlow,
  approvePerformancePlan,
  loginClient,
  logoutClient,
  userCleanUp,
  callQuickSetupEndpoint,
  changePassword,
  markMessages,
  uploadBulkEmployee,
  setupClientLogin,
  getResetLink,
  resetPassLogin,
  updateUserContactInfo,
  updateUserDependentInfo,
  updateUserMedicalInfo,
  updateUserNOKInfo,
  deleteNextOfKin,
  updateUserPersonalInfo,
  uploadProfilePicEmployee,
  approveUserMedicalInfo,
  approveUserContactInfo,
  approveUserDependentInfo,
  approveUserNOKInfo,
  approveUserPersonalInfo,
  rejectUserInfo,
  updateUserIdentityInfo,
  approveUserIdentityInfo,
  updateUserEduInfo,
  approveUserEduInfo,
  updateUserEmploymentInfo,
  approveUserEmploymentInfo,
  rejectUserInfo1,
  uploadProfileDocumentEmployee,
  approveUserDocInfo,
  createUserPersonalInfo,
  updateUserFinancialsinfo,
  approveUserFinancialsInfo,
  rejectUserDoc,
  updateClientLogo,
  updateClientTheme,
  addToCategory,
  deleteFromCategory,
  uploadOnboardingSetup,
  addNewHire,
  onboardingAction,
  submitPrefillform,
  acceptReject,
  deleteSetupDoc,
  onboardingActionRes,
  updateClientComDetails,
  updateClientPassDetails,
  setSettings,
  addEditDeptment,
  addEditBanks,
  deleteDepartment,
  createTemple,
  editTemple,
  deleteTemple,
  addActivityTemple,
  updateActivityTemple,
  deleteActivity,
  assignTemple,
  moveToStage,
  toggleActivies,
  addEditHoliday,
  deleteHoliday,
  addEditAnniversary,
  deleteAnniversary,
  addEditRoleDesignation,
  deleteRoleDesignation,
  markAsRead,
  deleteBank,
  uploadMultiple,
  SheduledNewEvent,
  createNewGoal,
  createNewGoalDept,
  generalAction,
  addEditEventype,
  cancelMyLeaveRequest,
  recallLeaveAction,
  toggleUserLock,
};
