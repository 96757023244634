import { useState, useEffect } from "react";
import { Table, Space, Button, Popconfirm, Drawer, Select, Input } from "antd";
import {
  useGetUserProfile,
  useGetPerformanceKPITargets,
  useGetSystemDepartments,
  useGetCascadeByTarget,
} from "../../../../util/usershookActions";

const CascadeTable = ({ cascadeTableData }) => {
  const groupedData = {};

  // Group the data by department
  cascadeTableData?.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Goal",
      dataIndex: "goal",
    },
    {
      title: "Objectives",
      dataIndex: "objective",
    },
    {
      title: "KPIs",
      dataIndex: "kpi",
    },
    {
      title: "Staff",
      dataIndex: "employee",
    },
    {
      title: "Department Target (unit)",
      dataIndex: "target",
      key: "target",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Individual Target (unit)",
      dataIndex: "individual_target",
      key: "individual_target",
      render: (text, record) => (
        <span>
          {record.individual_target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
    },
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      render: (_, record) => <EachAction selectedCascade={record} />,
    },
  ];

  return (
    <div className="performance_table">
      <Table
        columns={columns}
        dataSource={transformedData}
        bordered
        pagination={false}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

function EachAction({ selectedCascade }) {
  const [enabled, setEnabled] = useState(true);
  const [cascadeByTargetEnabled, setCascadeByTargetEnabled] = useState(false);

  const [departmentId, setDepartmentId] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [targetsDataState, setTargetsDataState] = useState([]);
  const [targetAvailableWeight, setTargetAvailableWeight] = useState(null);

  const [openEdit, setOpenEdit] = useState(false);
  const [deleteCascadeIsLoading, setDeleteCascadeIsLoading] = useState(false);

  const [cascades, setCascades] = useState([]);

  const [editCascadeTableData, setEditCascadeTableData] = useState(null);

  const { data: userProfile } = useGetUserProfile(enabled, setEnabled);

  const { data: systemsDepartments } = useGetSystemDepartments(
    enabled,
    setEnabled
  );

  const {
    data: cascadeByTarget,
    isLoading: isCascadeByTargetLoading,
    refetch: refetchCascadeByTarget,
  } = useGetCascadeByTarget(
    cascadeByTargetEnabled,
    setCascadeByTargetEnabled,
    targetId,
    departmentId
  );

  const { data: targetsData, refetch: refetchTargets } =
    useGetPerformanceKPITargets(enabled, setEnabled);

  useEffect(() => {
    if (openEdit) {
      if (selectedCascade) {
        setDepartmentId(selectedCascade?.department_id);
        setTargetId(selectedCascade?.target_id);
      }
    } else {
      setDepartmentId(null);
      setTargetId(null);
    }
  }, [openEdit, selectedCascade]);

  useEffect(() => {
    if (targetId) {
      setTargetAvailableWeight(
        targetsDataState.filter((target) => target.id === targetId)?.[0]
          ?.available_weight
      );
    } else {
      setTargetAvailableWeight(null);
    }
  }, [targetId, targetsDataState, selectedCascade]);

  useEffect(() => {
    if (targetId && departmentId) {
      setCascadeByTargetEnabled(true);
    }
  }, [targetId, departmentId]);

  const handleOpenEditDrawer = () => {
    setOpenEdit(!openEdit);
  };

  const handleDeleteCascade = () => {};

  const cancelDeleteCascade = () => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleDepartmentChange = (value) => {
    setDepartmentId(value);
    setTargetId(null);
  };

  useEffect(() => {
    if (targetsData) {
      setTargetsDataState(targetsData);
    }
  }, [targetsData, departmentId]);

  useEffect(() => {
    if (cascadeByTarget) {
      setCascades(
        cascadeByTarget?.payload?.map((cascade) => ({
          id: cascade.id,
          individual_target: cascade.individual_target,
          weight: cascade.weight,
        }))
      );
    }
  }, [cascadeByTarget]);

  useEffect(() => {
    const creds = {
      target_id: targetId,
      cascades,
    };
    console.log("creds", creds);
  }, []);

  useEffect(() => {
    if (cascadeByTarget) {
      const new_array = cascadeByTarget?.payload?.map((cascade, index) => {
        // Calculate the actual score based on user input
        // const user_input = Number(employee_score[index]);
        // const actual_score = parseFloat(
        //   ((user_input / cascade.individual_target) * cascade.weight).toFixed(2)
        // );

        return {
          key: cascade.id,
          sn: index + 1,
          employee: cascade.employee,
          goal: cascade.goal,
          objective: cascade.objective,
          department_target: cascade.department_target,
          individual_target: (
            <Space.Compact
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Input
                size="large"
                id={`score-${cascade.id}`}
                defaultValue={cascade}
                placeholder="Type score here"
                style={{
                  color: "white",
                  cursor: "pointer",
                  border: "1px solid black",
                  borderColor: "#d9d9d9",
                  borderRadius: "6px",
                }}
                bordered
                // value={employee_score[index]}
                onChange={(e) => {}}
              />
              <span
                style={{
                  minWidth: "5rem",
                  width: "fit-content",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "var(--clr-primary)",
                  color: "white",
                }}
              >
                {cascade.unit}
              </span>
            </Space.Compact>
          ),
          cascade,
        };
      });
      setEditCascadeTableData(new_array);
    }
  }, [cascadeByTarget]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      align: "center",
    },
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "Department Target (Unit)",
      dataIndex: "department_target",
      align: "center",
      render: (text, record) => (
        <span>
          {record.department_target} ({record.cascade.unit})
        </span>
      ),
    },
    {
      title: "Individual Target",
      align: "center",
      dataIndex: "individual_target",
      width: "15rem",
      fixed: "right",
    },
  ];

  return (
    <>
      <Space>
        <Button
          type="primary"
          shape="round"
          className=""
          onClick={handleOpenEditDrawer}
        >
          Edit
        </Button>
        <Popconfirm
          title="Delete Cascade"
          description="Are you sure you want to delete this Cascade?"
          onConfirm={handleDeleteCascade}
          onCancel={cancelDeleteCascade}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            className=""
            style={{
              backgroundColor: "red",
            }}
            loading={deleteCascadeIsLoading}
          >
            Delete
          </Button>
        </Popconfirm>
      </Space>

      <Drawer
        title={
          <div className="d-flex flex-column gap-1">
            <h5>Edit Cascade</h5>
            <p>
              Cascading targets can be edited and updated provided the
              respective weightage isn't exceeded
            </p>
          </div>
        }
        width={800}
        onClose={handleOpenEditDrawer}
        open={openEdit}
        closeIcon={false}
        extra={
          <Space>
            <Button danger size="large" onClick={handleOpenEditDrawer}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        className="cascade__edit__drawer"
      >
        <div className="flex flex-col gap-4 w-100">
          <div className="d-flex gap-2">
            <div className="w-100">
              {userProfile?.data?.personal?.is_hod?.length ? (
                userProfile?.data?.personal?.is_hod?.length > 1 ? (
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">Department Category</label>
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select a department"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      style={{
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                      value={departmentId}
                      onChange={handleDepartmentChange}
                      options={userProfile?.data?.personal?.is_hod?.map(
                        (department) => ({
                          value: department?.id,
                          label: department?.name,
                        })
                      )}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">Department Category</label>
                    <Input
                      size="large"
                      defaultValue={
                        userProfile?.data?.personal?.is_hod[0]?.name
                      }
                      className="disabled__input"
                      style={{
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                      disabled
                    />
                  </div>
                )
              ) : (
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">Department Category</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a department"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                    value={departmentId}
                    onChange={handleDepartmentChange}
                    options={systemsDepartments?.payload?.map((department) => ({
                      value: department?.id,
                      label: department?.name,
                    }))}
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-2 w-100">
              <label className="fw-bold fs-6">Targets</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a target"
                optionFilterProp="children"
                value={targetId}
                onChange={(value, option) => {
                  setTargetId(value);
                  setTargetAvailableWeight(option?.items?.available_weight);
                }}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                  width: "100%",
                }}
                options={targetsDataState
                  ?.filter((target) => target.department_id === departmentId)
                  .map((target) => ({
                    value: target?.id,
                    label: `${target?.target} ${target.unit}`,
                    items: target,
                  }))}
              />
            </div>
            {targetAvailableWeight !== null && (
              <div className="d-flex align-items-end">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    minWidth: "10rem",
                  }}
                >
                  {`Available Weight: ${targetAvailableWeight}%`}
                </Button>
              </div>
            )}
          </div>

          <div className="performance_table">
            <Table
              columns={columns}
              dataSource={editCascadeTableData}
              bordered
              pagination={false}
              scroll={{ x: 1000 }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default CascadeTable;
