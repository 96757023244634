import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { requestCompany, setPrivateRequest } from "./requestMethods";
import { getURLSubdomain } from "./helpers";
import { storage_type } from "../config";
import { hashData, dehashData } from "./hash";

const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;

const useGetActiveModules = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["active_modules"],
    async () => {
      let result = await setPrivateRequest().get(`/dashboard/active-modules`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  // useEffect(() => {
  //   if (!window.navigator.onLine) {
  //     setEnabled(false);
  //     queryClient.removeQueries(["system_modules"]);
  //     let resMessage = {};
  //     dispatch({ type: "STOP_SPINNER" });
  //     dispatch({ type: "SYSTEM_MODULES_ERROR", payload: resMessage });
  //   }
  // }, []);

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ACTIVE_MODULES_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["active_modules"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ACTIVE_MODULES_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetDetachedModules = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["detached_modules"],
    async () => {
      let result = await setPrivateRequest().get(`/dashboard/detached-modules`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "DETACHED_MODULES_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["detached_modules"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DETACHED_MODULES_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetCompanyDetails = (enabled, setEnabled, company_url) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["company_details"],
    async () => {
      let result = await requestCompany.get(`/company/${company_url}/profile`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "COMPANY_DETAILS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["company_details"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "COMPANY_DETAILS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetCompanyNotifications = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["company_notifications"],
    async () => {
      let result = await setPrivateRequest().get(`/in-messages`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "COMPANY_NOTIFICATIONS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["company_notifications"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "COMPANY_NOTIFICATIONS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetUserProfile = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/profile`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "USER_PROFILE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_PROFILE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetClientAuditlogs = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["audit_trail", page],
    async () => {
      let result = await requestCompany.get(
        `company/${getURLSubdomain()}/audit-trail?page=${page}`
      );

      result = result?.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      // alert(data);
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CLIENT_AUDITRAIL_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["audit_trail", page]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CLIENT_AUDITRAIL_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch };
};
const useGetInductionTemplate = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["ind_template", page],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/onboarding/induction/template?page=${page}`
      );

      result = result?.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      // alert(data);
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "INDUCTION_TEMPLATE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["ind_template", page]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "INDUCTION_TEMPLATE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetOnboardingTemplate = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["onboarding_template", page],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/onboarding/onboard/template?page=${page}`
      );

      result = result?.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      // alert(data);
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARDING_TEMPLATE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["onboarding_template", page]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ONBOARDING_TEMPLATE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetPendingSlaveUpdate = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile_update"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/slave/pending-profile`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "USER_PENDING_REQUEST_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile_update"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_PENDING_REQUEST_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetPerformanceStat = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["performance_stat"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/performance/statistics`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "PERFORMANCE_STAT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["performance_stat"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PERFORMANCE_STAT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetPerformanceSetup = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["performance_setup"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/performance/settings`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "PERFORMANCE_SETUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["performance_setup"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "PERFORMANCE_SETUP_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetPendingSlaveUpdateDafualt = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile_default", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/slave/pending-default-profile/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile_default", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};

const useGetSlaveHistory = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile_history", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/slave/pending-profile-all/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile_history", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};
const useGetUserDocument = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_document", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/documents?user_id=${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_document", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};
const useGetUserEducationalHistory = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["edu_history", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/educational_history?user_id=${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["edu_history", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};
const useGetUserEmploymentHistory = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["em_history", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/employment_history?user_id=${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["em_history", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};
const useGetUserAutoID = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["id_gen"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/employees/generate-id-number`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "USER_DEFAULT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["id_gen"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_DEFAULT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetPendingSlaveUpdatePending = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile_pending", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/profile/slave/pending-profile/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "USER_PENDING_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile_pending", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_PENDING_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch };
};
const useGetSystemSettingsUtils = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["settings_utils"],
    async () => {
      let result = await setPrivateRequest().get(`settings/utils`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "SETTINGS_UTIL_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["settings_utils"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SETTINGS_UTIL_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemSettings = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["settings_only"],
    async () => {
      let result = await setPrivateRequest().get(`settings`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "SETTINGS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["settings_only"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SETTINGS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetSystemDepartments = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["departments"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/preferences/department`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "DEPARTMENT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["departments"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DEPARTMENT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetSystemBirthdays = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["birthday-list"],
    async () => {
      let result = await setPrivateRequest().get(`/birthday-list`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "BIRTHDAY_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["birthday_list"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "BIRTHDAY_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemHolidays = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["holidays"],
    async () => {
      let result = await setPrivateRequest().get(`/holiday`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "HOLIDAY_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["holidays"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "HOLIDAY_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemEventType = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["event_type"],
    async () => {
      let result = await setPrivateRequest().get(`/settings/event-type`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "EVENT_TYPE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["event_type"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "EVENT_TYPE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemCombinedEvents = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["combined_events"],
    async () => {
      let result = await setPrivateRequest().get(`/holiday/combined-events`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "COMBINED_EVENTS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["combined_events"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "COMBINED_EVENTS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetSystemAnniversary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["anniversary"],
    async () => {
      let result = await setPrivateRequest().get(`/holiday/combined-events`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNIVERSARY_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["anniversary"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "ANNIVERSARY_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemBanks = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["sys_banks"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/preferences/bank`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "SYS_BANKS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["sys_banks"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SYS_BANKS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetSystemScheduledEvent = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_scheduled_event"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/event`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GET_SCHEDULED_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_scheduled_event"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GET_SCHEDULED_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetSystemRoleDesignation = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["role_designation"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/job-role?limit=no`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "DESIGNATION_ROLE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["role_designation"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "DESIGNATION_ROLE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetUploadsToCategory = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["onboarding_to_cat"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/onboarding/setup/uploads`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GET_ONBOARDING_TO_CAT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["onboarding_to_cat"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GET_ONBOARDING_TO_CAT_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetNewHireList = (enabled, setEnabled, stage) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  let result;
  const { data, error, refetch, isLoading } = useQuery(
    ["hire_new_list", stage],
    async () => {
      if (stage) {
        result = await setPrivateRequest().get(
          `hrm/onboarding/pre-employment/new-hire?stage=${stage}`
        );
      } else {
        result = await setPrivateRequest().get(
          `hrm/onboarding/pre-employment/new-hire`
        );
      }

      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(
    () => {
      if (isLoading === true) {
        dispatch({ type: "START_SPINNER" });
      }
      if (data) {
        dispatch({ type: "STOP_SPINNER" });
        // console.log(data);
        dispatch({ type: "GET_NEW_HIRE_SUCCESS", payload: data });
        setEnabled(false);
      }

      if (error) {
        setEnabled(false);
        queryClient.removeQueries(["hire_new_list", stage]);
        let resMessage = error?.response?.data;
        dispatch({ type: "STOP_SPINNER" });
        dispatch({ type: "GET_NEW_HIRE_ERROR", payload: resMessage });
      }
    },
    [dispatch, isLoading, data, error, setEnabled, queryClient, stage],
    stage
  );

  return { data, refetch, isLoading };
};
const useGetNewHireDeleteList = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["hire_deleted_list"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/onboarding/pre-employment/new-hire-trash`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GET_NEW_HIRE_DEL_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["hire_deleted_list"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GET_NEW_HIRE_DEL_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["new_hire_overview"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/onboarding/overview`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GET_NEW_HIRE_OVERVIEW_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["new_hire_overview"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GET_NEW_HIRE_OVERVIEW_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetOnboardUserProfile = (enabled, setEnabled, id, admin) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["onboard_profile_user", id],
    async () => {
      let result;
      if (admin) {
        result = await setPrivateRequest().get(`hire/${id}/profile?adm=true`);
      } else {
        result = await setPrivateRequest().get(`hire/${id}/profile`);
      }

      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GET_ONBOARD_PROFILE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["onboard_profile_user", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GET_ONBOARD_PROFILE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch, isLoading };
};

const useGetFinacialYears = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["financial_years"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/performance/initiate`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "FINANCIAL_YEAR_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["financial_years"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "FINANCIAL_YEAR_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceDept = (enabled, setEnabled, year) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["performance_dept", year],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/plan?year=${year}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "PLAN_DEPARTMENT_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["performance_dept", year]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, year]);

  return { data, refetch, isLoading };
};

const useGetPerformanceDeptEmp = (enabled, setEnabled, id, year) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["performance_dept_emp", id, year],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/plan/department?year=${year}&department=${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "PLAN_EMPLOYEE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["performance_dept_emp", id, year]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id, year]);

  return { data, refetch, isLoading };
};

const useGetAppWorkList = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["approval_work_list"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/performance/plan/approval-workflow`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "LIST_WORKFLOW_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["approval_work_list"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetApprovalNumber = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["approval_numbers"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/performance/plan/approval-workflow-chain`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "OVERVIEW_COMPENSATIONS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["approval_numbers"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetGoalsBreakdown = (enabled, setEnabled, year, dept_id, row_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["goal_break_down", year, dept_id, row_id],
    async () => {
      let result;

      if (dept_id && year) {
        result = await setPrivateRequest().post(
          `hrm/performance/department/goal-breakdown?department_id=${dept_id}&year=${year}`
        );
      } else if (year) {
        result = await setPrivateRequest().post(
          `hrm/performance/department/goal-breakdown?year=${year}`
        );
      } else if (dept_id) {
        result = await setPrivateRequest().post(
          `hrm/performance/department/goal-breakdown?department_id=${dept_id}`
        );
      }
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "GOAL_BREAKDOWN_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["goal_break_down", year, dept_id, row_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setEnabled,
    queryClient,
    year,
    dept_id,
    row_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetUserNugdeRequest = (enabled, setEnabled, user_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_nudge", user_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/performance/plan/approvals/${user_id}/nudge`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    // const fake = {
    //   status: "success",
    //   message: "Message sent successfully",
    //   payload: [
    //     {
    //       approval_id: 1,
    //       from: [
    //         {
    //           id: 1,
    //           e_id: "eyJpdiI6IkhQeFR0RFlRZ3pVMDdPcVlSSVAya2c9PSIsInZhbHVlIjoiQ3dLdmpORGh5c2ZyWUtjTXFWdTM0dz09IiwibWFjIjoiMWYwNzE5OWU4YmY0NjU4Yjk0MzJjMjMwZTUzZjJlMzJjYmJhZDJmZjExZmFmMjdlZjc3NDczZDllMzZlNWNlMSIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "",
    //           title: null,
    //           fullname: "Administrator  ",
    //           first_name: "Administrator",
    //           last_name: null,
    //           other_names: null,
    //           gender: "m",
    //           status: "active",
    //           avatar: null,
    //           designation: null,
    //           contact_information: {
    //             id: 1,
    //             user_id: 1,
    //             telephone: null,
    //             mobile_number: null,
    //             primary_email: "ebenezer.babalola@ss-limited.com",
    //             secondary_email: null,
    //             home_address: null,
    //           },
    //         },
    //       ],
    //       to: [
    //         {
    //           id: 2,
    //           e_id: "eyJpdiI6InZJRWZ5OTk1Q3lreEpTV1BzWVpYeEE9PSIsInZhbHVlIjoiWGd5UlBBREE5T2xkTkUvSngxYWtVQT09IiwibWFjIjoiNmVlMzEzOGQwOWFjNDRjN2ZiNzMwYTU4Y2EwZmNlM2VhNzJkMTRlZGFlY2Y0YmEzY2Q2YmU4NTMzYmE1NTU4OSIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "SSL-001",
    //           title: "Mr",
    //           fullname: "Fem Taiwo Babalola",
    //           first_name: "Fem",
    //           last_name: "Babalola",
    //           other_names: "Taiwo",
    //           gender: "f",
    //           status: "inactive",
    //           avatar: null,
    //           designation: null,
    //           contact_information: null,
    //         },
    //       ],
    //       message: "Hello this is a message to test this nudger",
    //       created_at: "2023-06-11T07:31:16.000000Z",
    //       service_requested_by: "Self",
    //     },
    //     {
    //       approval_id: 1,
    //       from: [
    //         {
    //           id: 2,
    //           e_id: "eyJpdiI6Im9UUjNEY1J4Mkl0SW5DY2ZoT3VoOEE9PSIsInZhbHVlIjoiOFZvdjNJdXgrMTJlYnVtaTdMUkgvZz09IiwibWFjIjoiN2Y1ZTUzNTg2OGUyZWYxM2JkM2IzMDgxZGVlMjMyYWZmY2Y1YTg2ZTQ4NDlkM2NjMWY0MTQxY2RmOWNiOTY5ZCIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "SSL-001",
    //           title: "Mr",
    //           fullname: "Fem Taiwo Babalola",
    //           first_name: "Fem",
    //           last_name: "Babalola",
    //           other_names: "Taiwo",
    //           gender: "f",
    //           status: "inactive",
    //           avatar: null,
    //           designation: null,
    //           contact_information: null,
    //         },
    //       ],
    //       to: [
    //         {
    //           id: 1,
    //           e_id: "eyJpdiI6Ik1MakIwOW5jRWFRS3dVcXZxdjFhVWc9PSIsInZhbHVlIjoidzZGZmtPZUJ5a0xHRWJveUJVMlkwQT09IiwibWFjIjoiZTI0MzhmZTY4MGI1OWY3ZWFjYTIwOTIxOTIzMDA1MTE4OTU2NDkzZjA3M2FjMGUwNGUwOTMyM2M2YzQzMWU1ZiIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "",
    //           title: null,
    //           fullname: "Administrator  ",
    //           first_name: "Administrator",
    //           last_name: null,
    //           other_names: null,
    //           gender: "m",
    //           status: "active",
    //           avatar: null,
    //           designation: null,
    //           contact_information: {
    //             id: 1,
    //             user_id: 1,
    //             telephone: null,
    //             mobile_number: null,
    //             primary_email: "ebenezer.babalola@ss-limited.com",
    //             secondary_email: null,
    //             home_address: null,
    //           },
    //         },
    //       ],
    //       message: "Hello this is a message to test this nudger",
    //       created_at: "2023-06-11T07:32:06.000000Z",
    //       service_requested_by: null,
    //     },
    //     {
    //       approval_id: 1,
    //       from: [
    //         {
    //           id: 1,
    //           e_id: "eyJpdiI6IjNGbWx5b0h4Tm5XT21iRFU4NExMTmc9PSIsInZhbHVlIjoiTlVpeWQ3K2tsdFUvRy9xZUtzWGhZdz09IiwibWFjIjoiODgyYWM4OWE4N2E1YzA2NDY3ODg1YjFjNzIxMzdmMjVkZTc0NWY1OWI5MDQyODUwYTcwMWMxZDliODM0ZTFkZiIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "",
    //           title: null,
    //           fullname: "Administrator  ",
    //           first_name: "Administrator",
    //           last_name: null,
    //           other_names: null,
    //           gender: "m",
    //           status: "active",
    //           avatar: null,
    //           designation: null,
    //           contact_information: {
    //             id: 1,
    //             user_id: 1,
    //             telephone: null,
    //             mobile_number: null,
    //             primary_email: "ebenezer.babalola@ss-limited.com",
    //             secondary_email: null,
    //             home_address: null,
    //           },
    //         },
    //       ],
    //       to: [
    //         {
    //           id: 2,
    //           e_id: "eyJpdiI6Im0zRjdHMXJBZ3hKOGJRcnJvRjhWT2c9PSIsInZhbHVlIjoiOWNSTGhLbHM1dGdKemQzTFdqMUZIUT09IiwibWFjIjoiNzVhNDEyNTJjZTIwNDljM2RkOTA3YzJiZWM0ZjkxOWUzYzJlMWJhNjMyNDRkNzJhNWNlZTAxODQ3MTAwNmNhNiIsInRhZyI6IiJ9",
    //           tenant_id: "soft_solutions_limited",
    //           employee_id: "SSL-001",
    //           title: "Mr",
    //           fullname: "Fem Taiwo Babalola",
    //           first_name: "Fem",
    //           last_name: "Babalola",
    //           other_names: "Taiwo",
    //           gender: "f",
    //           status: "inactive",
    //           avatar: null,
    //           designation: null,
    //           contact_information: null,
    //         },
    //       ],
    //       message: "Hello this is a message to test this nudger",
    //       created_at: "2023-06-11T07:34:48.000000Z",
    //       service_requested_by: "Self",
    //     },
    //   ],
    // };
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      console.log({ data });

      if (data?.payload?.length) {
        dispatch({ type: "GET_USER_NUDGE_SUCCESS", payload: data });
      } else {
        dispatch({ type: "GET_USER_NUDGE_SUCCESS", payload: data });
        // dispatch({ type: "GET_USER_NUDGE_SUCCESS", payload: fake });
      }
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_nudge", user_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, user_id]);

  return { data, refetch, isLoading };
};

// compensations
const useGetCompensationsOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["overviews_compensation"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/compensation`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "OVERVIEW_COMPENSATIONS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["overviews_compensation"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetAllBenefitsInKind = (enabled, setEnabled, search) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["benefit_in_kind", search],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/benefits-in-kind?search=${search}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "BENEFITS_IN_KIND_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["benefit_in_kind", search]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, search]);

  return { data, refetch, isLoading };
};
const useGetAllGradedGrades = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["graded_grades"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/compensation/grading`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "BENEFITS_IN_KIND_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["graded_grades"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetOneBenefitsInKind = (enabled, setEnabled, benefit) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["benefit_one_kind", benefit],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/benefits-in-kind/${benefit}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "BENEFITS_ONE_KIND_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["benefit_one_kind", benefit]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, benefit]);

  return { data, refetch, isLoading };
};
const useGetCompensationsSettings = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["com_settings"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/compensation/settings`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "COM_SETTINGS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["com_settings"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAvailableJobGrade = (enabled, setEnabled, search, group_by) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["avail_job_grade", search, group_by],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/job-role?limit=no&search=${search}&group_by=${group_by}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "AVAILABLE_JOB_GRADE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["avail_job_grade", search, group_by]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setEnabled,
    queryClient,
    search,
    group_by,
  ]);

  return { data, refetch, isLoading };
};

const useGetOneAvailableJobGrade = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["one_avail_job", id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/job-role/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ONE_AVAIL_JOB_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["one_avail_job", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch, isLoading };
};

const useGetSystemGrade = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_grade"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/helper/system-grades`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_GRADE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_grade"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetSystemStep = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_step"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/helper/system-steps`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_STEP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_step"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetSystemCurrency = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_currency"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/helper/currencies`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_STEP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_currency"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetSystemDeptCategory = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_dept_cat"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/helper/department-categories`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_STEP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_dept_cat"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetSystemJobRole = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_step_job"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/helper/job-roles`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_STEP_job_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_step"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetSystemSalaryStructure = (
  enabled,
  setEnabled,
  grade_id,
  payee_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["sal_components", grade_id],
    async () => {
      let result = await setPrivateRequest().get(
        // `hrm/compensation/payroll/${payee_id}/get-wage-item-setting/${grade_id}`
        `hrm/compensation/payroll/get-wage-item-setting/${grade_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "sal_components_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["sal_components", grade_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setEnabled,
    queryClient,
    grade_id,
    payee_id,
  ]);

  return { data, refetch, isLoading };
};
const useGetSystemSalaryStructureOthers = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_step_others"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/compensation/salary-structure/others`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "SYSTEM_STEP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_step_others"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAllSystemSteps = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["allSystem_steps"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/helper/system-steps?format=true`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALLSYSTEM_STEPS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["allSystem_steps"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAllSystemGrades = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_sys_grades"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/helper/system-grades?format=true`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_SYSTEMS_GRADES_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_sys_grades"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetIDCardPending = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_pending_id_card", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/identity-card/request/pending${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_pending_id_card", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetIDCardRejected = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_reject_id_card", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/identity-card/request/rejected${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_reject_id_card", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetIDCardApproved = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_approved_id_card", page],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/identity-card/request/approved${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_approved_id_card", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetIDCardALL = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_id_card", page],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/identity-card/request${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_id_card", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetReportPending = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_pending_report", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/identity-card/report/pending${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_pending_report", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetReportResolved = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_resolved_report", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/identity-card/report/resolved${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_resolved_report", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetReportAll = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_report", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/identity-card/report${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_report", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetMyReportHis = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["report_his", page],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/identity-card/report/report-history${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["report_his", page]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetIDCardOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["id_card_overview"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/identity-card/overview`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "OVERVIEW_IDCARD_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["id_card_overview"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetLeaveOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["leave_overview"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/leave/summary`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "OVERVIEW_IDCARD_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["leave_overview"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAllLeaveHistory = (
  enabled,
  setEnabled,
  page,
  from,
  to,
  department_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_leave_history", page, from, to, department_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/hr/all-leave-history?department_id=${department_id}&page=${page}&from=${from}&to=${to}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "all_leave_history",
        page,
        from,
        to,
        department_id,
      ]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetMyTeamLeaveHis = (
  enabled,
  setEnabled,
  page,
  from,
  to,
  department_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["team_leave_his", page, from, to, department_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/line-manager/team-leave-history?department_id=${department_id}&page=${page}&from=${from}&to=${to}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "team_leave_his",
        page,
        from,
        to,
        department_id,
      ]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};
const useGetMyTeamLeaveHisHR = (enabled, setEnabled, page, from, to) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["team_leave_his", page, from, to],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/hr/team-leave-history${page ? `?page=${page}` : ""}${
          from ? `?from=${from}` : ""
        }${to ? `?to=${to}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["team_leave_his", page, from, to]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetLeaveGroup = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_leavegroup"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/grade-leave-distribution/leave-groups`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_LEAVEGROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_leavegroup"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetUserLeaveGroup = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_user_leavegroup"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/grade-leave-distribution/user/${id}`
      );
      result = result?.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_LEAVEGROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_user_leavegroup"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch, isLoading };
};
const useGetUserLeaveGroupTeam = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_user_leavegroupteam"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/grade-leave-distribution/user/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_LEAVEGROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_user_leavegroupteam"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetLeaveHistory = (enabled, setEnabled, pageLHstory, from, to) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["leave_history"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/leave-history?page=${pageLHstory}&from=${from}&to=${to}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );
  const fakeData = {
    status: "success",
    message: "Leave history fetched successfully",
    payload: {
      data: [
        {
          id: 1,
          user_id: 2,
          backup_employee: {
            id: 1,
            employee_id: null,
            avatar: null,
            first_name: "Administrator",
            last_name: null,
            contact_information: {
              id: 1,
              user_id: 1,
              telephone: null,
              mobile_number: null,
              primary_email: "ebenezer.babalola@ss-limited.com",
              secondary_email: null,
              home_address: null,
            },
          },
          backup_employee_approval: "APPROVED",
          backup_employee_comment: null,
          line_manager: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          line_manager_approval: "PENDING",
          line_manager_comment: null,
          hr: null,
          hr_approval: "PENDING",
          hr_comment: null,
          start_date: "2023-07-01 00:00:00",
          end_date: "2023-07-05 00:00:00",
          duration: 4,
          attachment:
            "https://res.cloudinary.com/dvhfyuilj/image/upload/v1690715918/pyf65zplevozr7doexlm.pdf",
          comment: "This is a leave application",
          deleted_at: null,
          created_at: "2023-07-30T11:18:38.000000Z",
          type: "normal",
          status: "PENDING",
          approval_date: false,
          awaiting: {
            actor: "LINE MANAGER",
            user_id: 2,
          },
          leave_group: {
            id: 1,
            title: "Sick",
            description: "Suffering from disease or illness; unwell; ill",
            deleted_at: null,
            created_at: "2023-07-30T08:58:03.000000Z",
            updated_at: "2023-07-30T08:58:03.000000Z",
          },
        },
        {
          id: 119,
          user_id: 2,
          backup_employee: {
            id: 1,
            employee_id: null,
            avatar: null,
            first_name: "Administrator",
            last_name: null,
            contact_information: {
              id: 1,
              user_id: 1,
              telephone: null,
              mobile_number: null,
              primary_email: "ebenezer.babalola@ss-limited.com",
              secondary_email: null,
              home_address: null,
            },
          },
          backup_employee_approval: "PENDING",
          backup_employee_comment: null,
          line_manager: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          line_manager_approval: "PENDING",
          line_manager_comment: null,
          hr: null,
          hr_approval: "PENDING",
          hr_comment: null,
          start_date: "2023-07-01 00:00:00",
          end_date: "2023-07-05 00:00:00",
          duration: 4,
          attachment:
            "https://res.cloudinary.com/dvhfyuilj/image/upload/v1690730510/a7821j3baqytccdh09ad.pdf",
          comment: "This is a leave application",
          deleted_at: null,
          created_at: "2023-07-30T15:21:51.000000Z",
          type: "annual",
          status: "PENDING",
          approval_date: false,
          awaiting: {
            actor: "BACKUP EMPLOYEE",
            user_id: 1,
          },
          leave_group: {
            id: 1,
            title: "Sick",
            description: "Suffering from disease or illness; unwell; ill",
            deleted_at: null,
            created_at: "2023-07-30T08:58:03.000000Z",
            updated_at: "2023-07-30T08:58:03.000000Z",
          },
        },
      ],
      links: {
        first:
          "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/leave-history?page=1",
        last: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/leave-history?page=1",
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [
          {
            url: null,
            label: "&laquo; Previous",
            active: false,
          },
          {
            url: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/leave-history?page=1",
            label: "1",
            active: true,
          },
          {
            url: null,
            label: "Next &raquo;",
            active: false,
          },
        ],
        path: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/leave-history",
        per_page: "30",
        to: 2,
        total: 2,
      },
    },
  };

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      //change
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "LEAVE_HISTORY_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["leave_history"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAnnualLeaveHistory = (
  enabled,
  setEnabled,
  pageAnnualLHstory,
  from,
  to
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["annual_leave_history"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/annual-leave-plan-history?page=${pageAnnualLHstory}?${
          from ? `?from=${from}` : ""
        }${to ? `?to=${to}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );
  const fakeData = {
    status: "success",
    message: "Annual leave history fetched successfully",
    payload: {
      data: [
        {
          id: 119,
          user_id: 2,
          backup_employee: {
            id: 1,
            employee_id: null,
            avatar: null,
            first_name: "Administrator",
            last_name: null,
            contact_information: {
              id: 1,
              user_id: 1,
              telephone: null,
              mobile_number: null,
              primary_email: "ebenezer.babalola@ss-limited.com",
              secondary_email: null,
              home_address: null,
            },
          },
          backup_employee_approval: "PENDING",
          backup_employee_comment: null,
          line_manager: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          line_manager_approval: "PENDING",
          line_manager_comment: null,
          hr: null,
          hr_approval: "PENDING",
          hr_comment: null,
          start_date: "2023-07-01 00:00:00",
          end_date: "2023-07-05 00:00:00",
          duration: 4,
          attachment:
            "https://res.cloudinary.com/dvhfyuilj/image/upload/v1690730510/a7821j3baqytccdh09ad.pdf",
          comment: "This is a leave application",
          deleted_at: null,
          created_at: "2023-07-30T15:21:51.000000Z",
          type: "annual",
          status: "PENDING",
          approval_date: false,
          leave_group: {
            id: 1,
            title: "Sick",
            description: "Suffering from disease or illness; unwell; ill",
            deleted_at: null,
            created_at: "2023-07-30T08:58:03.000000Z",
            updated_at: "2023-07-30T08:58:03.000000Z",
          },
        },
      ],
      links: {
        first:
          "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/annual-leave-history?page=1",
        last: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/annual-leave-history?page=1",
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [
          {
            url: null,
            label: "&laquo; Previous",
            active: false,
          },
          {
            url: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/annual-leave-history?page=1",
            label: "1",
            active: true,
          },
          {
            url: null,
            label: "Next &raquo;",
            active: false,
          },
        ],
        path: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/annual-leave-history",
        per_page: "30",
        to: 1,
        total: 1,
      },
    },
  };
  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNUAL_LEAVE_HISTORY_SUCCESS", payload: data }); //change data
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["annual_leave_history"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetTeamLeaveHistory = (
  enabled,
  setEnabled,
  pageTeamHistory,
  from,
  to
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["teamLeave_history"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/team-leave-history?page=${pageTeamHistory}&from=${from}&to=${to}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  const fakeData = {
    status: "success",
    message: "Leave track fetched successfully",
    payload: {
      data: [
        {
          user: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          backup_employee: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          backup_employee_approval: "APPROVED",
          backup_employee_comment: null,
          line_manager: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          line_manager_approval: "PENDING",
          line_manager_comment: null,
          hr: null,
          hr_approval: "PENDING",
          hr_comment: null,
          created_at: "2023-07-30T11:18:38.000000Z",
          deleted_at: null,
          start_date: "2023-07-01 00:00:00",
          end_date: "2023-07-05 00:00:00",
          duration: 4,
          type: "normal",
          status: "PENDING",
          approval_date: false,
          awaiting: {
            actor: "LINE MANAGER",
            user_id: 2,
          },
          leave_group: {
            id: 1,
            title: "Sick",
            description: "Suffering from disease or illness; unwell; ill",
            deleted_at: null,
            created_at: "2023-07-30T08:58:03.000000Z",
            updated_at: "2023-07-30T08:58:03.000000Z",
          },
        },
        {
          user: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          backup_employee: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          backup_employee_approval: "PENDING",
          backup_employee_comment: null,
          line_manager: {
            id: 2,
            employee_id: "SSL-0008",
            avatar: null,
            first_name: "Ebenezer",
            last_name: "Babalola",
            contact_information: {
              id: 2,
              user_id: 2,
              telephone: "+2347035418523",
              mobile_number: null,
              primary_email: "enterprise@ss-limited.com",
              secondary_email: "enterprise@ss-limited.com",
              home_address: null,
            },
          },
          line_manager_approval: "PENDING",
          line_manager_comment: null,
          hr: null,
          hr_approval: "PENDING",
          hr_comment: null,
          created_at: "2023-07-30T15:21:51.000000Z",
          deleted_at: null,
          start_date: "2023-07-01 00:00:00",
          end_date: "2023-07-05 00:00:00",
          duration: 4,
          type: "annual",
          status: "PENDING",
          approval_date: false,
          awaiting: {
            actor: "BACKUP EMPLOYEE",
            user_id: 1,
          },
          leave_group: {
            id: 1,
            title: "Sick",
            description: "Suffering from disease or illness; unwell; ill",
            deleted_at: null,
            created_at: "2023-07-30T08:58:03.000000Z",
            updated_at: "2023-07-30T08:58:03.000000Z",
          },
        },
      ],
      links: {
        first:
          "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/team-leave-history?page=1",
        last: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/team-leave-history?page=1",
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [
          {
            url: null,
            label: "&laquo; Previous",
            active: false,
          },
          {
            url: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/team-leave-history?page=1",
            label: "1",
            active: true,
          },
          {
            url: null,
            label: "Next &raquo;",
            active: false,
          },
        ],
        path: "https://ssl-cloud-v2-purchases.run/soft_solutions_limited/api/hrm/leave/team-leave-history",
        per_page: "30",
        to: 2,
        total: 2,
      },
    },
  };

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "TEAM_LEAVE_HISTORY_SUCCESS", payload: data }); // change data
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["teamLeave_history"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAnnualLeavePlan = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["annual_leave_plan"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/leave/annual-leave-plan`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );
  // const fakeData = {
  //   "status": "success",
  //   "message": "My annual leave plan fetched successfully",
  //   "payload": [
  //       "2023-07-30",
  //       "2023-08-01",
  //       "2023-08-02",
  //       "2023-08-03",
  //       "2023-09-01",
  //       "2023-09-02",
  //       "2023-09-03",
  //       "2023-09-04",
  //   ]
  // }
  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNUAL_LEAVE_PLAN_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["annual_leave_plan"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetDeductionMasters = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_deduction_masters"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/payroll/get-deduction-master`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "get_deduction_masters_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_deduction_masters"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

// const useGetTeamAnnualLeavePlan = (enabled, setEnabled) => {
//   const queryClient = useQueryClient();
//   const dispatch = useDispatch();
//   const { data, error, refetch, isLoading } = useQuery(
//     ["annual_leave_plan"],
//     async () => {
//       let result = await setPrivateRequest().get(`hrm/leave/annual-leave-plan`);
//       result = result.data;
//       return result;
//     },
//     { enabled: enabled, manual: true, retry: 1 }
//   );
// const fakeData = {
//   "status": "success",
//   "message": "My annual leave plan fetched successfully",
//   "payload": [
//       "2023-07-30",
//       "2023-08-01",
//       "2023-08-02",
//       "2023-08-03",
//       "2023-09-01",
//       "2023-09-02",
//       "2023-09-03",
//       "2023-09-04",
//   ]
// }
//   useEffect(() => {
//     if (isLoading === true) {
//       dispatch({ type: "START_SPINNER" });
//     }
//     if (fakeData) {
//       dispatch({ type: "STOP_SPINNER" });
//       // console.log(data);
//       dispatch({ type: "ANNUAL_LEAVE_PLAN_SUCCESS", payload: fakeData });
//       setEnabled(false);
//     }

//     if (error) {
//       setEnabled(false);
//       queryClient.removeQueries(["annual_leave_plan"]);
//       let resMessage = error?.response?.data;
//       dispatch({ type: "STOP_SPINNER" });
//       dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
//     }
//   }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

//   return { data, refetch, isLoading };
// };

// const useGetTeamAnnualLeavePlan = (enabled, setEnabled) => {
//   const queryClient = useQueryClient();
//   const dispatch = useDispatch();
//   const { data, error, refetch, isLoading } = useQuery(
//     ["annual_team_leave_plan"],
//     async () => {
//       let result = await setPrivateRequest().get(
//         `hrm/leave/team-annual-leave-plan`
//       );
//       result = result.data;
//       return result;
//     },
//     { enabled: enabled, manual: true, retry: 1 }
//   );
//   // const fakeData = {
//   //   "status": "success",
//   //   "message": "My annual leave plan fetched successfully",
//   //   "payload": [
//   //       "2023-07-30",
//   //       "2023-08-01",
//   //       "2023-09-01",
//   //       "2023-09-02",
//   //       "2023-09-03",
//   //       "2023-09-04",
//   //   ]
//   // }
//   useEffect(() => {
//     if (isLoading === true) {
//       dispatch({ type: "START_SPINNER" });
//     }
//     if (data) {
//       dispatch({ type: "STOP_SPINNER" });
//       // console.log(data);
//       dispatch({ type: "ANNUAL_TEAM_LEAVE_PLAN_SUCCESS", payload: data });
//       setEnabled(false);
//     }

//     if (error) {
//       setEnabled(false);
//       queryClient.removeQueries(["annual_team_leave_plan"]);
//       let resMessage = error?.response?.data;
//       dispatch({ type: "STOP_SPINNER" });
//       dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
//     }
//   }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

//   return { data, refetch, isLoading };
// };
const useGetTeamAnnualLeavePlanHr = (enabled, setEnabled, page, from, to) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["annual_team_leave_plan_hr"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/all-annual-leave-plan-history?page=${page}?${
          from ? `?from=${from}` : ""
        }${to ? `?to=${to}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNUAL_TEAM_LEAVE_PLAN_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["annual_team_leave_plan_hr"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetTeamAnnualLeavePlan = (
  enabled,
  setEnabled,
  page,
  from,
  to,
  dept
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["annual_team_leave_plan_team"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/team-annual-leave-plan-history/${dept}?page=${page}?${
          from ? `?from=${from}` : ""
        }${to ? `?to=${to}` : ""}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNUAL_TEAM_LEAVE_PLAN_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["annual_team_leave_plan_team"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetTeamAnnualLeavePlanTeam = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["annual_team_leave_plan_team"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/line-manager/team-annual-leave-plan?page=${page}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ANNUAL_TEAM_LEAVE_PLAN_TEAM_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["annual_team_leave_plan_team"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAllLeaveGroup = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_leave_group"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/grade-leave-distribution/leave-groups`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_LEAVE_GROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_leave_group"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetAllLeaveGroupDistribution = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_leave_distribution"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/grade-leave-distribution`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_LEAVE_DISTRIBUTION_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_leave_di"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetTrackLeave = (enabled, setEnabled, track_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["track_leave", track_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/leave-track/${track_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "ALL_LEAVE_DISTRIBUTION_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["track_leave", track_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, track_id]);

  return { data, refetch, isLoading };
};

const useMyTeamMembers = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["my_team_member"],
    async () => {
      let result = await setPrivateRequest().get(`hrm/profile/team-members`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "MY_TEAM_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["my_team_member"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetUserProfileAny = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_profile_any", id],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/profile?user_id=${id}`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      // dispatch({ type: "USER_PROFILE_any_, idSUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_profile_any", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "USER_PROFILE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch, isLoading };
};
const useGetUserAnnualPlanAny = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_annual_plan_any", id],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/leave/annual-leave-plan?user_id=${id}`
      );
      result = result.data;

      return result;
      // return {
      //   status: "success",
      //   message: "My annual leave plan fetched successfully",
      //   payload: [
      //     "2024-02-13 00:00:00",
      //     "2024-02-14 00:00:00",
      //     "2024-02-15 00:00:00",
      //     "2024-03-21 00:00:00",
      //     "2024-03-20 00:00:00",
      //   ],
      // };
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      console.log({ data });
      // dispatch({ type: "USER_annual_plan_any_, idSUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_annual_plan_any", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, id]);

  return { data, refetch, isLoading };
};
const useGetUserAnnualPlan = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["user_annual_plan_my"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/leave/annual-leave-plan`
      );
      result = result.data;

      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      console.log({ data });
      // dispatch({ type: "USER_annual_plan_any_, idSUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["user_annual_plan_my"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGradeWithoutBands = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["grades_without_bands"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/grading/breakdown?step=false`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "MY_TEAM_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["grades_without_bands"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGradeWitBands = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["grades_without_bands"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/grading/breakdown?step=true`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "MY_TEAM_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["grades_without_bands"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPayStructure = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["grades_without_bands"],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/compensation/pay-structure/breakdown?benefit=true`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // console.log(data);
      dispatch({ type: "MY_TEAM_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["grades_without_bands"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetCompanies = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_companies"],
    async () => {
      let result = await setPrivateRequest().get(`/org`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_companies"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetBranches = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_branches"],
    async () => {
      let result = await setPrivateRequest().get(`/branch`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_branches"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetBranchDetails = (enabled, setEnabled, branchId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_branch_details", branchId],
    async () => {
      let result = await setPrivateRequest().get(`/branch/${branchId}`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_branch_details", branchId]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, branchId]);

  return { data, refetch, isLoading };
};

const useGetSystemHr = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_sys_hr"],
    async () => {
      let result = await setPrivateRequest().get(`/settings/system-hr/45`);

      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_sys_hr"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceSettings = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_settings"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/performance/settings`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_settings"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetLeaveApprovalSettings = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["leave_approval_settings"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/leave/leave-approval-setting`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["leave_approval_settings"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceMeasurementUnits = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_units"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/performance/get-units`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_units"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceSections = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_sections"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/get-sections`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_sections"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceGoals = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_goals"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-goals`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_goals"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceGoalObjectives = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_goal_objectives"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-objective`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_goal_objectives"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceObjectiveKPIs = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_objective_kpis"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-kpi`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_objective_kpis"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformanceSettingsHistory = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_settings_history"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/get-performance-settings-history`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_settings_history"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPerformancePlanningHistory = (
  historyEnabled,
  setHistoryEnabled,
  start_date,
  end_date
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_history", start_date, end_date],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-history?start_date=${start_date}&end_date=${end_date}`
      );
      result = result.data;
      return result;
    },
    { enabled: historyEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setHistoryEnabled(false);
    }

    if (error) {
      setHistoryEnabled(false);
      queryClient.removeQueries([
        "get_performance_history",
        start_date,
        end_date,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setHistoryEnabled,
    queryClient,
    historyEnabled,
    start_date,
    end_date,
  ]);

  return { data, refetch, isLoading };
};

const useGetPerformanceKPITargets = (enabled, setEnabled, duration) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_kpi_target", duration],
    async () => {
      let result;
      if (duration) {
        result = await setPrivateRequest().get(
          `/hrm/performance/initiate/get-target?duration=${duration}`
        );
      } else {
        result = await setPrivateRequest().get(
          `/hrm/performance/initiate/get-target`
        );
      }
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_kpi_target", duration]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, duration]);

  return { data, refetch, isLoading };
};

const useGetEmployeesByDepartment = (enabled, setEnabled, departmentID) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_employees_by_department", departmentID],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/preferences/department/employee/${departmentID}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_employees_by_department", departmentID]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, departmentID]);

  return { data, refetch, isLoading };
};

const useGetCascade = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_cascade"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-cascade/data`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_cascade"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetCascadeByTarget = (enabled, setEnabled, targetId, departmentId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_cascade_by_target"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/initiate/get-cascade-by-target/${targetId}/${departmentId}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_cascade_by_target"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAppraisalSettings = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_appraisal_settings"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/settings`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_appraisal_settings"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAppraisalsApproved = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_appraisal_approved"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get?authorised=true`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_appraisal_approved"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAppraisalsPending = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_appraisal_pending"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get?authorised=false`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_appraisal_pending"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};
const useGetPerformancePlans = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_plans"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/performance/get-plan`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_plans"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetEmployeeScoreCard = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_employee_score_card"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get-employee-score-card`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_employee_score_card"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetTeamScoreCard = (teamEnabled, setTeamEnabled, employee_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_team_score_card", employee_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get-team-score-card/${employee_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: teamEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setTeamEnabled(false);
    }

    if (error) {
      setTeamEnabled(false);
      queryClient.removeQueries(["get_team_score_card", employee_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setTeamEnabled,
    queryClient,
    employee_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetToLeaveHis = (
  enabled,
  setEnabled,
  page,
  from,
  to,
  department_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["my_to_approve", page, from, to, department_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/pending-leave-request-history?page=${page}&from=${from}&to=${to}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "my_to_approve",
        page,
        from,
        to,
        department_id,
      ]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setEnabled,
    queryClient,
    page,
    from,
    to,
    department_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetLineManagerSubordinates = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_line_manager_subordinates"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get-line-manager-subordinate`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_line_manager_subordinates"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetLineManagerAppraisal = (enabled, setEnabled, employee_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_line_manager_appraisal", employee_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/get-appraiser-summary-comments/${employee_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_line_manager_appraisal", employee_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, employee_id]);

  return { data, refetch, isLoading };
};

const useGetNextLevelAppraisees = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_next_level_appriasees"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/next-level/subordinates`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_next_level_appriasees"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetDepartmentOnLeave = (
  enabled,
  setEnabled,
  page,
  from,
  to,
  department_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, error, refetch, isLoading } = useQuery(
    ["dept_on_leave", page, from, to, department_id],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/hr/view-departmental-leave?department_id=${department_id}&page=${page}&from=${from}&to=${to}`
      );
      result = result.data;

      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "dept_on_leave",
        page,
        from,
        to,
        department_id,
      ]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetAllOnLeave = (enabled, setEnabled, page, from, to) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, error, refetch, isLoading } = useQuery(
    ["all_on_leave", page, from, to],
    async () => {
      let result = await setPrivateRequest().get(
        `hrm/leave/hr/view-organizational-leave?page=${page}&from=${from}&to=${to}`
      );
      result = result.data;

      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
    }
    if (data) {
      // console.log(data);
      // dispatch({ type: "GENER", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_on_leave", page, from, to]);
      let resMessage = error?.response?.data;

      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetAllEmployees = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_all_employees"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/employees/all`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_all_employees"]);
      let resMessage = error?.response?.data;
      
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAppraisalLevel = (enabled, setEnabled, employee_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_appraisal_level", employee_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/next-level/${employee_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_appraisal_level", employee_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, employee_id]);

  return { data, refetch, isLoading };
};

const useGetPerformanceYear = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_year"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/monitoring/get-performance-year`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_year"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetIndividualScoreCard = (
  scoreCardEnabled,
  setScoreCardEnabled,
  employee_id,
  start_date,
  end_date,
  review_period_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    [
      "get_individual_score_card",
      employee_id,
      start_date,
      end_date,
      review_period_id,
    ],
    async () => {
      let result;
      let url = `/hrm/performance/monitoring/get-individual-score-card/${employee_id}?start_date=${start_date}&end_date=${end_date}`;
      if (review_period_id) {
        url += `&review_period_id=${review_period_id}`;
      }
      result = await setPrivateRequest().get(url);
      result = result.data;
      return result;
    },
    { enabled: scoreCardEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setScoreCardEnabled(false);
    }

    if (error) {
      setScoreCardEnabled(false);
      queryClient.removeQueries([
        "get_individual_score_card",
        employee_id,
        start_date,
        end_date,
        review_period_id,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setScoreCardEnabled,
    queryClient,
    employee_id,
    start_date,
    end_date,
    review_period_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetDepartmentScoreCard = (
  scoreCardEnabled,
  setScoreCardEnabled,
  department_id,
  start_date,
  end_date,
  review_period_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    [
      "get_department_score_card",
      department_id,
      start_date,
      end_date,
      review_period_id,
    ],
    async () => {
      let result;
      let url = `/hrm/performance/monitoring/get-department-score-card/${department_id}?start_date=${start_date}&end_date=${end_date}`;
      if (review_period_id) {
        url += `&review_period_id=${review_period_id}`;
      }
      result = await setPrivateRequest().get(url);
      result = result.data;
      return result;
    },
    { enabled: scoreCardEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setScoreCardEnabled(false);
    }

    if (error) {
      setScoreCardEnabled(false);
      queryClient.removeQueries([
        "get_department_score_card",
        department_id,
        start_date,
        end_date,
        review_period_id,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setScoreCardEnabled,
    queryClient,
    department_id,
    start_date,
    end_date,
    review_period_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetOrganizationScoreCard = (
  scoreCardEnabled,
  setScoreCardEnabled,
  start_date,
  end_date,
  review_period_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_organization_score_card", start_date, end_date, review_period_id],
    async () => {
      let result;
      let url = `/hrm/performance/monitoring/get-organization-score-card?start_date=${start_date}&end_date=${end_date}`;
      if (review_period_id) {
        url += `&review_period_id=${review_period_id}`;
      }
      result = await setPrivateRequest().get(url);
      result = result.data;
      return result;
    },
    { enabled: scoreCardEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setScoreCardEnabled(false);
    }

    if (error) {
      setScoreCardEnabled(false);
      queryClient.removeQueries([
        "get_organization_score_card",
        start_date,
        end_date,
        review_period_id,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setScoreCardEnabled,
    queryClient,
    start_date,
    end_date,
    review_period_id,
  ]);

  return { data, refetch, isLoading };
};

const useGetPaymentTemplate = (
  paymentTemplateEnabled,
  setPaymentTemplateEnabled,
  grade_id
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_payment_template", grade_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/payroll/create-payment-template/${grade_id}`
      );
      result = result.data;
      return result;
    },
    { enabled: paymentTemplateEnabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setPaymentTemplateEnabled(false);
    }

    if (error) {
      setPaymentTemplateEnabled(false);
      queryClient.removeQueries(["get_payment_template", grade_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    queryClient,
    grade_id,
    setPaymentTemplateEnabled,
  ]);

  return { data, refetch, isLoading };
};

const useGetUserPaymentTemplate = (enabled, setEnabled, user_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_user_payment_template", user_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/payroll/get-user-payment-template/${user_id}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_user_payment_template", user_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, user_id, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetUserBatchPaymentTemplate = (enabled, setEnabled, user_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_user_batch_payment_template", user_id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/payroll/get-batch-payment/${user_id}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_user_batch_payment_template", user_id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, user_id, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetBatchPaymentStatus = (
  enabled,
  setEnabled,
  start_date,
  end_date,
  company_id,
  status
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_batch_payment_status", start_date, end_date, company_id, status],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/payroll/get-batch-payment-status/${start_date}/${end_date}/${company_id}/${status}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "get_batch_payment_status",
        start_date,
        end_date,
        company_id,
        status,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    queryClient,
    setEnabled,
    start_date,
    end_date,
    company_id,
    status,
  ]);

  return { data, refetch, isLoading };
};

const useGetEmployeeBatchPaymentStatus = (
  enabled,
  setEnabled,
  start_date,
  end_date,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_employee_batch_payment_status", start_date, end_date],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/payroll/get-user-batch-payment/${start_date}/${end_date}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "get_employee_batch_payment_status",
        start_date,
        end_date,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    queryClient,
    setEnabled,
    start_date,
    end_date,
  ]);

  return { data, refetch, isLoading };
};

const useGetRequestTemplates = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_template"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-template`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_template"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestTemplateById = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_template_by_id", id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-template/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_template_by_id", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, id, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetEmployeeRequestHistory = (enabled, setEnabled, id, from, to) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_employee_request_history", id, from, to],
    async () => {
      let result;
      let url;
      if (from && to) {
        url = `/hrm/request-management/get-employee-request-history/${id}?from=${from}&to=${to}`;
      } else {
        url = `/hrm/request-management/get-employee-request-history/${id}`;
      }
      result = await setPrivateRequest().get(url);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_employee_request_history", id, from, to]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, id, setEnabled, to, from]);

  return { data, refetch, isLoading };
};

const useGetRequestAnswersForHistory = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_answers_for_history", id],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-request-answer/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_answers_for_history", id]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, id, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestAuthorizers = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_authorizers"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-authorizers`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_authorizers"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetAllEmployeesRequestHistory = (
  enabled,
  setEnabled,
  id,
  from,
  to
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_all_employees_request_history", id, from, to],
    async () => {
      let result;
      let url;
      if (from && to) {
        url = `/hrm/request-management/get-request-history/${id}?from=${from}&to=${to}`;
      } else {
        url = `/hrm/request-management/get-request-history/${id}`;
      }
      result = await setPrivateRequest().get(url);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "get_all_employees_request_history",
        id,
        from,
        to,
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, id, setEnabled, to, from]);

  return { data, refetch, isLoading };
};

const useGetTrackMyRequests = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_track_my_requests"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/track-my-request`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_track_my_requests"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetUserRequestTemplatesBasedOnHistory = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_user_request_templates_based_on_history"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-user-template`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries([
        "get_user_request_templates_based_on_history",
      ]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestPredefinedTemplates = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_predefined_template"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-predefined-templates`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_predefined_template"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestManagementPersonalOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_mgt_personal_overview"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-personal-overview`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_mgt_personal_overview"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestManagementSummary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_mgt_summary"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-overview-summary`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_mgt_summary"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetRequestManagementAnalytics = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_request_mgt_analytics"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/request-management/get-request-analytics`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_request_mgt_analytics"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetPerformanceManagementPersonalOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_mgt_personal_overview"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/get-personal-overview`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_mgt_personal_overview"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetPerformanceManagementSummary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_performance_mgt_summary"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/get-overview-summary`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_performance_mgt_summary"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetPerformanceMgtDepartmentAnalytics = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_perfomance_mgt_dept_analytics"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/get-departmental-analytics`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_perfomance_mgt_dept_analytics"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetPerformanceMgtOrganisationAnalytics = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_perfomance_mgt_org_analytics"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/get-organisation-analytics`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_perfomance_mgt_org_analytics"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetHRManagementSummary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_hr_mgt_summary"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/dashboard/summary`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_hr_mgt_summary"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetHRManagementAnalytics = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_hr_mgt_analytics"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/dashboard/analytics`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_hr_mgt_analytics"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetHRManagementDeptAnalytics = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_hr_mgt_dept_analytics"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/dashboard/dept-analytics`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_hr_mgt_dept_analytics"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetHRManagementSubscriptionUsage = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_subscription_usage"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/dashboard/subscription-usage`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_subscription_usage"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetCompensationPersonalOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_compensation_personal_overview"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/compensation/personal-overview`
      );
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_compensation_personal_overview"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};
const useGetCompensationSummary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_compensation_summary"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/compensation`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_compensation_summary"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetLeaveMgtPersonalOverview = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_leave_mgt_personal_overview"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/leave/personal-overview`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_leave_mgt_personal_overview"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetLeaveMgtSummary = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_leave_mgt_summary"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/leave/summary`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_leave_mgt_summary"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetEmployeesOnLeave = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_employee_on_leave"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/employees/leave-list`);
      result = result.data;
      return result;
    },
    { enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_employee_on_leave"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, queryClient, setEnabled]);

  return { data, refetch, isLoading };
};

const useGetAllEmployeesList = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_all_employees_list"],
    async () => {
      let result;
      result = await setPrivateRequest().get(`/hrm/employees/list`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_all_employees_list"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetPasswordRequirements = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["password-requirements"],
    async () => {
      let result = await setPrivateRequest().get(`get-password-requirement`);
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["password-requirements"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetUserPermissions = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_user_permissions"],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/preferences/permission/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (data) {
      const auth_details = dehashData(AUTH_DETAILS);

      const new_auth_details = {
        ...auth_details,
        abilities: data.payload,
      };

      const hash_auth_details = hashData(new_auth_details);
      storage_type.setItem(AUTH_DETAILS, hash_auth_details);
    }
    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_user_permissions"]);
      let resMessage = error?.response?.data;
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

const useGetAppraisalTracking = (enabled, setEnabled, id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_appraisal_tracking"],
    async () => {
      let result;
      result = await setPrivateRequest().get(
        `/hrm/performance/appraisal/track-appraisal/${id}`
      );
      result = result.data;
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["get_appraisal_tracking"]);
      let resMessage = error?.response?.data;
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch, isLoading };
};

export {
  useGetAppraisalTracking,
  useGetUserPermissions,
  useGetAllEmployeesList,
  useGetEmployeesOnLeave,
  useGetLeaveMgtSummary,
  useGetLeaveMgtPersonalOverview,
  useGetCompensationSummary,
  useGetCompensationPersonalOverview,
  useGetHRManagementSubscriptionUsage,
  useGetHRManagementDeptAnalytics,
  useGetHRManagementAnalytics,
  useGetHRManagementSummary,
  useGetPerformanceMgtOrganisationAnalytics,
  useGetPerformanceMgtDepartmentAnalytics,
  useGetPerformanceManagementPersonalOverview,
  useGetPerformanceManagementSummary,
  useGetRequestManagementAnalytics,
  useGetRequestManagementSummary,
  useGetRequestManagementPersonalOverview,
  useGetRequestPredefinedTemplates,
  useGetUserRequestTemplatesBasedOnHistory,
  useGetTrackMyRequests,
  useGetAllEmployeesRequestHistory,
  useGetRequestAuthorizers,
  useGetRequestAnswersForHistory,
  useGetEmployeeRequestHistory,
  useGetRequestTemplateById,
  useGetRequestTemplates,
  useGetEmployeeBatchPaymentStatus,
  useGetBatchPaymentStatus,
  useGetUserBatchPaymentTemplate,
  useGetUserPaymentTemplate,
  useGetPaymentTemplate,
  useGetOrganizationScoreCard,
  useGetDepartmentScoreCard,
  useGetIndividualScoreCard,
  useGetPerformanceYear,
  useGetAppraisalLevel,
  useGetAllEmployees,
  useGetNextLevelAppraisees,
  useGetLineManagerAppraisal,
  useGetLineManagerSubordinates,
  useGetToLeaveHis,
  useGetDeductionMasters,
  useGetTeamScoreCard,
  useGetEmployeeScoreCard,
  useGetPerformancePlans,
  useGetAppraisalsApproved,
  useGetAppraisalsPending,
  useGetAppraisalSettings,
  useGetUserAnnualPlan,
  useGetLeaveApprovalSettings,
  useGetUserAnnualPlanAny,
  useGetSystemHr,
  useGetCascade,
  useGetCascadeByTarget,
  useGetEmployeesByDepartment,
  useGetPerformanceKPITargets,
  useGetPerformanceSettingsHistory,
  useGetPerformancePlanningHistory,
  useGetPerformanceObjectiveKPIs,
  useGetPerformanceGoalObjectives,
  useGetPerformanceGoals,
  useGetPerformanceSections,
  useGetPerformanceMeasurementUnits,
  useGetPerformanceSettings,
  useGetCompanies,
  useGetBranches,
  useGetBranchDetails,
  useGetSystemSalaryStructureOthers,
  useGetPayStructure,
  useGradeWitBands,
  useGradeWithoutBands,
  useGetAllGradedGrades,
  useGetUserProfileAny,
  useMyTeamMembers,
  useGetTrackLeave,
  useGetUserLeaveGroupTeam,
  useGetUserLeaveGroup,
  useGetAllLeaveGroupDistribution,
  useGetAllLeaveGroup,
  useGetAllLeaveHistory,
  useGetMyTeamLeaveHisHR,
  useGetMyTeamLeaveHis,
  useGetTeamAnnualLeavePlanTeam,
  useGetTeamAnnualLeavePlanHr,
  useGetTeamAnnualLeavePlan,
  useGetAnnualLeavePlan,
  useGetTeamLeaveHistory,
  useGetAnnualLeaveHistory,
  useGetLeaveHistory,
  useGetLeaveGroup,
  useGetLeaveOverview,
  useGetMyReportHis,
  useGetIDCardOverview,
  useGetReportAll,
  useGetReportResolved,
  useGetReportPending,
  useGetIDCardALL,
  useGetIDCardApproved,
  useGetIDCardRejected,
  useGetIDCardPending,
  useGetAllSystemGrades,
  useGetAllSystemSteps,
  useGetSystemJobRole,
  useGetSystemSalaryStructure,
  useGetSystemDeptCategory,
  useGetSystemCurrency,
  useGetSystemStep,
  useGetSystemGrade,
  useGetOneAvailableJobGrade,
  useGetAvailableJobGrade,
  useGetCompensationsSettings,
  useGetAllBenefitsInKind,
  useGetOneBenefitsInKind,
  useGetCompensationsOverview,
  useGetPerformanceDept,
  useGetUserNugdeRequest,
  useGetGoalsBreakdown,
  useGetApprovalNumber,
  useGetAppWorkList,
  useGetPerformanceDeptEmp,
  useGetFinacialYears,
  useGetSystemEventType,
  useGetActiveModules,
  useGetDetachedModules,
  useGetCompanyDetails,
  useGetCompanyNotifications,
  useGetUserProfile,
  useGetClientAuditlogs,
  useGetPendingSlaveUpdate,
  useGetPendingSlaveUpdateDafualt,
  useGetPendingSlaveUpdatePending,
  useGetUserDocument,
  useGetUserEducationalHistory,
  useGetUserEmploymentHistory,
  useGetUserAutoID,
  useGetSlaveHistory,
  useGetSystemSettingsUtils,
  useGetUploadsToCategory,
  useGetNewHireList,
  useGetOverview,
  useGetNewHireDeleteList,
  useGetSystemSettings,
  useGetSystemDepartments,
  useGetInductionTemplate,
  useGetOnboardingTemplate,
  useGetOnboardUserProfile,
  useGetSystemBirthdays,
  useGetSystemHolidays,
  useGetSystemAnniversary,
  useGetSystemRoleDesignation,
  useGetSystemBanks,
  useGetSystemCombinedEvents,
  useGetSystemScheduledEvent,
  useGetPerformanceStat,
  useGetPerformanceSetup,
  useGetDepartmentOnLeave,
  useGetAllOnLeave,
  useGetPasswordRequirements,
};
