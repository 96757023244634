import React from "react";
import { AiOutlineTeam } from "react-icons/ai";

import { IoMdGift } from "react-icons/io";
import { MdMoney } from "react-icons/md";
import { NavLink } from "react-router-dom";

function RequestDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"request-hr"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">All Leave History</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-hr/teamannualleave_hr"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">All Annual Leave Plan</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-hr/requestforemployee_hr"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Request for Employee</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-hr/all_employee_on_leave"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Employee on Leave</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default RequestDropdown;
