import { useState } from "react";
import DashboardCard from "../../dashboard/DashboardCard";
import { Button } from "antd";
import styles from "../../../styles/employee/Profile.module.css";
import { MdEmergency } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import Warning from "../../ui/Warning";
import { useShallowEqualSelector } from "../../../util/hooks";
import { settings_util } from "../../../util/selectors/userSelectors";

function EmergencyContact() {
  const [editMode, setEditMode] = useState(false);
  const utils = useShallowEqualSelector(settings_util);

  const handleEdit = () => {
    setEditMode(!editMode);
  };
  return !editMode ? (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Emergency Contact Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Biodata"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Name</label>
            <p>{"N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <p>{"N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Email</label>
            <p>{"N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard title={"Edit Emergency Contact"} icon={<MdEmergency />}>
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Medicals" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form}>
        <div className="row">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="emergency_contact_name">Name</label>
            <input type="text" name="emergency_contact_name" />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="emergency_contact_mobile">Mobile Number</label>
            <input type="number" name="emergency_contact_mobile" />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="emergency_contact_email">Email</label>
            <input type="email" name="emergency_contact_email" />
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger"
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}

export default EmergencyContact;
