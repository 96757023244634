import React, { useState } from "react";
import styles from "../../../styles/hrms/Approval.module.css";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useGetPendingSlaveUpdate } from "../../../util/usershookActions";

import {
  user_profile_pending_request,
  user_spinner,
} from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import { ThreeDots } from "react-loader-spinner";
import NoContent from "../../ui/NoContent";
import HocHeader from "../../ui/HocHeader";

function HRMApproval() {
  const [enabled, setEnabled] = useState(true);

  useGetPendingSlaveUpdate(enabled, setEnabled);

  const user_pending = useShallowEqualSelector(user_profile_pending_request);
  const userSpinner = useShallowEqualSelector(user_spinner);

  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Approval Management"
          module_title="Human Resource Information System"
          module_description="Manage the requests of employees for modifications to their data."
          // module_img="/assets/benefits.png"
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <div className="container-fluid py-3">
            <div className="app__section py-4">
              <h3 className="h4 mb-4">Pending Approvals</h3>
              {userSpinner ? (
                <div className=" d-flex justify-content-center align-items-center ">
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color={"var(--clr-primary)"}
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : user_pending.length ? (
                <div className="row px-3 d-flex gap-3 flex-wrap">
                  {user_pending.map((user) => (
                    <div className={`${styles.card} col-md-3`} key={user.id}>
                      <h4>
                        {user?.first_name} {user?.last_name}
                      </h4>
                      <Button>
                        <Link
                          to={`/modules/hris/approval-management/${user.e_id}/${
                            user.first_name
                          }/${user.last_name || "Admin"}/default`}
                        >
                          View
                        </Link>
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <NoContent
                  illustration={"/assets/3891942.jpg"}
                  message={"No Pending Approvals"}
                  width={400}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HRMApproval;
