import React, { useState, useEffect } from "react";
import styles from "../../styles/HomeLayout.module.css";
import navStyles from "../../styles/Navbar.module.css";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useShallowEqualSelector } from "../../util/hooks";
import { MdMenu, MdOutlinePowerSettingsNew } from "react-icons/md";
import { user_profile } from "../../util/selectors/userSelectors";
import { useDispatch } from "react-redux";
import { logoutClient } from "../../redux/users/userActions";
import { Modal, Dropdown } from "antd";

const HomeLayoutTopbar = () => {
  const profile = useShallowEqualSelector(user_profile);

  const { personal } = profile;

  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const [pathName, setPathName] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    if (path) {
      setPathName(path);
    } else {
      setPathName("");
    }
  }, [location]);

  // leave drop down
  const leaveItems = [
    {
      key: "1",
      label: (
        <div className={navStyles.dropleave}>
          <section className={navStyles.leftDropLeave}>
            <img src="/assets/leaveimage.png" alt=""></img>
          </section>
          <section className={navStyles.middleDropLeave}>
            <article>
              <h4>Actions</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/leave/leave-actions"}
                    className="leave__dropdown"
                  >
                    New leave request
                  </NavLink>
                </li>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/leave/leave-actions/annualLeave"}
                    className="leave__dropdown"
                  >
                    Set annual leave plan
                  </NavLink>
                </li>
              </ul>
            </article>
            <article>
              <h4>History</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/leave/leave-actions/leaveHistory"}
                    className="leave__dropdown"
                  >
                    My leave history
                  </NavLink>
                </li>

                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/leave/leave-actions/teamAnnual"}
                    className="leave__dropdown"
                  >
                    Team leave history
                  </NavLink>
                </li>
              </ul>
            </article>
          </section>
          <section className={navStyles.rightDropLeave}>
            <article>
              <h4>Plans</h4>
              <ul className={navStyles.actionLists}>
                {/* <li className={navStyles.actionLists}>Set team leave plan</li> */}
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/leave/leave-actions/teamleaveplan"}
                    className="leave__dropdown"
                  >
                    Team leave plan
                  </NavLink>
                </li>
              </ul>
            </article>
            <article className={navStyles.faq}>FAQ</article>
          </section>
        </div>
      ),
    },
  ];

  // Loan drop down
  const requestItems = [
    {
      key: "1",
      label: (
        <div className={navStyles.dropleave}>
          <section className={navStyles.leftDropLeave}>
            <img src="/assets/Billlingsmanagement.png"></img>
          </section>
          <section className={navStyles.middleDropLeave}>
            <article>
              <h4>Actions</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/request/make-request"}
                    className="leave__dropdown"
                  >
                    Make a request
                  </NavLink>
                </li>
              </ul>
            </article>
            <article>
              <h4>History</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/request/make-request/request-history"}
                    className="leave__dropdown"
                  >
                    My Request history
                  </NavLink>
                </li>
              </ul>
            </article>
          </section>
          <section className={navStyles.rightDropLeave}>
            <article></article>
            <article className={navStyles.faq}>FAQ</article>
          </section>
        </div>
      ),
    },
  ];

  // Appraisal drop down
  const appraisalItems = [
    {
      key: "1",
      label: (
        <div className={navStyles.dropleave}>
          <section className={navStyles.leftDropLeave}>
            <img src="/assets/appraisal.png"></img>
          </section>
          <section className={navStyles.middleDropLeave}>
            <article>
              <h4>Actions</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/performance/appraisals/begin-appraisal"}
                    className="leave__dropdown"
                  >
                    Begin Appraisal
                  </NavLink>
                </li>
              </ul>
            </article>
            <article>
              <h4>History</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/performance/monitoring"}
                    className="leave__dropdown"
                  >
                    View Your Appraisal Score Card
                  </NavLink>
                </li>
              </ul>
            </article>
          </section>
          <section className={navStyles.rightDropLeave}>
            <article>
              <h4>Management</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/performance/appraisals/manage-team-appraisal"}
                    className="leave__dropdown"
                  >
                    Manage appraisal deliverables
                  </NavLink>
                </li>
              </ul>
            </article>
            <article className={navStyles.faq}>FAQ</article>
          </section>
        </div>
      ),
    },
  ];

  //payroll drop down
  const payrollItems = [
    {
      key: "1",
      label: (
        <div className={navStyles.dropleave}>
          <section className={navStyles.leftDropLeave}>
            <img src="/assets/payroll.png" alt=""></img>
          </section>
          <section className={navStyles.middleDropLeave}>
            <article>
              <h4>Actions</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"/modules/compensation/pay-roll/employee-payslip"}
                    className="leave__dropdown"
                  >
                    Pay slip request
                  </NavLink>
                </li>
              </ul>
            </article>
            {/* <article>
              <h4>History</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>Salary history</li>
              </ul>
            </article> */}
          </section>
          <section className={navStyles.rightDropLeave}>
            <article>{""}</article>
            <article className={navStyles.faq}>FAQ</article>
          </section>
        </div>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    logoutClient(dispatch);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const modalTrigger = () => {
    Modal.confirm({
      title: `Logout?`,
      content: `Are you sure you want to logout?`,
      onOk: logoutHandler,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  // profile dropdown menu
  const profiledropdown = [
    {
      key: "1",
      label: (
        <div className={styles.profileDropdown}>
          <div className={styles.navLinkGroup}>
            <MdOutlinePowerSettingsNew size={30} />
            <span onClick={modalTrigger} className={styles.linktext}>
              Logout
            </span>
          </div>
        </div>
      ),
    },
  ];

  // const isActiveClass = styles.selected

  return (
    <div className={styles.topbar}>
      <div className={styles.navWrapper}>
        <MdMenu size={32} />
        <ul className={navStyles.navlinks}>
          <li>
            <Dropdown menu={{ items: payrollItems }}>
              <NavLink
                to={"#"}
                className={pathName === "payroll" ? styles.selected : null}
              >
                Payroll
              </NavLink>
            </Dropdown>
          </li>

          <li>
            <Dropdown menu={{ items: leaveItems }} className="dropdown__action">
              <NavLink
                to={"#"}
                className={pathName === "leave" ? styles.selected : null}
              >
                Leave
              </NavLink>
            </Dropdown>
          </li>

          <li>
            <Dropdown menu={{ items: requestItems }}>
              <NavLink
                to={"#"}
                className={pathName === "request" ? styles.selected : null}
              >
                Requests
              </NavLink>
            </Dropdown>
          </li>
          <li>
            <Dropdown menu={{ items: appraisalItems }}>
              <NavLink
                to={"#"}
                className={pathName === "appraisal" ? styles.selected : null}
              >
                Appraisal
              </NavLink>
            </Dropdown>
          </li>
        </ul>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <div
          className={styles.navLinkGroup}
          style={{
            cursor: "pointer",
          }}
        >
          <MdOutlinePowerSettingsNew
            size={30}
            onClick={modalTrigger}
            color="inherit"
          />
        </div>
        <div className={styles.topbarAvatar}>
          <div onClick={navigateToProfile}>
            {personal?.avatar && (
              <img src={personal?.avatar} alt={personal?.fullname} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayoutTopbar;
