import React, { useState, useEffect } from "react";
import { useLocation, Outlet, NavLink } from "react-router-dom";
import styles from "../../../../styles/hrms/Approval.module.css";
import { useGetSlaveHistory } from "../../../../util/usershookActions";
import NoContent from "../../../ui/NoContent";

function History({ emp_id }) {
  const active = styles.active;
  const [details, setDetails] = useState();
  const [enabled, setEnabled] = useState(false);

  const { data } = useGetSlaveHistory(enabled, setEnabled, emp_id);

  const location = useLocation();

  const segments = location.pathname.split("/");
  const activePath = segments[segments.length - 1];
  useEffect(() => {
    if (emp_id) {
      setEnabled(true);
    }
  }, [emp_id]);

  const med_details = data?.payload[0].medical;
  const contact_details = data?.payload[0].contact;
  const dependant_details = data?.payload[0].dependant;
  const next_of_kin = data?.payload[0].next_of_kin;
  const personal_details = data?.payload[0].personal;
  const identity = data?.payload[0].identity;
  const edu_history = data?.payload[0].educational;
  const emp_history = data?.payload[0].employment;
  const document_details = data?.payload[0].document;

  useEffect(() => {
    if (data) {
      switch (activePath) {
        case "medical":
          setDetails(med_details);
          break;
        // case "financials":
        //   setDetails(financial_details);
        //   break;
        case "nok":
          setDetails(next_of_kin);
          break;
        case "identity":
          setDetails(identity);
          break;
        case "edu_history":
          setDetails(edu_history);
          break;
        case "employment_history":
          setDetails(emp_history);
          break;
        case "document":
          setDetails(document_details);
          break;
        case "dependant":
          setDetails(dependant_details);
          break;
        case "contact":
          setDetails(contact_details);
          break;
        default:
          setDetails(personal_details);
          break;
      }
    }
  }, [
    activePath,
    contact_details,
    data,
    dependant_details,
    document_details,
    edu_history,
    emp_history,
    identity,
    med_details,
    next_of_kin,
    personal_details,
  ]);


  return (
    <div className={styles.content}>
      <ul className={styles.navigation}>
        <li>
          <NavLink
            to={""}
            end
            className={({ isActive }) => (isActive ? active : null)}
          >
            Personal
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"medical"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Medical
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"contact"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            {" "}
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"dependant"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Dependants
          </NavLink>
        </li>

        <li>
          <NavLink
            to={"nok"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Next of kin
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"identity"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Identity
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"edu_history"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Educational
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"employment_history"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Employment
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"document"}
            className={({ isActive }) => (isActive ? active : null)}
          >
            Documents
          </NavLink>
        </li>
      </ul>
      <div className={styles.details}>
        <div className="container">
          <div className="container">
            {details && details.length > 0 ? (
              <Outlet context={[details]} />
            ) : (
              <NoContent message="No Pending Approval" width={400} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
