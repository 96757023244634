import Sidebar from "./Sidebar";
import styles from "../../../styles/employee/Profile.module.css";
import { Outlet } from "react-router-dom";
import { useUsersNotification, useUserCleanUp } from "../../../util/hooks";

function ProfilePage() {
  useUsersNotification();
  useUserCleanUp();

  return (
    <div className={styles.profile}>
      <Sidebar />
      <div className={`${styles.content} bg-white`}>
        <Outlet />
      </div>
    </div>
  );
}

export default ProfilePage;
