import React, { useState } from "react";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";
import { Doughnut, Bar } from "react-chartjs-2";

import HocHeader from "../ui/HocHeader";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";

import {
  useGetRequestManagementPersonalOverview,
  useGetRequestManagementSummary,
  useGetRequestManagementAnalytics,
} from "../../util/usershookActions";

import { colorArray } from "../../util/helpers";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CanAccess from "../hoc/CanAccess";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function RequestMgtOverview() {
  useUserCleanUp();
  // Chart.register(...registerables);
  const [enabled, setEnabled] = useState(true);

  const { data: personal_overview, isLoading: isLoadingPersonalOverview } =
    useGetRequestManagementPersonalOverview(enabled, setEnabled);

  const { data: summary, isLoading: isLoadingSummary } =
    useGetRequestManagementSummary(enabled, setEnabled);

  const { data: analytics, isLoading: isLoadingAnalytics } =
    useGetRequestManagementAnalytics(enabled, setEnabled);

  const cardBgColors = [
    "#D7FFEC94",
    "#CD602330",
    "#62D9FE38",
    "#21231E14",
    "#FE626238",
    "#D7E2FF",
    "#E8F5FF",
    "#FFECEC",
  ];

  const data = {
    labels: [
      `Approved Requests: ${analytics?.payload?.request_status_analytics?.approved_request}`,
      `Declined Requests: ${analytics?.payload?.request_status_analytics?.declined_request}`,
    ],
    datasets: [
      {
        label: "No. of Requests",
        data: [
          analytics?.payload?.request_status_analytics?.approved_request,
          analytics?.payload?.request_status_analytics?.declined_request,
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  const dataBar = {
    labels:
      analytics?.payload?.request_by_departments?.map(
        (request) => request.template_name
      ) || [],
    datasets:
      analytics?.payload?.departments?.map((department, index) => {
        return {
          label: department,
          data: analytics?.payload?.request_by_departments?.map(
            (request) => request?.request_count[department] || 0
          ),
          borderColor: colorArray[index],
          backgroundColor: colorArray[index],
          borderWidth: 2,
          borderRadius: 10,
          borderSkipped: false,
        };
      }) || [],
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_name="Request Management"
        module_description="Employees can make facilities work order requests and maintenance requests to the facilities management team, or requests to the IT service management team."
        module_img="/assets/request-management.png"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4 mb-4">
            <h5>Personal Overview</h5>

            {isLoadingPersonalOverview ? (
              <SkeletonUI />
            ) : (
              <div className="row mt-4">
                {Object.keys(personal_overview?.payload).map((key, index) => {
                  const randomIndex = Math.floor(
                    Math.random() * cardBgColors.length
                  );
                  const randomColor = cardBgColors[randomIndex];
                  return (
                    <div className="col-md-3 mb-4" key={index}>
                      <div
                        className="insight"
                        style={{ backgroundColor: randomColor }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiPulseLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h6 className="text-capitalize">
                                {key.split("_").join(" ")}s
                              </h6>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                                {personal_overview?.payload[key]}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </section>

          <CanAccess
            isRoute={false}
            can_access={"view-request-management-request-overview"}
          >
            <section className="app__section py-4 mb-4">
              <h5>Summary</h5>

              {isLoadingSummary ? (
                <SkeletonUI />
              ) : (
                <div className="row mt-4">
                  {summary?.payload &&
                    Object.keys(summary?.payload).map((key, index) => {
                      const randomIndex = Math.floor(
                        Math.random() * cardBgColors.length
                      );
                      const randomColor = cardBgColors[randomIndex];

                      return (
                        <div className="col-md-3 mb-4" key={index}>
                          <div
                            className="insight"
                            style={{ backgroundColor: randomColor }}
                          >
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <RiPulseLine />
                              </div>
                              <div>
                                <div className="insight-title">
                                  <h6 className="text-capitalize">
                                    {key.split("_").join(" ")}s
                                  </h6>
                                </div>
                                <div className="insight-content">
                                  <h2 className="mb-0">
                                    {summary?.payload[key]}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </section>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Request Status Analysis</h5>
                  {isLoadingAnalytics ? (
                    <SkeletonUI />
                  ) : analytics?.payload?.request_status_analytics
                      ?.approved_request > 0 ||
                    analytics?.payload?.request_status_analytics
                      ?.declined_request > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={data} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    Requests By Departments and type
                  </h5>
                  {isLoadingAnalytics ? (
                    <SkeletonUI />
                  ) : analytics?.payload?.request_by_departments ? (
                    <Bar options={optionsBar} data={dataBar} />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
        </div>
      </main>
    </>
  );
}

export default RequestMgtOverview;
