import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";

import { user_errors } from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { Button, Modal, Select } from "antd";

import { useDispatch } from "react-redux";
import { useGetAllEmployee } from "../../../../util/preferenceshookActions";
import { all_groups } from "../../../../util/selectors/preferenceSelectors";
import { useGetSystemHr } from "../../../../util/usershookActions";
import { setSystemHr } from "../../../../redux/users/userActions";

const SystemHr = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [enabledhr, setEnabledhr] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [values, setValues] = useState({
    system_hr: "",
  });

  const back_errors = useShallowEqualSelector(user_errors);
  useGetAllEmployee(enabled, setEnabled, "all"); //need for employee
  const { data: sys_hr, refetch } = useGetSystemHr(enabledhr, setEnabledhr);

  const all_users = useShallowEqualSelector(all_groups); // need

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  function handleSelect(value, name) {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  useEffect(() => {
    if (
      sys_hr?.payload?.system_hr_id &&
      Object.keys(sys_hr?.payload?.system_hr_id).length
    ) {
      setValues((prevState) => {
        return {
          ...prevState,
          ["system_hr"]: sys_hr?.payload?.system_hr_id.id,
        };
      });
    }
  }, [sys_hr?.payload?.system_hr_id?.id]);

  function handleSubmit(e) {
    e.preventDefault();

    const creds = {
      name: "System HR",
      system_hr_id: values.system_hr,
    };
    setLoading(true);

    setSystemHr(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setShowModal(false);
      }
    });
  }

  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>System Hr</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <small>
                Add, view and edit your company's human resource personnel{" "}
              </small>
            </div>
            <div className="col-md-5 gap-1 d-flex align-items-center justify-content-end flex-column">
              <h5>
                {" "}
                {sys_hr && sys_hr.payload.system_hr_id
                  ? `${sys_hr.payload?.system_hr_id?.first_name} ${sys_hr.payload?.system_hr_id?.last_name} ${sys_hr.payload?.system_hr_id?.other_names}`
                  : "Not Set"}
              </h5>
              <Button type="text" className="w-50" onClick={handleModal}>
                Edit HR
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={showModal}
        footer={null}
        centered
        onCancel={handleModal}
        width={700}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3> Edit Hr personnel</h3>

            <form className="onboarding-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="name">Name</label>
                      <div className="w-100 text-right text-danger d-flex justify-content-end">
                        <small>{back_errors.name && back_errors.name[0]}</small>
                      </div>
                    </div>

                    <Select
                      name="system_hr"
                      id="system_hr"
                      value={values.system_hr}
                      size="large"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={(value) => handleSelect(value, "system_hr")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {users.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-2">
                <div>
                  <Button className="myBtn mt-3 mx-2" onClick={() => {}}>
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    className="myBtn  mt-3"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SystemHr;
