import { useEffect, useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { Button } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  sys_banks,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import {
  updateUserFinancialsinfo,
  approveUserMedicalInfo,
} from "../../../redux/users/userActions";
import DashboardCard from "../../dashboard/DashboardCard";
import { FaMoneyBillAlt, FaRegEdit } from "react-icons/fa";
import Warning from "../../ui/Warning";
// import { system_banks } from "../../../util/selectors/preferenceSelectors";
// import { useGetSystemBanks } from "../../../util/preferenceshookActions";
import { useGetSystemBanks } from "../../../util/usershookActions";

export default function FinancialDet({ init_det }) {
  useUserCleanUp();
  let init_details;

  const dispatch = useDispatch();
  const [enabled_banks, setEnabledBanks] = useState(true);
  useGetSystemBanks(enabled_banks, setEnabledBanks);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const systemBanks = useShallowEqualSelector(sys_banks);
  const utils = useShallowEqualSelector(settings_util);

  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [ban_nam, setBanNam] = useState("");
  console.log({ userProfile });
  if (userProfile?.financials && Object.keys(userProfile?.financials).length) {
    const { id, ...rest } = userProfile.financials;
    init_details = rest;
  } else {
    init_details = {
      bank_id: "",
      account_number: "",
      tax_id: "",
    };
  }

  const callbackFn = () => {
    if (init_det) {
      approveUserMedicalInfo(dispatch, values).then((res) => {
        if (res?.status === "success") {
          // clearForm();
          setEditMode(!editMode);
        }
      });
    } else {
      setSaveSpinner(true);
      values.user_id = userProfile?.id;

      updateUserFinancialsinfo(dispatch, {
        ...values,
      }).then((res) => {
        setSaveSpinner(false);
        if (res?.status === "success") {
          clearForm();
          setEditMode(!editMode);
        }
      });
    }
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (
      userProfile?.financials &&
      Object.keys(userProfile?.financials).length
    ) {
      const bank = systemBanks?.find(
        (bank) => bank.id === userProfile?.financials.bank_id
      );
      setBanNam(bank?.name);
    }
  }, [userProfile?.financials, systemBanks]);
  return !editMode ? (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Financial Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Biodata"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Salary Bank</label>
            <p>{(userProfile?.financials?.bank_id && ban_nam) || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Account Number</label>
            <p>{userProfile?.financials?.account_number || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Tax ID</label>
            <p>{userProfile?.financials?.tax_id || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard
      title={"Edit Financial Information"}
      icon={<FaMoneyBillAlt />}
    >
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Financial" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4">
            <label htmlFor="bank_id"> Salary Bank</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.bank_id ||
                  (back_errors.bank_id && back_errors.bank_id[0])}
              </small>
            </div>
            <select
              name="bank_id"
              onChange={handleChange}
              value={values.bank_id}
              id="bank_id"
            >
              <option value="">--</option>
              {systemBanks?.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.name.toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="col-4">
            <label htmlFor="account_number">Account number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.account_number ||
                  (back_errors.account_number && back_errors.account_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="account_number"
              onChange={handleChange}
              value={values.account_number}
              id="account_number"
            />
          </div>
          <div className="col-4">
            <label htmlFor="tax_id">Tax ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.tax_id || (back_errors.tax_id && back_errors.tax_id[0])}
              </small>
            </div>
            <input
              type="text"
              name="tax_id"
              onChange={handleChange}
              value={values.tax_id}
              id="tax_id"
            />
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger"
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn " loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}
