import React, { useEffect, useState, useRef } from "react";

import { useGetAllSystemGrades } from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { useUserCleanUp } from "../../../util/hooks";

import { formatMoney } from "../../../util/helpers";
import { Input, Space, Table, Button, Card, Drawer, Popconfirm } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import WageItemTable from "./WageItemTable";
import EditSalaryComponent from "./EditSalaryComponent";
import { useDispatch } from "react-redux";
import { deleteWageItem } from "../../../redux/users/userActions";

function SalaryComponent({ view }) {
  useUserCleanUp();
  const is_open = useRef(false);
  const [enabled, setEnabled] = useState(true);
  const [selected, setSelected] = useState(null);
  const [update, setUpdate] = useState(false);

  const [table_data, setTableData] = useState([]);
  const [data_len, setDatalen] = useState(0);
  const {
    data: sys_grade,
    isLoading,
    refetch,
  } = useGetAllSystemGrades(enabled, setEnabled);

  console.log({ sys_grade });

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  function handleSelected(record, update = false) {
    setSelected(record);
    setUpdate(update);
    if (!view && !is_open.current) {
      is_open.current = true;
    }
    document.querySelector(".ant-drawer-body")?.scrollTo(0, 0);
  }

  function closeDrawer() {
    setSelected(null);
    if (!view && is_open.current) {
      is_open.current = false;
    }
  }

  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    Table.EXPAND_COLUMN,
    {
      title: " Grade",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Pay grade",
      dataIndex: "pay_grade_count",
      key: "pay_grade_count",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Wage Item settings",
      dataIndex: "wage_items",
      key: "wage_items",
      ...getColumnSearchProps("wage_items"),
      render: (value) => {
        return value === "Not added" ? (
          <span className="badge bg-primary p-2">{value}</span>
        ) : (
          <span className="badge bg-success p-2">{value}</span>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Action (Wage Items)",
      dataIndex: "code",
      key: "code",
      render: (_, record) => {
        return (
          <EachRecord
            record={record}
            refetch={refetch}
            handleSelected={handleSelected}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (sys_grade) {
      if (sys_grade?.payload?.length) {
        const new_arry = sys_grade.payload.map((data) => {
          return {
            ...data,
            key: data.id,
            pay_grade_count: data.gradings.length ? data.gradings.length : "0",
            wage_items: data?.has_wage_items ? "Added" : "Not added",
          };
        });

        setTableData(new_arry);
        setDatalen(new_arry.length);
      } else {
      }
    }
  }, [sys_grade]);

  function handleTable(
    pagination,
    filters,
    sorter,
    { currentDataSource, action }
  ) {
    if (action === "filter") {
      setDatalen(currentDataSource?.length);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 min-vh-100">
            <section className="app__section py-4">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  padding: "1rem",
                }}
              >
                <div>
                  <h5 className="mb-0">All wage item components</h5>
                  <small className=" d-none d-sm-block">
                    All statutory components of the salary structure for the
                    organization.
                  </small>
                </div>
              </div>

              <div className="d-flex gap-3 align-items-center mb-4">
                <div className="circle__bagde fw-bold">{data_len}</div>
                <h6 className="mb-0">Grades</h6>
              </div>

              {isLoading ? (
                <SkeletonUI number={2} />
              ) : table_data.length ? (
                <>
                  <div
                    className="row mt-5"
                    // style={{
                    //   padding: "1rem",
                    // }}
                  >
                    <Table
                      columns={columns}
                      dataSource={table_data}
                      bordered
                      scroll={{ x: "768px" }}
                      pagination={table_data.length > 10 ? true : false}
                      expandable={{
                        expandedRowRender: (record) => (
                          <Card
                            title={`${record.name} details`}
                            bordered={false}
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Step</th>
                                  <th scope="col"> Gross salary (&#x20A6;)</th>
                                  <th scope="col">Benefits</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.gradings.map((item, ind) => (
                                  <>
                                    <tr>
                                      <th scope="row" key={item.id}>
                                        {ind + 1}
                                      </th>
                                      <td>{item?.step?.name || "N/A"}</td>
                                      <td>
                                        {item?.salary
                                          ? formatMoney(item?.salary)
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <Space wrap>
                                          {item?.benefits?.length
                                            ? item.benefits.map((itm) => (
                                                <>
                                                  <div className="badge bg-primary p-1">
                                                    <span>{itm.benefit}</span>{" "}
                                                    -- <span>{itm.value}</span>{" "}
                                                  </div>
                                                </>
                                              ))
                                            : "N/A"}
                                        </Space>
                                      </td>
                                      {/* <td>
                                        <EachTableAction
                                          item={item}
                                          ben_data={ben_data}
                                          stp_data={stp_data}
                                          refetch={refetch}
                                        />
                                      </td> */}
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </Card>
                        ),
                        rowExpandable: (record) => record.gradings.length,
                      }}
                      onChange={handleTable}
                    />
                  </div>

                  {selected && is_open ? (
                    <Drawer
                      title={selected.name}
                      onClose={closeDrawer}
                      open={is_open}
                      extra={
                        <Space>
                          <Button danger size="large" onClick={closeDrawer}>
                            Close
                          </Button>
                        </Space>
                      }
                      destroyOnClose={true}
                      width={"90%"}
                      className="salary_comp"
                    >
                      <div>
                        <EditSalaryComponent
                          selected_grade={selected}
                          refetch={refetch}
                          update={update}
                        />
                        <hr />
                        <WageItemTable
                          handleSelected={handleSelected}
                          refetch={refetch}
                        />
                      </div>
                    </Drawer>
                  ) : null}
                </>
              ) : (
                <NoContent
                  message={"No salary structure yet"}
                  width={"400px"}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

function EachRecord({ record, refetch, handleSelected }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  function confirm() {
    console.log({ record });
    const creds = {
      id: record.id,
    };
    setLoading(true);
    deleteWageItem(dispatch, creds).then((res) => {
      setLoading(false);

      if (res?.status === "success") {
        refetch();
      }
    });
  }
  return (
    <Space wrap>
      {!record.has_wage_items ? (
        <Button
          onClick={() => handleSelected(record)}
          className="rounded p-1 px-2 border border-1 text-white me-1 bg-success"
        >
          Set up
        </Button>
      ) : (
        <>
          <Button
            onClick={() => handleSelected(record, true)}
            className="rounded p-1 px-2 border border-1 text-white me-1 bg-warning"
          >
            Edit{" "}
          </Button>

          <Popconfirm
            title="Delete wage item settings"
            description="Are you sure want to perform this action?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="rounded p-1 px-2 border border-1 text-white me-1 bg-danger"
              loading={loading}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      )}
    </Space>
  );
}

export default SalaryComponent;
