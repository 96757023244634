import React from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";

function Contact({ handleChange, values, errors, back_errors, onboarding }) {
  const err_text = "contact.0.";
  return (
    <div className={onboarding ? "col-md-12 mt-5" : "col-md-6"}>
      <div className="card b0">
        <div className="card-body py-4">
          <h4 className={styles.subheading4}>Contact Information</h4>
          <div className={styles.hr}></div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="mobile_number" className="d-block">
                    Mobile Number
                  </label>
                  <small className="text-danger">
                    {errors.mobile_number ||
                      (back_errors[`${err_text}mobile_number`] &&
                        back_errors[`${err_text}mobile_number`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="number"
                  name="mobile_number"
                  onChange={handleChange}
                  value={values.mobile_number}
                  id="mobile_number"
                  placeholder="Mobile Number"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="telephone" className="d-block">
                    Phone Number
                  </label>
                  <small className="text-danger">
                    {errors.telephone ||
                      (back_errors[`${err_text}telephone`] &&
                        back_errors[`${err_text}telephone`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <input
                  type="number"
                  name="telephone"
                  onChange={handleChange}
                  value={values.telephone}
                  id="telephone"
                  placeholder="Telephone Number"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="primary_email" className="d-block">
                    Primary Email <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.primary_email ||
                      (back_errors[`${err_text}primary_email`] &&
                        back_errors[`${err_text}primary_email`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="email"
                  name="primary_email"
                  onChange={handleChange}
                  value={values.primary_email}
                  id="primary_email"
                  placeholder="Primary Email"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="secondary_email" className="d-block">
                    Secondary Email
                  </label>
                  <small className="text-danger">
                    {errors.secondary_email ||
                      (back_errors[`${err_text}secondary_email`] &&
                        back_errors[`${err_text}secondary_email`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="email"
                  name="secondary_email"
                  onChange={handleChange}
                  value={values.secondary_email}
                  id="secondary_email"
                  placeholder="Secondary Email"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="home_address" className="d-block">
                    Home Address <span className="text-danger">*</span>
                  </label>

                  <small className="text-danger">
                    {errors.home_address ||
                      (back_errors[`${err_text}home_address`] &&
                        back_errors[`${err_text}home_address`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>

                  <small className="text-danger">
                    {errors.home_address ||
                      (back_errors.home_address && back_errors.home_address[0])}
                  </small>
                </div>

                <textarea
                  name="home_address"
                  rows={5}
                  onChange={handleChange}
                  id="home_address"
                  value={values.home_address}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
