import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";

import {
  // user_spinner,
  user_errors,
  sys_banks,
} from "../../../../util/selectors/userSelectors";
import { system_banks } from "../../../../util/selectors/preferenceSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserFinancialsInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import { useGetSystemBanks } from "../../../../util/preferenceshookActions";
import { useOutletContext } from "react-router-dom";

const BANKS = require("../../../../util/nigerian-banks.json");

const STATES = require("../../../../util/nigeria-state-and-lgas.json");

export default function ApproveFinancials() {
  const [enabled_banks, setEnabledBanks] = useState(true);
  useGetSystemBanks(enabled_banks, setEnabledBanks);
  // useGetSystemBanks

  const systemBanks = useShallowEqualSelector(sys_banks);

  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();
  // const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);
  const [details, setEnabled] = useOutletContext();

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserFinancialsInfo(dispatch, {
        ...values,
        id: details[0]?.id,
        notify: false,
        expect: exempt,
      }).then((res) => {
        setLoadingAppN(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  }

  function handleApproveNotify() {
    setLoadingApp(true);
    approveUserFinancialsInfo(dispatch, {
      ...values,
      id: details[0]?.id,
    }).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  }

  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  if (details[0]) {
    init_details = {
      bank_id: details[0].bank_id,
      account_number: details[0].account_number,
      tax_id: details[0].tax_id,
      pfa_custodian: details[0].pfa_custodian,
      pfa_id: details[0].pfa_id,
      tax_state: details[0].tax_state,
      housing_fund_id: details[0].housing_fund_id,
      medical_number: details[0].medical_number,
      except: [],
      notify: true,
    };
  }

  const callbackFn = () => {
    if (details[0]) {
      approveandnotify();
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );


  const modalHandler = () => {
    setShowModal(!showModal);
  };
  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };

  const confirm = (id) => {
    setLoading(true);
    rejectUserInfo(dispatch, {
      ...values,
      id: details[0]?.id,
      info_type: "financials",
    }).then((res) => {
      setLoading(false);
      Modal.destroyAll();
      setShowModal(false);

      if (res.status === "success") {
        setEnabled(true);
      }
    });
  };

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    // alert("here");
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <DashboardCard
      title={"Financial Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="bank_id"> Salary Bank</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.bank_id ||
                  (back_errors.bank_id && back_errors.bank_id[0])}
              </small>
            </div>
            <select
              name="bank_id"
              onChange={handleChange}
              value={values.bank_id}
              id="bank_id"
            >
              <option value="">--</option>
              {systemBanks?.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.name.toUpperCase()}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="bank_id"
              onChange={handleCheckBox}
            />
          </div>

          <div className="col-4  approval_side_checkbox">
            <label htmlFor="account_number">Account Number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.account_number ||
                  (back_errors.account_number && back_errors.account_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="account_number"
              onChange={handleChange}
              value={values.account_number}
              id="account_number"
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="account_number"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="tax_id">Tax ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.tax_id || (back_errors.tax_id && back_errors.tax_id[0])}
              </small>
            </div>
            <input
              type="text"
              name="tax_id"
              onChange={handleChange}
              value={values.tax_id}
              id="tax_id"
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="tax_id"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="pfa_custodian"> PFA Custodian</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.pfa_custodian ||
                  (back_errors.pfa_custodian && back_errors.pfa_custodian[0])}
              </small>
            </div>
            <select
              name="pfa_custodian"
              onChange={handleChange}
              value={values.pfa_custodian}
              id="pfa_custodian"
            >
              <option value="">--</option>
              {BANKS.map((bank) => (
                <option key={bank.id} value={bank.name}>
                  {bank.name.toUpperCase()}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="pfa_custodian"
              onChange={handleCheckBox}
            />
          </div>

          <div className="col-4  approval_side_checkbox">
            <label htmlFor="pfa_id">PFA ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.pfa_id || (back_errors.pfa_id && back_errors.pfa_id[0])}
              </small>
            </div>
            <input
              type="number"
              name="pfa_id"
              onChange={handleChange}
              value={values.pfa_id}
              id="pfa_id"
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="pfa_id"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="tax_state"> Tax State</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.tax_state ||
                  (back_errors.tax_state && back_errors.tax_state[0])}
              </small>
            </div>
            <select
              name="tax_state"
              onChange={handleChange}
              value={values.tax_state}
              id="tax_state"
            >
              <option value="">--</option>
              {STATES.map((state, index) => (
                <option key={index} value={state.state}>
                  {state.state.toUpperCase()}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="tax_state"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="housing_fund_id">House Fund ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.housing_fund_id ||
                  (back_errors.housing_fund_id &&
                    back_errors.housing_fund_id[0])}
              </small>
            </div>
            <input
              type="text"
              name="housing_fund_id"
              onChange={handleChange}
              value={values.housing_fund_id}
              id="housing_fund_id"
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="housing_fund_id"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-4  approval_side_checkbox">
            <label htmlFor="medical_number">Medical number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.medical_number ||
                  (back_errors.medical_number && back_errors.medical_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="medical_number"
              onChange={handleChange}
              value={values.medical_number}
              id="medical_number"
            />

            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="medical_number"
              onChange={handleCheckBox}
            />
          </div>
        </div>

        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn "
            loading={loadingApp}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>
          <Button
            size="large"
            className="myBtn "
            loading={loadingAppN}
            onClick={approve}
            disabled={disabled}
          >
            Approve
          </Button>

          <Button
            size="large"
            className="myBtn"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={warning}
            loading={loading}
          >
            Reject
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}
