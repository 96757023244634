import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import { Input, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PlanningSection from "../../settings/PlanningSection";

const GoalForm = ({
  goalNumber,
  handleAddForm,
  handleRemoveForm,
  id,
  goal,
  section,
  weight,
  description,
  availableWeight,
  onChange,
  onWeightChange,
  exceedsMax,
  sectionData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (name, value) => {
    onChange(id, name, value);
  };

  const handleQuillChange = (content, delta, source, editor) => {
    handleInputChange("description", content);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleViewModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleWeightInputChange = (e) => {
    console.log("newWeight", e.target.value);
    const newWeight = parseInt(e.target.value, 10) || "";
    onWeightChange(id, newWeight);
  };

  return (
    <div className="planning__form__wrapper">
      <div className="planning__form__top__buttons">
        <span>
          <AiFillPlusCircle
            size={32}
            color={"green"}
            style={{
              cursor: "pointer",
            }}
            onClick={handleAddForm}
          />
          <IoIosRemoveCircle
            size={32}
            color={"red"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleRemoveForm(goalNumber)}
          />
        </span>
      </div>
      <div className="planning__form">
        <h5 className="planning__form__title">{`Goal ${goalNumber}`}</h5>
        <div className="planning__form__content">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Goal</label>
            <Input
              size="large"
              id="goal"
              name="goal"
              value={goal}
              style={{
                border: "2px solid black",
              }}
              onChange={(e) => onChange(id, "goal", e.target.value)}
              required
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item__half">
            <label className="fw-bold fs-6 d-flex justify-content-between align-items-center w-100">
              <span className="">Weight</span>
              {exceedsMax && (
                <small className="text-danger">
                  {`Weight cannot exceed available weight: ${availableWeight}`}
                </small>
              )}
            </label>

            <input
              type="number"
              min={1}
              max={availableWeight}
              size="large"
              id="weight"
              value={weight}
              style={{
                border: "2px solid black",
                width: "100%",
              }}
              onChange={handleWeightInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item">
            <label className="fw-bold fs-6">Section</label>
            <div className="goal__form__group">
              <Select
                showSearch
                size="large"
                placeholder="Select section"
                optionFilterProp="children"
                value={section && Number(section)}
                onChange={(value) => onChange(id, "section_id", value)}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={sectionData?.payload?.map((section) => ({
                  value: Number(section?.id),
                  label: section?.name,
                }))}
                bordered={false}
              />

              <span className="d-flex align-items-center px-2">
                <AiFillPlusCircle
                  size={25}
                  color={"green"}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleViewModal}
                />
              </span>
            </div>
          </div>

          <PlanningSection
            isModalOpen={isModalOpen}
            handleViewModal={handleViewModal}
          />

          <div className="d-flex flex-column gap-2 planning__form__item">
            <label className="fw-bold fs-6">Description</label>
            <ReactQuill
              theme="snow"
              id="body"
              placeholder=""
              value={description}
              onChange={handleQuillChange}
              style={{
                border: "2px solid black",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalForm;
