import React from "react";

import { Input, Select, Checkbox, Radio, Upload, Form, message, Alert } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { TextArea } = Input;

const TemplateRenderer = ({ question, handleSetAnswer }) => {
  const sharedInputProps = (props) => {
    return {
      style: {
        maxWidth: "80%",
        borderBottom: "5px solid var(--clr-primary)",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderRadius: props === "short" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = (maxNoOfFiles, maxSize, questionID) => ({
    name: "file",
    multiple: true,
    beforeUpload(file, fileList) {
      let isLessThanMaxSize = file.size / 1024 / 1024 < maxSize;
      if (!isLessThanMaxSize) {
        message.error(`File must be smaller than ${maxSize} MB!`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange(info) {
      console.log("info", info?.fileList);
      
      if (info.fileList.length > maxNoOfFiles) {
        message.error(`You can only upload ${maxNoOfFiles} files at a time!`);
        // Remove the extra file from the list
        info.fileList.pop();
      }
      handleSetAnswer(questionID, info.fileList.map((file) => file.originFileObj));
    },
    onDownload(file) {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    },
  });

  return (
    <div className="make__request__form__item">
      <div className="d-flex flex-column gap-1">
        <h5 className="">
          {question.questionTitle}
          {question.required && <span className="text-danger">*</span>}
        </h5>
        {question?.isQuestionDescription && (
          <small>{question.questionDescription}</small>
        )}
      </div>

      {question.questionType === "radio" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          hasFeedback
          rules={[
            {
              required: question?.required,
              message: "Please select an option",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              handleSetAnswer(question.id, e.target.value);
            }}
            className="d-flex flex-column gap-1"
            value={question?.answer}
          >
            {question.questionOptions.map((option, index) => (
              <Radio key={index} value={option} className=" fs-6">
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      ) : question.questionType === "checkbox" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          hasFeedback
          rules={[
            {
              required: question?.required,
              message: "Please select an option",
            },
          ]}
        >
          <Checkbox.Group
            onChange={(e) => {
              handleSetAnswer(question.id, e);
            }}
            className="d-flex flex-column gap-1"
            value={question?.answer}
          >
            {question.questionOptions.map((option, index) => (
              <Checkbox key={index} value={option} className=" fs-6">
                {option}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      ) : question.questionType === "paragraph" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          hasFeedback
          rules={[
            {
              required: question?.required,
              message: "Please input your answer",
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder={"Paragraph Answer Here"}
            value={question?.answer}
            onChange={(e) => {
              handleSetAnswer(question.id, e.target.value);
            }}
            {...sharedInputProps("paragraph")}
          />
        </Form.Item>
      ) : question?.questionType === "short" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          hasFeedback
          rules={[
            {
              required: question?.required,
              message: "Please input your answer",
            },
          ]}
        >
          <Input
            size="large"
            placeholder={"Short Answer Here"}
            value={question?.answer}
            onChange={(e) => {
              handleSetAnswer(question.id, e.target.value);
            }}
            {...sharedInputProps("short")}
          />
        </Form.Item>
      ) : question?.questionType === "dropdown" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          hasFeedback
          rules={[
            {
              required: question?.required,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            showSearch
            size="large"
            placeholder="Select an option"
            optionFilterProp="children"
            value={question?.answer}
            onChange={(value) => {
              handleSetAnswer(question.id, value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            {...sharedInputProps("short")}
            options={
              question?.questionOptions && question?.questionOptions.length > 0
                ? question?.questionOptions.map((option, index) => ({
                    label: option,
                    value: option,
                  }))
                : []
            }
          />
        </Form.Item>
      ) : question?.questionType === "file" ? (
        <Form.Item
          name={`${question.title}${question.id}`}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: question?.required,
              message: "Please upload a file",
            },
          ]}
        >
          <Dragger
            {...props(
              question?.file?.maximum_no_of_files,
              question?.file?.file_size,
              question?.id
            )}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from
              uploading company data or other banned files.
            </p>
            <Alert message={
              <div className="d-flex flex-column gap-1">
                <span>Maximum Number of files: {question?.file?.maximum_no_of_files}</span>
                <span>Maximum File Size: {question?.file?.file_size} MB</span>
              </div>
            } type="info" showIcon />
          </Dragger>
        </Form.Item>
      ) : null}
    </div>
  );
};

export default TemplateRenderer;
