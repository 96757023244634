import React from "react";
import EmergencyContact from "./EmergencyContact";
import MedicalDetails from "./MedicalDetails";
import styles from "../../../styles/employee/Profile.module.css";
import { FaFileMedicalAlt } from "react-icons/fa";

function MedicalPage() {
  return (
    <>
      <div className={`${styles.title} greeting_2`}>
        <div>
          <h4>Medical & Emergency Details</h4>
          <p>Review and update your medical information</p>
        </div>
        <FaFileMedicalAlt />
      </div>
      <div className="row p-3 pt-5">
        <div className="col-md-7 d-flex flex-column gap-5">
          <MedicalDetails />
          <EmergencyContact />
        </div>
      </div>
    </>
  );
}

export default MedicalPage;
