import React, { useState, useEffect } from "react";
import styles from "../../styles/permissions/CreateGroup.module.css";
import Permission from "./Permission";
import {
  useGetClientPermissions,
  useGetOneClientGroup,
} from "../../util/preferenceshookActions";
import { useDispatch } from "react-redux";
import {
  client_permissions,
  permissions_to_add,
  single_group,
} from "../../util/selectors/preferenceSelectors";

import {
  useShallowEqualSelector,
  usePreferenceCleanUp,
} from "../../util/hooks";
import { ThreeDots } from "react-loader-spinner";
import { editEmployeeGroup } from "../../redux/preference/preferenceActions";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import HocHeader from "../ui/HocHeader";

function EditGroup() {
  usePreferenceCleanUp();
  const { groupId } = useParams();

  const dispatch = useDispatch();
  const [enable_perm, setEnabledPrem] = useState(true);
  const [enable_single, setEnabledSingle] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useGetClientPermissions(enable_perm, setEnabledPrem);
  useGetOneClientGroup(enable_single, setEnabledSingle, groupId);

  const clientPermissions = useShallowEqualSelector(client_permissions);
  const permissionsToAdd = useShallowEqualSelector(permissions_to_add);
  const singleGroup = useShallowEqualSelector(single_group);

  useEffect(() => {
    if (groupId) {
      setEnabledSingle(true);
    }
  }, [groupId]);

  useEffect(() => {
    if (singleGroup.length) {
      setName(singleGroup[0].role);
    }
  }, [singleGroup]);

  function addGroup(e) {
    e.preventDefault();
    setLoading(true);
    const creds = {
      permission_ids: permissionsToAdd,
      name,
      role_id: parseInt(groupId),
    };

    editEmployeeGroup(dispatch, creds).then((res) => setLoading(false));
  }

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Permissions Management"
        module_name="Edit Group"
        module_description="
      HR Managers and privileged administrators can view all your new
      hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />

      <main className="main__wrapper">
        <div className="container-fluid p-3">
          <div className="app__section pb-3">
            <header className={styles.header}>
              <div className={styles.groupName}>
                <label htmlFor="group-name">
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="group-name"
                  id=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter a group name"
                />
              </div>
            </header>
            <main>
              <h3 className="mb-3">Permissions</h3>
              {clientPermissions?.length ? (
                <ul>
                  {clientPermissions.map((permission, index) => (
                    <Permission
                      key={index}
                      title={permission.module}
                      options={permission.menus}
                      addGroup={addGroup}
                      loading={loading}
                      update={true}
                    />
                  ))}

                  <Button
                    onClick={addGroup}
                    loading={loading}
                    size="large"
                    className="mt-3"
                  >
                    Update
                  </Button>
                </ul>
              ) : (
                <div className=" d-flex justify-content-center align-items-center ">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color={"var(--clr-primary)"}
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
            </main>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditGroup;
