import React, { useState, useEffect } from "react";
import HocHeader from "../../ui/HocHeader";
import { Button, Select } from "antd";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import { useGetLeaveApprovalSettings } from "../../../util/usershookActions";
import { setLeaveapprovalFlow } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const ApprovalSettings = () => {
  useUserCleanUp();
  const [users, setUsers] = useState([]);
  const [enabled, setEnabled] = useState(true);
  const [enabledapp, setEnabledapp] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState({
    level_1: "",
    level_2: "",
    level_3: "",
    observers: [],
  });
  const dispatch = useDispatch();

  const { isLoading } = useGetAllEmployee(enabled, setEnabled, "all"); //need for employee

  const all_users = useShallowEqualSelector(all_groups); // need

  const { data: app_flow, refetch } = useGetLeaveApprovalSettings(
    enabledapp,
    setEnabledapp
  );

  console.log({ app_flow });

  function handleSelect(value, name) {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  console.log("values", users);

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  function handleSubmit(e) {
    e.preventDefault();
    let creds = {
      leave_approval_workflow_id: 1,
      first_approver: "",
      second_approver: "",
      third_approver: "",
      observer_id: values.observers,
    };

    if (
      values.level_1 !== "" &&
      values.level_2 === "" &&
      values.level_3 === ""
    ) {
      creds = {
        leave_approval_workflow_id: 1,
        first_approver: values.level_1,
        second_approver: "",
        third_approver: "",
        observer_id: values.observers,
      };
    }

    if (
      values.level_1 !== "" &&
      values.level_2 !== "" &&
      values.level_3 === ""
    ) {
      creds = {
        leave_approval_workflow_id: 2,
        first_approver: values.level_1,
        second_approver: values.level_2,
        third_approver: "",
        observer_id: values.observers,
      };
    }
    if (
      values.level_1 !== "" &&
      values.level_2 !== "" &&
      values.level_3 !== ""
    ) {
      creds = {
        leave_approval_workflow_id: 3,
        first_approver: values.level_1,
        second_approver: values.level_2,
        third_approver: values.level_3,
        observer_id: values.observers,
      };
    }

    console.log({ creds });
    setLoading(true);
    setLeaveapprovalFlow(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }

  useEffect(() => {
    setDisabled(false);
    if (values.level_1 === "") {
      setDisabled(true);
    }

    if (
      (values.level_2 !== "" || values.level_3 !== "") &&
      values.level_1 === ""
    ) {
      setDisabled(true);
    }

    if (values.level_3 !== "" && values.level_2 === "") {
      setDisabled(true);
    }
  }, [values]);

  const FIX_OPTIONS = [
    <option value="Backup Employee">Backup Employee</option>,
    <option value="System HR">System HR</option>,
    <option value="Primary Line Manager">Primary Line Manager</option>,
    <option value="Secondary Line Manager">Secondary Line Manager</option>,
    <option value="Team Lead">Team Lead</option>,
  ];

  useEffect(() => {
    if (app_flow && Object.keys(app_flow).length) {
      setValues({
        level_1: app_flow?.payload[0]?.first_approver || "",
        level_2: app_flow?.payload[0]?.second_approver || "",
        level_3: app_flow?.payload[0]?.third_approver || "",
        observers: app_flow?.payload[0]?.observer_id?.length
          ? app_flow?.payload[0]?.observer_id
          : [],
      });
    }
  }, [app_flow]);

  return (
    <>
      <div>
        <HocHeader
          module_title="Leave Management"
          module_class="performance-header"
          module_name={"Settings"}
          module_description=" Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
          module_img="/assets/landing-page/Leave Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3 ">
            <h5>Approval Process Flow</h5>
            <form onSubmit={handleSubmit}>
              <div className="mt-5 d-flex flex-column gap-3">
                <div className="d-flex justify-content-between align-item-center">
                  <div className="">
                    <h6>
                      First level approval{" "}
                      <span className="text-danger">*</span>
                    </h6>
                    <p>
                      This helps you to select the first individual to approval
                      a leave request. This feild is required.
                    </p>
                  </div>
                  <div
                    style={{ width: "250px" }}
                    className="d-flex gap-2 justify-content-end align-items-center"
                  >
                    <Select
                      name="level_1"
                      id="level_1"
                      value={values.level_1}
                      size="large"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={(value) => handleSelect(value, "level_1")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {FIX_OPTIONS}
                      {users.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                    {isLoading ? <LoadingOutlined /> : null}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Second level approval</h6>
                    <p>
                      If this field is selected, it helps you to select the
                      second individual to approval a leave request after the
                      first approval process. This feild is optional.
                    </p>
                  </div>
                  <div
                    style={{ width: "250px" }}
                    className="d-flex gap-2 justify-content-end align-items-center"
                  >
                    <Select
                      name="level_2"
                      id="level_2"
                      value={values.level_2}
                      size="large"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={(value) => handleSelect(value, "level_2")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {FIX_OPTIONS}
                      {users.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                    {isLoading ? <LoadingOutlined /> : null}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-item-center">
                  <div className="">
                    <h6>Third level approval</h6>
                    <p>
                      if this field is selected, it helps to select the third
                      individual to approval a leave request after the first and
                      second approval process. This feild is also optional.
                    </p>
                  </div>
                  <div
                    style={{ width: "250px" }}
                    className="d-flex gap-2 justify-content-end align-items-center"
                  >
                    <Select
                      name="level_3"
                      id="level_3"
                      value={values.level_3}
                      size="large"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={(value) => handleSelect(value, "level_3")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {FIX_OPTIONS}
                      {users.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                    {isLoading ? <LoadingOutlined /> : null}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-item-center">
                  <div className="">
                    <h6> Approval observers</h6>
                    <p>
                      This are set of individual(s) that only recieves
                      notification about the leave process flow. This feild is
                      optional.
                    </p>
                  </div>
                  <div
                    style={{ width: "250px" }}
                    className="d-flex gap-2 justify-content-end align-items-center"
                  >
                    <Select
                      mode="multiple"
                      name="observers"
                      id="observers"
                      value={values.observers.length ? values.observers : []}
                      size="large"
                      style={{ width: "100%" }}
                      showSearch
                      onChange={(value) => handleSelect(value, "observers")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {users.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                    {isLoading ? <LoadingOutlined /> : null}
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      backgroundColor: "var(--clr-primary)",
                      marginTop: "2rem",
                    }}
                    disabled={disabled}
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default ApprovalSettings;
