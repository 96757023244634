import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function EduHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();
  
  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: em.institution_name,
          category: em.institution_category || "--",
          course: em.course || "--",
          degree: em.degree_obtained || "--",
          start: em.start_date.split("T")[0] || "--",
          end: em.end_date.split("T")[0] || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "INSTITUTION NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "INSTITUTION CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "COURSE",
      dataIndex: "course",
      key: "course",
    },
    {
      title: "DEGREE OBTAINED",
      dataIndex: "degree",
      key: "degree",
    },
    {
      title: "START DATE",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "END DATE",
      dataIndex: "end",
      key: "end",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} />;
}

export default EduHistory;
