import React from "react";
import styles from "../../../styles/hrms/Employee.module.css";
import { NavLink } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function ApprovalPending() {
  const active = styles.active;

  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Approval Management"
          module_title="Human Resource Information System"
          module_description="Manage the requests of employees for modifications to their data."
          // module_img="/assets/benefits.png"
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid pt-3">
          <div className="app__section py-4">
            <div>
              <ul className={styles.navigation}>
                <li>
                  <NavLink
                    to={"default"}
                    className={({ isActive }) =>
                      isActive ? active : undefined
                    }
                  >
                    Default
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"pending"}
                    className={({ isActive }) =>
                      isActive ? active : undefined
                    }
                  >
                    Pending
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"history"}
                    className={({ isActive }) =>
                      isActive ? active : undefined
                    }
                  >
                    History
                  </NavLink>
                </li>
              </ul>
            </div>
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
}

export default ApprovalPending;
