import React from "react";
import DefaultTable from "./DefaultTable";
import { useParams } from "react-router-dom";

function DefaultApproval() {
  const { id, fname, lname } = useParams();

  // console.log(useParams());

  return (
    <div className="container-fluid ">
      <h5>
        Default Approvals ({fname} {lname === "Admin" ? "" : lname})
      </h5>
      <div className="row">
        <div className="col-md-12">
          <DefaultTable emp_id={id} />
        </div>
      </div>
    </div>
  );
}

export default DefaultApproval;
