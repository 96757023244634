import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Checkbox,
  Button,
  Tag,
  Switch,
  InputNumber,
  Space,
  Alert,
  Drawer,
} from "antd";
import {
  MdRadioButtonChecked,
  MdCheckBox,
  MdKeyboardArrowDown,
  MdOutlineDeleteOutline,
  MdCheckBoxOutlineBlank,
  MdOutlineTextFields,
} from "react-icons/md";
import { IoIosRadioButtonOff, IoIosAddCircleOutline } from "react-icons/io";
import { RxTextAlignRight } from "react-icons/rx";
import { BsTextParagraph } from "react-icons/bs";
import { FaCloudUploadAlt, FaRegCopy } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "../../../hooks/ReorderIcon";

import {
  useGetRequestAuthorizers,
  useGetRequestPredefinedTemplates,
} from "../../../../util/usershookActions";
import { createRequestTemplate } from "../../../../redux/users/userActions";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ReadOnlyExpenseReportTemplate from "../../predefined-template/expense-report-template/ReadOnlyExpenseReportTemplate";

const { TextArea } = Input;

const CreateNewRequest = () => {
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPredefinedTemplate, setIsPredefinedTemplate] = useState(false);
  const [formTemplateObject, setFormTemplateObject] = useState({
    templateName: "",
    no_of_auth_levels: 3,
    authorizationLevels: {},
    requestObservers: [],
    templateType: "",
    requestSubject: "",
    questionsItems: [
      {
        questionTitle: "Question 1",
        questionDescription: "",
        questionType: "radio",
        questionOptions: ["Option 1"],
        file: {
          maximum_no_of_files: 0,
          file_size: 0,
        },
        required: false,
        isQuestionDescription: false,
        answer: "",
      },
    ],
  });

  const dispatch = useDispatch();

  const [editQuestionOption, setEditQuestionOption] = useState({
    id: "",
    option: "",
  });

  const { data: predefinedTemplates, isLoading: isLoadingPredefinedTemplates } =
    useGetRequestPredefinedTemplates(enabled, setEnabled);

  const { data: requestAuthorizers, isLoading: isLoadingRequestAuthorizers } =
    useGetRequestAuthorizers(enabled, setEnabled);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const dragControls = useDragControls();

  const sharedInputProps = (props) => {
    return {
      style: {
        width: props === "file" ? "10rem" : "20rem",
        marginLeft: 10,
        borderBottom: "5px solid var(--clr-primary)",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderRadius: props === "file" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  const tagsOptions = requestAuthorizers?.payload?.map((authorizer) => ({
    value: authorizer?.id,
    label: authorizer?.fullname,
  }));

  const levelsOptions = requestAuthorizers?.payload?.map((authorizer) => ({
    value: authorizer?.id,
    label: authorizer?.fullname,
  }));

  const questionTypeOptions = [
    {
      value: "radio",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdRadioButtonChecked size={20} />
          <span className="fs-6">Radio Button</span>
        </div>
      ),
    },
    {
      value: "checkbox",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdCheckBox size={20} />
          <span className="fs-6">Checkbox</span>
        </div>
      ),
    },
    {
      value: "short",
      label: (
        <div className="d-flex align-items-center gap-1">
          <RxTextAlignRight size={20} />
          <span className="fs-6">Short Answer</span>
        </div>
      ),
    },
    {
      value: "paragraph",
      label: (
        <div className="d-flex align-items-center gap-1">
          <BsTextParagraph size={20} />
          <span className="fs-6">Paragraph</span>
        </div>
      ),
    },
    {
      value: "dropdown",
      label: (
        <div className="d-flex align-items-center gap-1">
          <MdKeyboardArrowDown size={20} />
          <span className="fs-6">Dropdown</span>
        </div>
      ),
    },
    {
      value: "file",
      label: (
        <div className="d-flex align-items-center gap-1">
          <FaCloudUploadAlt size={20} />
          <span className="fs-6">File Upload</span>
        </div>
      ),
    },
  ];

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, padding: "0 5px" }}
      >
        {label}
      </Tag>
    );
  };

  const onChange = (e) => {
    setIsPredefinedTemplate(e.target.checked);
  };

  const handleCreateTemplate = async () => {
    setLoading(true);

    createRequestTemplate(dispatch, formTemplateObject).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        console.log("resLoad", res);
      }
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleAuthorizationLevelChange = (name, value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      authorizationLevels: {
        ...prevFormTemplateObject.authorizationLevels,
        [name]: value,
      },
    }));
  };

  const handleQuestionItemChange = (id, name, value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map(
        (question, i) => {
          if (i === id) {
            return {
              ...question,
              [name]: value,
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleQuestionOptionsChange = (id, value, index) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map(
        (question, i) => {
          if (i === id) {
            return {
              ...question,
              questionOptions: question.questionOptions.map((option, i) => {
                if (i === index) {
                  return value;
                }
                return option;
              }),
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleAddQuestionOptions = (id) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map(
        (question, i) => {
          if (i === id) {
            return {
              ...question,
              questionOptions: [
                ...question.questionOptions,
                `Option ${question.questionOptions.length + 1}`,
              ],
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleFileOptionsSettingsChange = (id, name, value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map(
        (question, i) => {
          if (i === id) {
            return {
              ...question,
              file: {
                ...question.file,
                [name]: value,
              },
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleRemoveQuestionOptions = (id, index) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.map(
        (question, i) => {
          if (i === id) {
            return {
              ...question,
              questionOptions: question.questionOptions.filter(
                (_, i) => i !== index
              ),
            };
          }
        }
      ),
    }));
  };

  const handleAddQuestionItem = () => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: [
        ...prevFormTemplateObject.questionsItems,
        {
          questionTitle: `Question ${
            prevFormTemplateObject.questionsItems.length + 1
          }`,
          questionDescription: "",
          questionType: "radio",
          questionOptions: ["Option 1"],
          file: {
            maximum_no_of_files: 0,
            file_size: 0,
          },
          required: false,
          isQuestionDescription: false,
          answer: "",
        },
      ],
    }));
  };

  const handleRemoveQuestionItem = (id) => {
    if (formTemplateObject.questionsItems.length === 1) {
      return;
    }
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: prevFormTemplateObject.questionsItems.filter(
        (question, i) => i !== id
      ),
    }));
  };

  const handleDuplicateQuestionItem = (id) => {
    setFormTemplateObject((prevFormTemplateObject) => {
      const questionToDuplicate = prevFormTemplateObject.questionsItems.find(
        (question, i) => i === id
      );

      const duplicatedQuestion = {
        ...questionToDuplicate,
        questionTitle: `${questionToDuplicate.questionTitle} (copy)`,
        questionDescription: questionToDuplicate.questionDescription,
        questionOptions: questionToDuplicate.questionOptions.map(
          (option) => `${option}`
        ),
        file: questionToDuplicate.file,
        required: questionToDuplicate.required,
        isQuestionDescription: questionToDuplicate.isQuestionDescription,
      };

      return {
        ...prevFormTemplateObject,
        questionsItems: [
          ...prevFormTemplateObject.questionsItems,
          duplicatedQuestion,
        ],
      };
    });
  };

  const handleEditQuestionOption = (id, option) => {
    setEditQuestionOption({ id, option });
    console.log("option", { option, id });
  };

  const handleChangeNoOfAuthLevels = (value) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      no_of_auth_levels: value,
    }));
  };

  const auth_levels = [...Array(formTemplateObject?.no_of_auth_levels)].map(
    (_, i) => {
      return (
        <div className="d-flex flex-column gap-2" key={i}>
          <label className="fw-bold fs-6">
            {`Level ${i + 1}`}{" "}
            {i === 0 && <span className="text-danger">*</span>}
          </label>
          <Select
            showSearch
            size="large"
            placeholder="Select a level"
            optionFilterProp="children"
            value={formTemplateObject[`level${i + 1}`]}
            onChange={(value) => {
              handleAuthorizationLevelChange(`level${i + 1}`, value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            style={{
              border: "2px solid black",
              borderRadius: "10px",
            }}
            options={levelsOptions}
          />
        </div>
      );
    }
  );

  useEffect(() => {
    console.log("formTemplateObject", formTemplateObject);
  }, [formTemplateObject]);

  const handleReorder = (newOrder) => {
    setFormTemplateObject((prevFormTemplateObject) => ({
      ...prevFormTemplateObject,
      questionsItems: newOrder,
    }));
  };

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3" id="create__template__body">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Create Form Template</h3>
            <p>Build your custom form card and set authorization levels</p>
          </div>
        </div>

        <form className="create__request__form">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Template Name:</label>
            <Input
              placeholder="Template Name"
              size="large"
              value={formTemplateObject?.templateName}
              onChange={(e) => {
                setFormTemplateObject((prevFormTemplateObject) => ({
                  ...prevFormTemplateObject,
                  templateName: e.target.value,
                }));
              }}
              style={{
                border: "2px solid black",
                width: "20rem",
              }}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">No of Authorization Levels</label>
              <InputNumber
                min={1}
                max={10}
                value={formTemplateObject?.no_of_auth_levels}
                size="large"
                style={{
                  border: "2px solid black",
                  width: "20rem",
                }}
                onChange={handleChangeNoOfAuthLevels}
              />
            </div>
            <span className="">Add Authorization Levels</span>
            <div className="create__request__form__grid">{auth_levels}</div>
          </div>
          <div className="d-flex flex-column gap-2">
            <label className="fw-bold fs-6">
              Select Request Observers: <small>(optional)</small>
            </label>
            <div className="d-flex gap-2 justify-content-end align-items-center">
              <Select
                mode="multiple"
                size="large"
                placeholder="Add people that can observe request process"
                tagRender={tagRender}
                value={formTemplateObject?.requestObservers}
                onChange={(value) => {
                  setFormTemplateObject((prevFormTemplateObject) => ({
                    ...prevFormTemplateObject,
                    requestObservers: value,
                  }));
                }}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                  width: "100%",
                }}
                options={tagsOptions}
              />
              {isLoadingRequestAuthorizers ? <LoadingOutlined /> : null}
            </div>
          </div>
          <div className="">
            <Checkbox onChange={onChange} checked={isPredefinedTemplate}>
              <span className="fw-bold fs-6">
                Select from Predefined templates
              </span>
            </Checkbox>
          </div>

          {isPredefinedTemplate ? (
            <>
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Select Template:</label>
                <div className="d-flex gap-2 justify-content-start align-items-center">
                  <Select
                    size="large"
                    placeholder="Select a template"
                    value={formTemplateObject?.templateType}
                    onChange={(value) => {
                      if (value) {
                        const { questionsItems, ...rest } = formTemplateObject;
                        setFormTemplateObject({
                          ...rest,
                          templateType: value,
                          predefined_template: isPredefinedTemplate,
                        });
                      }
                    }}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    options={[
                      {
                        label: "--",
                        value: "",
                      },
                      ...predefinedTemplates?.payload?.map((template) => ({
                        label: template.template_name,
                        value: template.template_type,
                      })),
                    ]}
                  />
                  {isLoadingPredefinedTemplates ? <LoadingOutlined /> : null}
                </div>
              </div>

              <Space>
                <Button
                  type="primary"
                  size="large"
                  onClick={showDrawer}
                  disabled={formTemplateObject?.templateType ? false : true}
                >
                  Preview
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    width: "fit-content",
                  }}
                  onClick={handleCreateTemplate}
                  loading={loading}
                >
                  Submit
                </Button>
              </Space>
            </>
          ) : (
            <>
              <div className="d-flex flex-column gap-3 mt-3">
                <Alert
                  message="Important Information"
                  description="A form item called Request Title will be generated for every form"
                  type="info"
                  style={{
                    width: "45rem",
                    maxWidth: "50rem",
                  }}
                  showIcon
                />
                <Reorder.Group
                  axis="y"
                  onReorder={handleReorder}
                  values={formTemplateObject.questionsItems}
                  className="d-flex flex-column gap-5 mt-5"
                >
                  {formTemplateObject.questionsItems.map(
                    (question, questionIndex) => (
                      <Reorder.Item
                        className="d-flex gap-3 relative"
                        id={questionIndex}
                        value={question}
                        key={questionIndex}
                        dragListener={true}
                        dragControls={dragControls}
                      >
                        <div className="form__item">
                          <div className="d-flex justify-content-center">
                            <ReorderIcon dragControls={dragControls} />
                          </div>
                          <div className="d-flex justify-content-between align-items-end gap-4">
                            <Input
                              placeholder="Question"
                              size="large"
                              value={question?.questionTitle}
                              onChange={(e) => {
                                handleQuestionItemChange(
                                  questionIndex,
                                  "questionTitle",
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: "5px solid var(--clr-primary)",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                width: "70%",
                              }}
                            />
                            <Select
                              showSearch
                              placeholder="Select an option"
                              size="large"
                              optionFilterProp="children"
                              value={question?.questionType}
                              onChange={(value) => {
                                handleQuestionItemChange(
                                  questionIndex,
                                  "questionType",
                                  value
                                );
                              }}
                              onSearch={onSearch}
                              filterOption={filterOption}
                              defaultValue={"radio"}
                              style={{
                                width: "15rem",
                                marginLeft: 10,
                                borderBottom: "5px solid var(--clr-primary)",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderRadius: "10px",
                              }}
                              options={questionTypeOptions}
                            />
                          </div>
                          {question?.isQuestionDescription && (
                            <TextArea
                              rows={3}
                              placeholder="Question Description"
                              value={question?.questionDescription}
                              onChange={(e) => {
                                handleQuestionItemChange(
                                  questionIndex,
                                  "questionDescription",
                                  e.target.value
                                );
                              }}
                              {...sharedInputProps("paragraph")}
                            />
                          )}

                          <div className="form__item__answer__section">
                            {question?.questionType === "radio" ? (
                              <div className="">
                                <Space direction="vertical">
                                  {question?.questionOptions?.map(
                                    (option, optionIndex) => (
                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{ width: "25rem" }}
                                        key={optionIndex}
                                      >
                                        <div className="d-flex align-items-center gap-2">
                                          <IoIosRadioButtonOff size={20} />
                                          <span className="fs-6">
                                            {editQuestionOption?.id ===
                                              questionIndex &&
                                            editQuestionOption?.option ===
                                              optionIndex ? (
                                              <Input
                                                autoFocus
                                                placeholder={`Option ${
                                                  optionIndex + 1
                                                }`}
                                                size="large"
                                                value={option}
                                                onChange={(e) => {
                                                  handleQuestionOptionsChange(
                                                    questionIndex,
                                                    e.target.value,
                                                    optionIndex
                                                  );
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    handleAddQuestionOptions(
                                                      questionIndex
                                                    );
                                                    handleEditQuestionOption(
                                                      questionIndex,
                                                      optionIndex + 1
                                                    );
                                                  }
                                                }}
                                                onBlur={() =>
                                                  handleEditQuestionOption(
                                                    questionIndex,
                                                    undefined
                                                  )
                                                }
                                                {...sharedInputProps()}
                                              />
                                            ) : (
                                              <label
                                                className="fs-6"
                                                style={{
                                                  cursor: "pointer",
                                                  width: "20rem",
                                                }}
                                                onClick={() =>
                                                  handleEditQuestionOption(
                                                    questionIndex,
                                                    optionIndex
                                                  )
                                                }
                                              >
                                                {option}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                        {question?.questionOptions?.length >
                                          1 && (
                                          <IoCloseOutline
                                            size={25}
                                            cursor={"pointer"}
                                            onClick={() =>
                                              handleRemoveQuestionOptions(
                                                questionIndex,
                                                optionIndex
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="d-flex align-items-center gap-2"
                                    onClick={() => {
                                      handleAddQuestionOptions(questionIndex);
                                      handleEditQuestionOption(
                                        questionIndex,
                                        question?.questionOptions?.length
                                      );
                                    }}
                                  >
                                    <IoIosRadioButtonOff size={20} />
                                    <span className="fs-6">Add Option</span>
                                  </div>
                                </Space>
                              </div>
                            ) : question?.questionType === "checkbox" ? (
                              <div className="">
                                <Space direction="vertical">
                                  {question?.questionOptions?.map(
                                    (option, optionIndex) => (
                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{ width: "25rem" }}
                                        key={optionIndex}
                                      >
                                        <div className="d-flex align-items-center gap-2">
                                          <MdCheckBoxOutlineBlank size={20} />
                                          <span className="fs-6">
                                            {editQuestionOption?.id ===
                                              questionIndex &&
                                            editQuestionOption?.option ===
                                              optionIndex ? (
                                              <Input
                                                autoFocus
                                                placeholder={`Option ${
                                                  optionIndex + 1
                                                }`}
                                                size="large"
                                                value={option}
                                                onChange={(e) => {
                                                  handleQuestionOptionsChange(
                                                    questionIndex,
                                                    e.target.value,
                                                    optionIndex
                                                  );
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    handleAddQuestionOptions(
                                                      questionIndex
                                                    );
                                                    handleEditQuestionOption(
                                                      questionIndex,
                                                      optionIndex + 1
                                                    );
                                                  }
                                                }}
                                                onBlur={() =>
                                                  handleEditQuestionOption(
                                                    questionIndex,
                                                    undefined
                                                  )
                                                }
                                                {...sharedInputProps()}
                                              />
                                            ) : (
                                              <label
                                                className="fs-6"
                                                style={{
                                                  cursor: "pointer",
                                                  width: "20rem",
                                                }}
                                                onClick={() =>
                                                  handleEditQuestionOption(
                                                    questionIndex,
                                                    optionIndex
                                                  )
                                                }
                                              >
                                                {option}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                        {question?.questionOptions?.length >
                                          1 && (
                                          <IoCloseOutline
                                            size={25}
                                            cursor={"pointer"}
                                            onClick={() =>
                                              handleRemoveQuestionOptions(
                                                questionIndex,
                                                optionIndex
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="d-flex align-items-center gap-2"
                                    onClick={() => {
                                      handleAddQuestionOptions(questionIndex);
                                      handleEditQuestionOption(
                                        questionIndex,
                                        question?.questionOptions?.length
                                      );
                                    }}
                                  >
                                    <MdCheckBoxOutlineBlank size={20} />
                                    <span className="fs-6">Add Option</span>
                                  </div>
                                </Space>
                              </div>
                            ) : question?.questionType === "dropdown" ? (
                              <div className="d-flex flex-column gap-2">
                                {question?.questionOptions?.map(
                                  (option, optionIndex) => (
                                    <div
                                      key={optionIndex}
                                      className="d-flex align-items-center fs-6 justify-content-between"
                                      style={{ width: "25rem" }}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <span className="">
                                          {optionIndex + 1}.
                                        </span>
                                        {editQuestionOption?.id ===
                                          questionIndex &&
                                        editQuestionOption?.option ===
                                          optionIndex ? (
                                          <Input
                                            autoFocus
                                            placeholder={`Option ${
                                              optionIndex + 1
                                            }`}
                                            size="large"
                                            value={option}
                                            onChange={(e) => {
                                              handleQuestionOptionsChange(
                                                questionIndex,
                                                e.target.value,
                                                optionIndex
                                              );
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                handleAddQuestionOptions(
                                                  questionIndex
                                                );
                                                handleEditQuestionOption(
                                                  questionIndex,
                                                  optionIndex + 1
                                                );
                                              }
                                            }}
                                            onBlur={() =>
                                              handleEditQuestionOption(
                                                questionIndex,
                                                undefined
                                              )
                                            }
                                            {...sharedInputProps()}
                                          />
                                        ) : (
                                          <label
                                            className="fs-6"
                                            style={{
                                              cursor: "pointer",
                                              width: "20rem",
                                            }}
                                            onClick={() =>
                                              handleEditQuestionOption(
                                                questionIndex,
                                                optionIndex
                                              )
                                            }
                                          >
                                            {option}
                                          </label>
                                        )}
                                      </div>
                                      {question?.questionOptions?.length >
                                        1 && (
                                        <IoCloseOutline
                                          size={25}
                                          cursor={"pointer"}
                                          onClick={() =>
                                            handleRemoveQuestionOptions(
                                              questionIndex,
                                              optionIndex
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                                <div
                                  className="d-flex align-items-center gap-2"
                                  onClick={() => {
                                    handleAddQuestionOptions(questionIndex);
                                    handleEditQuestionOption(
                                      questionIndex,
                                      question?.questionOptions?.length
                                    );
                                  }}
                                >
                                  <span className="">
                                    {question?.questionOptions?.length + 1}.
                                  </span>
                                  <span className="fs-6">Add Option</span>
                                </div>
                              </div>
                            ) : question?.questionType === "file" ? (
                              <div className="d-flex flex-column gap-3">
                                <div className="d-flex justify-content-between align-items-center gap-3 fs-6">
                                  <label className="fs-6">
                                    Maximum number of files
                                  </label>
                                  <Select
                                    size="large"
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                    value={question?.file?.maximum_no_of_files}
                                    onChange={(value) => {
                                      handleFileOptionsSettingsChange(
                                        questionIndex,
                                        "maximum_no_of_files",
                                        value
                                      );
                                    }}
                                    {...sharedInputProps("file")}
                                    options={[
                                      {
                                        value: 1,
                                        label: 1,
                                      },
                                      {
                                        value: 5,
                                        label: 5,
                                      },
                                      {
                                        value: 10,
                                        label: 10,
                                      },
                                    ]}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center gap-3 fs-6">
                                  <label className="fs-6">
                                    Maximum file size
                                  </label>
                                  <Select
                                    size="large"
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                    value={question?.file?.file_size}
                                    onChange={(value) => {
                                      handleFileOptionsSettingsChange(
                                        questionIndex,
                                        "file_size",
                                        value
                                      );
                                    }}
                                    {...sharedInputProps("file")}
                                    options={[
                                      {
                                        value: 1,
                                        label: "1 MB",
                                      },
                                      {
                                        value: 5,
                                        label: "5 MB",
                                      },
                                      {
                                        value: 10,
                                        label: "10 MB",
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            ) : question?.questionType === "short" ? (
                              <Input
                                size="large"
                                value={"Short Answer Here"}
                                {...sharedInputProps()}
                                disabled
                              />
                            ) : question?.questionType === "paragraph" ? (
                              <TextArea
                                rows={4}
                                value={"Paragraph Answer Here"}
                                maxLength={6}
                                {...sharedInputProps("paragraph")}
                                disabled
                              />
                            ) : null}
                          </div>

                          <div className="form__item__footer">
                            <div className="d-flex gap-3">
                              <FaRegCopy
                                size={22}
                                cursor={"pointer"}
                                onClick={() =>
                                  handleDuplicateQuestionItem(questionIndex)
                                }
                              />
                              <MdOutlineDeleteOutline
                                size={25}
                                cursor={"pointer"}
                                onClick={() =>
                                  handleRemoveQuestionItem(questionIndex)
                                }
                              />
                            </div>
                            <div className="form__item__footer__divider"></div>
                            <div className="d-flex align-items-center gap-3">
                              <span className="fs-6">Required</span>
                              <Switch
                                defaultChecked={question.required}
                                onChange={(checked) =>
                                  handleQuestionItemChange(
                                    questionIndex,
                                    "required",
                                    checked
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form__item__side__menu">
                          <IoIosAddCircleOutline
                            size={30}
                            cursor={"pointer"}
                            onClick={handleAddQuestionItem}
                          />
                          <MdOutlineTextFields
                            size={30}
                            cursor={"pointer"}
                            onClick={() =>
                              handleQuestionItemChange(
                                questionIndex,
                                "isQuestionDescription",
                                !question.isQuestionDescription
                              )
                            }
                          />
                        </div>
                      </Reorder.Item>
                    )
                  )}
                </Reorder.Group>
              </div>

              <div className="d-flex justify-content-center gap-3 mt-3 w-100">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    width: "fit-content",
                  }}
                  onClick={handleCreateTemplate}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </>
          )}

          <Drawer
            title={formTemplateObject?.templateType
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
            width={"60%"}
            onClose={onClose}
            open={open}
            extra={
              <Space>
                <Button danger size="large" onClick={onClose}>
                  Close
                </Button>
              </Space>
            }
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
          >
            <div className="">
              {formTemplateObject?.templateType === "expense-report" ? (
                <ReadOnlyExpenseReportTemplate
                  requestSubject={formTemplateObject?.requestSubject}
                  templatePreview={true}
                />
              ) : null}
            </div>
          </Drawer>
        </form>
      </div>
    </div>
  );
};

export default CreateNewRequest;
