import { useEffect, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import styles from "../../styles/permissions/Permission.module.css";
import EachPermCard from "./EachPermCard";
import {
  addPermission,
  removePermission,
} from "../../redux/preference/preferenceActions";
import { useDispatch } from "react-redux";
import { Button } from "antd";

function Permission(props) {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState([props.initialShowContent]);

  const clickHandler = () => {
    setShowContent((prevShowContent) => {
      const keyIndex = prevShowContent.indexOf(props.index);

      if (keyIndex > -1) {
        const updatedShowContent = [...prevShowContent];
        updatedShowContent.splice(keyIndex, 1);
        return updatedShowContent;
      } else {
        return [...prevShowContent, props.index];
      }
    });
  };

  useEffect(() => {
    console.log("showContent", showContent);
  }, [showContent]);

  const handleSelectAll = (e) => {
    const all_permissions = props.options;
    if (e.target.checked) {
      all_permissions.forEach((obj) => {
        obj.permissions.forEach((perm) => {
          addPermission(dispatch, perm.id);
        });
      });
    } else {
      all_permissions.forEach((obj) => {
        obj.permissions.forEach((perm) => {
          removePermission(dispatch, perm.id);
        });
      });
    }
  };

  return (
    <li className={styles.permission}>
      <div className={styles.title}>
        <h4>{props.title}</h4>
        <div className={styles.right}>
          <input type="checkbox" name="select-all" onChange={handleSelectAll} />
          <button onClick={clickHandler}>
            {showContent.includes(props.index) ? <BiMinus /> : <BiPlus />}
          </button>
        </div>
      </div>
      {showContent.includes(props.index) && (
        <>
          <div className={styles.options}>
            {props.options.map(
              ({ menu, permissions, users, total_permissions }, index) => (
                <EachPermCard
                  title={menu}
                  roles={permissions}
                  users={users}
                  total_permissions={total_permissions}
                  key={index}
                />
              )
            )}
          </div>
          {props.update ? (
            <div className="d-flex justify-content-end bg-white ">
              <Button
                onClick={props.addGroup}
                loading={props.loading}
                size="large"
                className="my-3 me-3"
              >
                Update
              </Button>
            </div>
          ) : null}
        </>
      )}
    </li>
  );
}

export default Permission;
