import React from "react";

import styles from "../../../styles/employee/Profile.module.css";
import { FaPiggyBank } from "react-icons/fa";
import FinancialDet from "./FinancialDet";
import PensionDet from "./PensionDet";

function FinancialPage() {
  return (
    <>
      <div className={`${styles.title} greeting_2`}>
        <div>
          <h4>Financial Details</h4>
          <p>Review and update your financial information</p>
        </div>
        <FaPiggyBank />
      </div>
      <div className="row p-3 pt-5">
        <div className="col-md-6 d-flex flex-column gap-5">
          <FinancialDet />
        </div>
        <div className="col-md-6 d-flex flex-column gap-5">
          <PensionDet />
        </div>
      </div>
    </>
  );
}

export default FinancialPage;
