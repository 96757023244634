import React, { useState } from "react";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";
import { Doughnut } from "react-chartjs-2";

import HocHeader from "../ui/HocHeader";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";

import {
  useGetLeaveMgtPersonalOverview,
  useGetLeaveMgtSummary,
} from "../../util/usershookActions";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CanAccess from "../hoc/CanAccess";

import { colorArray } from "../../util/helpers";
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function LeaveOverview() {
  useUserCleanUp();
  // Chart.register(...registerables);
  const [enabled, setEnabled] = useState(true);

  const { data: personal_overview, isLoading: isLoadingPersonalOverview } =
    useGetLeaveMgtPersonalOverview(enabled, setEnabled);

  const { data: summary, isLoading: isLoadingSummary } = useGetLeaveMgtSummary(
    enabled,
    setEnabled
  );

  const cardBgColors = [
    "#D7FFEC94",
    "#CD602330",
    "#62D9FE38",
    "#21231E14",
    "#FE626238",
    "#D7E2FF",
    "#E8F5FF",
    "#FFECEC",
  ];

  const jobRoleData = {
    labels: summary?.payload?.leave_request_by_job_role.map((role) => {
      return `${role.job_role}: ${role.job_role_count}`;
    }),
    datasets: [
      {
        label: "No. of Requests",
        data: summary?.payload?.leave_request_by_job_role?.map((role) => {
          return role.job_role_count;
        }),
        backgroundColor: colorArray,
        borderColor: summary?.payload?.leave_request_by_job_role?.map((role) => {
          return "#ffffff";
        }),
      },
    ],
  };


  const data = {
    labels: [
      `Male: ${summary?.payload?.leave_request_by_gender["Male"]}`,
      `Female: ${summary?.payload?.leave_request_by_gender["Female"]}`,
    ],
    datasets: [
      {
        label: "No. of Requests",
        data: [
          summary?.payload?.leave_request_by_gender["Male"],
          summary?.payload?.leave_request_by_gender["Female"],
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_name="Leave Management Module"
        module_description=" Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
        module_img="/assets/landing-page/Leave Management.png"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4 mb-4">
            <h5>Personal Overview</h5>

            {isLoadingPersonalOverview ? (
              <SkeletonUI />
            ) : (
              <div className="row mt-4">
                {Object.keys(personal_overview?.payload).map((key, index) => {
                  const randomIndex = Math.floor(
                    Math.random() * cardBgColors.length
                  );
                  const randomColor = cardBgColors[randomIndex];
                  return (
                    <div className="col-md-3 mb-4" key={index}>
                      <div
                        className="insight"
                        style={{ backgroundColor: randomColor }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiPulseLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h6 className="text-capitalize">
                                {key.split("_").join(" ")}
                              </h6>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                                {personal_overview?.payload[key]}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </section>

          <CanAccess
            isRoute={false}
            can_access={"view-leave-mgt-overview"}
          >
            <section className="app__section py-4 mb-4">
              <h5>Summary</h5>

              {isLoadingSummary ? (
                <SkeletonUI />
              ) : (
                <div className="row mt-4">
                  {summary?.payload &&
                    Object.keys(summary?.payload?.summary)?.map(
                      (key, index) => {
                        const randomIndex = Math.floor(
                          Math.random() * cardBgColors.length
                        );
                        const randomColor = cardBgColors[randomIndex];

                        return (
                          <div className="col-md-3 mb-4" key={index}>
                            <div
                              className="insight"
                              style={{ backgroundColor: randomColor }}
                            >
                              <div className="d-flex align-items-center gap-4">
                                <div>
                                  <RiPulseLine />
                                </div>
                                <div>
                                  <div className="insight-title">
                                    <h6 className="text-capitalize">
                                      {key.split("_").join(" ")}
                                    </h6>
                                  </div>
                                  <div className="insight-content">
                                    <h2 className="mb-0">
                                      {summary?.payload?.summary[key]}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              )}
            </section>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    Leave request distribution by gender
                  </h5>
                  {isLoadingSummary ? (
                    <SkeletonUI />
                  ) : summary?.payload?.leave_request_by_gender["Male"] > 0 ||
                    summary?.payload?.leave_request_by_gender["Female"] > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={data} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    Leave request distribution by job role
                  </h5>
                  {isLoadingSummary ? (
                    <SkeletonUI />
                  ) : summary?.payload?.leave_request_by_gender["Male"] > 0 ||
                    summary?.payload?.leave_request_by_gender["Female"] > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={jobRoleData} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
        </div>
      </main>
    </>
  );
}

export default LeaveOverview;
