import { Button } from "antd";
import { PlusOutlined, PlusCircleFilled } from "@ant-design/icons";

const AddTaskButton = ({ noBackground }) => {
  return (
    <>
      {!noBackground ? (
        <Button size="large" icon={<PlusCircleFilled size={30} />}>
          Add Task
        </Button>
      ) : (
        <Button
          size="large"
          icon={<PlusOutlined size={30} />}
          type="text"
          style={{
            width: "fit-content",
          }}
        >
          Add Task
        </Button>
      )}
    </>
  );
};

export default AddTaskButton;
