import React, { useState, useEffect } from "react";
import styles from "../../styles/permissions/Permission.module.css";
import { BiPlus, BiMinus } from "react-icons/bi";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import {
  addPermission,
  removePermission,
} from "../../redux/preference/preferenceActions";

import {
  permissions_to_add,
  single_group,
} from "../../util/selectors/preferenceSelectors";

import { useParams } from "react-router";
import { editEmployeeGroup } from "../../redux/preference/preferenceActions";

import { useShallowEqualSelector } from "../../util/hooks";
import { Button } from "antd";

function EachPermCard({ roles, title, users, total_permissions }) {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { groupId } = useParams();

  const permissionsToadd = useShallowEqualSelector(permissions_to_add);
  const singleGroup = useShallowEqualSelector(single_group);

  function showPerm(e) {
    e.preventDefault();
    setShowModal((preValue) => !preValue);
  }

  function handleCheck(e) {
    const is_checked = e.target.checked;
    const id = e.target.value;
    if (id && is_checked) {
      addPermission(dispatch, id);
    } else if (id && !is_checked) {
      removePermission(dispatch, id);
    }
  }

  useEffect(() => {
    if (singleGroup.length) {
      setName(singleGroup[0].role);
    }
  }, [singleGroup]);

  function addGroup(e) {
    e.preventDefault();
    setLoading(true);
    const creds = {
      permission_ids: permissionsToadd,
      name,
      role_id: parseInt(groupId),
    };

    editEmployeeGroup(dispatch, creds).then((res) => setLoading(false));
  }

  return (
    <div className={styles.option}>
      <div className={styles.top}>
        <div>
          <h3>{title}</h3>
        </div>
        <Button onClick={showPerm}>
          {showModal ? <BiMinus /> : <BiPlus />}
        </Button>
      </div>
      <div className={styles.bottom}>
        <p>{total_permissions} permission(s)</p>
      </div>
      {showModal && (
        <Modal
          open={showModal}
          centered
          onCancel={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowModal(false)}>
              Return
            </Button>,

            <Button
              onClick={addGroup}
              loading={loading}
              key="submit"
              type="primary"
              // size="large"
              className="mt-3"
            >
              Update
            </Button>,
          ]}
          title={title}
        >
          <div className={styles.content}>
            {roles.map((role, index) => (
              <div className={styles.role} key={index} title={role.description}>
                {/* {/* {console.log("ids", role.id)} */}
                {/* {console.log("perms", permissionsToadd)} */}
                {/* {console.log(permissionsToadd?.includes(role.id))} */}
                <input
                  type="checkbox"
                  name={role.action}
                  id={`${role.action}-${role.menu}`}
                  value={role.id}
                  onChange={handleCheck}
                  checked={permissionsToadd?.includes(role.id)}
                />
                <label htmlFor={`${role.action}-${role.menu}`}>
                  {role.action}
                </label>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default React.memo(EachPermCard);
