import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GoalForm from "./GoalForm";
import { useDispatch } from "react-redux";
import {
  addPerformanceGoal,
  updatePerformanceGoal,
} from "../../../../redux/users/userActions";
import {
  useGetPerformanceSections,
  useGetPerformanceGoals,
  useGetPerformanceSettings,
} from "../../../../util/usershookActions";

import { colorArray } from "../../../../util/helpers";
import CurrentPerformanceYear from "../currentPerformanceYear";

ChartJS.register(ArcElement, Tooltip, Legend);

const GoalSettings = () => {
  const [addGoalLoading, setAddGoalLoading] = useState(false);
  const [formList, setFormList] = useState([
    {
      id: 1,
      goal: "",
      section_id: null,
      weight: "",
      description: "",
    },
  ]);
  const [title, setTitle] = useState("");
  const [planId, setPlanId] = useState();

  const [exceedsMax, setExceedsMax] = useState(false);
  const [totalWeight, setTotalWeight] = useState(0);
  const [availableWeight, setAvailableWeight] = useState(100);
  const [selectedSection, setSelectedSection] = useState([]);

  const [enabled, setEnabled] = useState(true);

  const { data: performanceSettings, refetch } = useGetPerformanceSettings(
    enabled,
    setEnabled
  );
  const { data: sectionData } = useGetPerformanceSections(enabled, setEnabled);

  const { data: goalsList, refetch: goalsRefetch } = useGetPerformanceGoals(enabled, setEnabled);

  const dispatch = useDispatch();

  const data = {
    labels: [
      `Used Weight: ${totalWeight}%`,
      `Available Weight: ${availableWeight}%`,
    ],
    datasets: [
      {
        label: "% of Weight",
        data: [totalWeight, availableWeight],
        backgroundColor: ["#D9D9D9", "#4F4FE5"],
        borderColor: ["#D9D9D9", "#4F4FE5"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  const dataPie = {
    labels: selectedSection?.map((section) => {
      return `${section?.name}: ${section?.count}`;
    }),
    datasets: [
      {
        label: "No. of Sections",
        data: selectedSection?.map((section) => section?.count),
        backgroundColor: colorArray,
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    setAvailableWeight(100 - totalWeight);
  }, [totalWeight]);

  const handleAddForm = () => {
    if (availableWeight > 0) {
      const newForm = {
        id: formList.length + 1,
        goal: "",
        section_id: null,
        description: "",
        weight: "",
      };
      setTotalWeight(totalWeight + newForm.weight);
      setFormList([...formList, newForm]);
    } else {
      console.log("Available weight is 0");
    }
  };

  const handleWeightChange = (id, newWeight) => {
    if (
      newWeight <=
      availableWeight + formList.find((form) => form.id === id).weight
    ) {
      const updatedFormData = formList.map((form) =>
        form.id === id ? { ...form, weight: newWeight } : form
      );
      setFormList(updatedFormData);

      const newTotalWeight = updatedFormData.reduce(
        (total, form) => total + form.weight,
        0
      );
      setTotalWeight(newTotalWeight);
      setExceedsMax(false);
    } else {
      setExceedsMax(true);
      console.log("Weight exceeds available weight");
    }
  };

  useEffect(() => {
    const requestBody = {
      title,
      goals: formList.map((form) => ({
        goal: form.goal,
        section_id: form.section_id,
        weight: form.weight,
        description: form.description,
      })),
    };
    console.log("requestBody", requestBody);
  }, [formList, title]);

  const handleRemoveForm = (goalNumber) => {
    if (formList.length === 1) return;
    setFormList((prevList) =>
      prevList.filter((form) => form.id !== goalNumber)
    );
  };

  const handleFormChange = (id, name, value) => {
    const updatedFormData = formList.map((form) =>
      form.id === id ? { ...form, [name]: value } : form
    );

    if (name === "section_id") {
      setSelectedSection((prevSelectedSection) => {
        let count;

        const existingSectionIndex = prevSelectedSection.findIndex(
          (section) => section.goalId === id
        );

        const prevSectionIndex = prevSelectedSection.findIndex(
          (section) => section.id === value
        );

        if (prevSectionIndex !== -1) {
          count = prevSelectedSection[prevSectionIndex].count + 1;
          return prevSelectedSection.map((section, index) =>
            index === prevSectionIndex ? { ...section, count } : section
          );
        } else {
          count = 1;

          if (existingSectionIndex !== -1) {
            return prevSelectedSection.map((section, index) =>
              index === existingSectionIndex
                ? {
                    id: value,
                    goalId: id,
                    name: sectionData?.payload?.find(
                      (section) => section.id === value
                    )?.name,
                    count,
                  }
                : section
            );
          }
          return [
            ...prevSelectedSection,
            {
              id: value,
              goalId: id,
              name: sectionData?.payload?.find(
                (section) => section.id === value
              )?.name,
              count,
            },
          ];
        }
      });
    }
    setFormList(updatedFormData);
  };

  const handleAddGoals = (e) => {
    e.preventDefault();
    setAddGoalLoading(true);
    const creds = {
      title,
      goals: formList.map((form) => ({
        goal: form.goal,
        section_id: form.section_id.toString(),
        weight: form.weight,
        description: form.description,
      })),
    };
    addPerformanceGoal(dispatch, creds).then(() => {
      setAddGoalLoading(false);
      setFormList((prevList) => prevList.filter((form) => form.id === 1));
      setTitle("");
      goalsRefetch();
    });
  };

  const handleUpdateGoals = (e) => {
    e.preventDefault();
    setAddGoalLoading(true);
    const creds = {
      performance_tool: "MBO",
      title,
      goals: formList.map((form) => ({
        id: form.id,
        goal: form.goal,
        section_id: form.section_id.toString(),
        weight: form.weight,
        description: form.description,
      })),
    };
    updatePerformanceGoal(dispatch, creds, planId).then(() => {
      setAddGoalLoading(false);
    });
  };

  useEffect(() => {
    if (goalsList?.payload?.goals?.length) {
      setTitle(goalsList?.payload?.title);
      setPlanId(goalsList?.payload?.plan_id);
      setFormList(goalsList?.payload?.goals);
      setTotalWeight(() => {
        let total = 0;
        goalsList?.payload?.goals.forEach((goal) => {
          total += Number(goal.weight);
        });
        return total;
      });
    }
  }, [goalsList?.payload]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="card__border__bottom">
          <div className="">
            <h3 className="h4">
              Set Goal(s)
              <CurrentPerformanceYear />
            </h3>
            <p>Organizational goals can be created and initiated</p>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-5 flex-wrap ">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">Title</label>
              <Input
                size="large"
                id="title"
                value={title}
                style={{
                  width: "20rem",
                  border: "2px solid black",
                }}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <div
              className="d-flex "
              style={{
                width: "auto",
              }}
            >
              <div
                className="mx-auto"
                style={{
                  width: "100%",
                  minHeight: "11rem",
                }}
              >
                <Pie options={options} data={dataPie} />
              </div>

              <div
                className="mx-auto"
                style={{
                  width: "100%",
                  minHeight: "11rem",
                }}
              >
                <Doughnut data={data} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {formList
            .sort((a, b) => a.id - b.id)
            .reverse()
            .map((form) => (
              <GoalForm
                key={form.id}
                goalNumber={form.id}
                handleAddForm={handleAddForm}
                handleRemoveForm={handleRemoveForm}
                id={form.id}
                goal={form.goal}
                section={form.section_id}
                weight={form.weight}
                description={form.description}
                availableWeight={availableWeight}
                exceedsMax={exceedsMax}
                onChange={handleFormChange}
                onWeightChange={handleWeightChange}
                sectionData={sectionData}
              />
            ))}

          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              disabled={
                Object.values(formList).some((value) => value === "") ||
                title === "" ||
                totalWeight < 100
              }
              loading={addGoalLoading}
              onClick={
                goalsList?.payload?.goals?.length
                  ? handleUpdateGoals
                  : handleAddGoals
              }
            >
              {goalsList?.payload?.goals?.length ? "Update" : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalSettings;
