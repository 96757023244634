import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { Button } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import {
  updateUserFinancialsinfo,
  approveUserMedicalInfo,
} from "../../../redux/users/userActions";
import DashboardCard from "../../dashboard/DashboardCard";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import Warning from "../../ui/Warning";

const BANKS = require("../../../util/nigerian-banks.json");

const STATES = require("../../../util/nigeria-state-and-lgas.json");

export default function PensionDet({ init_det }) {
  useUserCleanUp();
  let init_details;

  const dispatch = useDispatch();

  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);

  if (userProfile?.financials && Object.keys(userProfile?.financials).length) {
    init_details = { ...userProfile?.financials };
  } else {
    init_details = {
      pfa_custodian: "",
      pfa_id: "",
      tax_state: "",
      housing_fund_id: "",
      medical_number: "",
    };
  }

  //   console.log({ init_details });
  const callbackFn = () => {
    if (init_det) {
      approveUserMedicalInfo(dispatch, values).then((res) => {
        if (res?.status === "success") {
          // clearForm();
        }
        setEditMode(!editMode);
      });
    } else {
      setSaveSpinner(true);
      values.user_id = userProfile?.id;

      updateUserFinancialsinfo(dispatch, {
        ...values,
        bank_id: 1,
      }).then((res) => {
        setSaveSpinner(false);
        if (res?.status === "success") {
          clearForm();
          setEditMode(!editMode);
        }
      });
    }
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Pension Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit pension information"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>PFA Custodian</label>
            <p>{userProfile?.financials?.pfa_custodian || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>PFA ID</label>
            <p>{userProfile?.financials?.pfa_id || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Tax State</label>
            <p>{userProfile?.financials?.tax_state || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>House Fund ID</label>
            <p>{userProfile?.financials?.housing_fund_id || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Medical Number</label>
            <p>{userProfile?.financials?.medical_number || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard
      title={"Edit Pension Information"}
      icon={<MdOutlineAttachMoney />}
    >
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Financial" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="pfa_custodian"> PFA Custodian</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.pfa_custodian ||
                  (back_errors.pfa_custodian && back_errors.pfa_custodian[0])}
              </small>
            </div>
            <select
              name="pfa_custodian"
              onChange={handleChange}
              value={values.pfa_custodian}
              id="pfa_custodian"
            >
              <option value="">--</option>
              {BANKS.map((bank) => (
                <option key={bank.id} value={bank.name}>
                  {bank.name.toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="col-4">
            <label htmlFor="pfa_id">PFA ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.pfa_id || (back_errors.pfa_id && back_errors.pfa_id[0])}
              </small>
            </div>
            <input
              type="number"
              name="pfa_id"
              onChange={handleChange}
              value={values.pfa_id}
              id="pfa_id"
            />
          </div>
          <div className="col-4">
            <label htmlFor="tax_state"> Tax State</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.tax_state ||
                  (back_errors.tax_state && back_errors.tax_state[0])}
              </small>
            </div>
            <select
              name="tax_state"
              onChange={handleChange}
              value={values.tax_state}
              id="tax_state"
            >
              <option value="">--</option>
              {STATES.map((state, index) => (
                <option key={index} value={state.state}>
                  {state.state.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="housing_fund_id">House Fund ID</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.housing_fund_id ||
                  (back_errors.housing_fund_id &&
                    back_errors.housing_fund_id[0])}
              </small>
            </div>
            <input
              type="text"
              name="housing_fund_id"
              onChange={handleChange}
              value={values.housing_fund_id}
              id="housing_fund_id"
            />
          </div>
          <div className="col-4">
            <label htmlFor="medical_number">Medical Number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.medical_number ||
                  (back_errors.medical_number && back_errors.medical_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="medical_number"
              onChange={handleChange}
              value={values.medical_number}
              id="medical_number"
            />
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger py-0"
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn " loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}
