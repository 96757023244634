import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";

import {
  // user_spinner,
  user_errors,
} from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserNOKInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import { useOutletContext } from "react-router-dom";

export default function ApproveNOK() {
  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);
  const [details, setEnabled] = useOutletContext();

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserNOKInfo(
        dispatch,
        { ...values, notify: false, expect: exempt },
        exempt
      ).then((res) => {
        setLoadingAppN(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  }

  function handleApproveNotify() {
    setLoadingApp(true);
    approveUserNOKInfo(dispatch, values).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  }
  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  if (details[0]) {
    init_details = { ...details[0], except: [], notify: true };
  }

  const callbackFn = () => {
    if (details[0]) {
      approveandnotify();
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };

  const confirm = (id) => {
    setLoading(true);
    rejectUserInfo(dispatch, { ...values, info_type: "next_of_kin" }).then(
      (res) => {
        Modal.destroyAll();
        setLoading(false);
        setShowModal(false);
        if (res?.status === "success") {
          setEnabled(true);
        }
      }
    );
  };

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    // alert("here");
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <DashboardCard
      title={"Next of Kin Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row m-0">
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="name">
              Name <span>*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.name || (back_errors.name && back_errors.name[0])}
              </small>
            </div>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              id="name"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="name"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="email">Email</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.email || (back_errors.email && back_errors.email[0])}
              </small>
            </div>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
              id="email"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="email"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column mt-3">
            <label htmlFor="relationship">Relationship</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.name || (back_errors.name && back_errors.name[0])}
              </small>
            </div>
            <select
              name="relationship"
              onChange={handleChange}
              value={values.relationship}
              id="relationship"
            >
              <option value="">--</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
            </select>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="relationship"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column mt-3">
            <label htmlFor="mobile_number">Mobile Number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.mobile_number ||
                  (back_errors.mobile_number && back_errors.mobile_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="mobile_number"
              onChange={handleChange}
              value={values.mobile_number}
              id="mobile_number"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="mobile_number"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column mt-3">
            <label htmlFor="telephone">Telephone</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.telephone ||
                  (back_errors.telephone && back_errors.telephone[0])}
              </small>
            </div>
            <input
              type="number"
              name="telephone"
              onChange={handleChange}
              value={values.telephone}
              id="telephone"
            />

            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="telephone"
              onChange={handleCheckBox}
            />
          </div>

          <div className="col-12  approval_side_checkbox d-flex flex-column mt-3">
            <label htmlFor="home_address">Home Address</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.home_address ||
                  (back_errors.home_address && back_errors.home_address[0])}
              </small>
            </div>
            <textarea
              name="home_address"
              onChange={handleChange}
              value={values.telephone}
              id="home_address"
            >
              {values.home_address}
            </textarea>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="home_address"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn "
            loading={loadingApp}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>
          <Button
            size="large"
            className="myBtn "
            loading={loadingAppN}
            onClick={approve}
            disabled={disabled}
          >
            Approve
          </Button>

          <Button
            size="large"
            className="myBtn "
            style={{ backgroundColor: "red", color: "white" }}
            onClick={warning}
            loading={loading}
          >
            Reject
          </Button>
        </div>

        {/* <Modal
          title={`Reject request`}
          centered
          onOk={() => confirm(values?.id)}
          onCancel={modalHandler}
          open={showModal}
          cancelText="No"
          okText="Yes"
        >
          <p className="h6">Are you sure to reject this request?</p>
        </Modal> */}
      </form>
    </DashboardCard>
  );
}
