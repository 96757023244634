import { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { notificationError, notificationSuccess } from "./helpers";

import { user_message, user_status } from "./selectors/userSelectors";
import {
  preference_message,
  preference_status,
} from "./selectors/preferenceSelectors";

import { userCleanUp } from "./../redux/users/userActions";
import { preferenceCleanUp } from "./../redux/preference/preferenceActions";
import { useLocation } from "react-router-dom";
import isEqual from "lodash.isequal";

function useForm(
  callback,
  initState = {},
  validate,
  reinitialise,
  setreinitialise
) {
  const [values, setValues] = useState(initState);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const parentPath = location.pathname.split("/")[3];

  const prevInitState = useRef(initState);

  useEffect(() => {
    if (parentPath === "approval-management") {
      if (!isEqual(initState, prevInitState.current)) {
        setValues(initState);
        prevInitState.current = initState;
      }
    }
  }, [initState, parentPath]);

  const handleChange = (e, alter, name, value) => {
    if (alter) {
      setValues((prevState) => {
        return { ...prevState, [name]: value };
      });
    } else {
      setValues((prevState) => {
        return { ...prevState, [e?.target.name]: e?.target.value };
      });
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const get_error = validate(values);

    if (Object.keys(get_error).length === 0) {
      callback();
      setErrors(get_error);
    } else {
      setErrors(get_error);
    }
  };

  function clearForm() {
    setValues((prev) => {
      let rep_obj = { ...prev };
      for (var key of Object.keys(rep_obj)) {
        rep_obj[key] = initState[key];
      }
      return rep_obj;
    });
  }

  useEffect(() => {
    if (reinitialise) {
      setValues(initState);
      setreinitialise();
      setErrors({});
    }
  }, [reinitialise, initState, setreinitialise]);

  return { handleChange, handleSubmit, errors, values, clearForm };
}

function useFormNotAuth(callback, initState = {}) {
  const [values, setValues] = useState(initState);

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    callback();
  };

  return { handleChange, handleSubmit, values };
}

function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clear, []);
}

function useTimeout(callback, delay) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}

function useUsersNotification() {
  const [delay, setDelay] = useState(false);
  const status = useShallowEqualSelector(user_status);
  const message = useShallowEqualSelector(user_message);

  useEffect(() => {
    if (delay && status === "success") {
      notificationSuccess(message);
    } else if (delay && status === "error") {
      notificationError(message);
    }
  }, [status, message, delay]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 500);
  }, []);
}

function usePreferenceNotification() {
  const [delay, setDelay] = useState(false);
  const status = useShallowEqualSelector(preference_status);
  const message = useShallowEqualSelector(preference_message);

  useEffect(() => {
    if (delay && status === "success") {
      notificationSuccess(message);
    } else if (delay && status === "error") {
      notificationError(message);
    }
  }, [status, message, delay]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 500);
  }, []);
}

function useUserCleanUp() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      setTimeout(() => {
        userCleanUp(dispatch);
      }, 500);
    };
  }, [dispatch]);
}
function usePreferenceCleanUp() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      setTimeout(() => {
        preferenceCleanUp(dispatch);
      }, 500);
    };
  }, [dispatch]);
}

function useOnlineStatus() {
  const [online, setOnline] = useState(navigator.onLine);

  useEventListener("online", () => setOnline(navigator.onLine));
  useEventListener("offline", () => setOnline(navigator.onLine));

  return online;
}

function useEventListener(eventType, callback, element = window) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;
    const handler = (e) => callbackRef.current(e);
    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}

function useCloseMobileSibar() {
  const location = useLocation();
  useEffect(() => {
    const sidebar = document.querySelector(".performance__nav");
    if (sidebar) {
      sidebar.classList.remove("mobile-show-sidebar");
    }
    // const handleRouteChange = () => {
    //   console.log("Route has changed:", window.location.pathname);
    // };

    // window.addEventListener("hashchange", handleRouteChange);

    // return () => {
    //   window.removeEventListener("hashchange", handleRouteChange);
    // };
  }, [location]);
}

function useScript(url) {
  return useAsync(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;

    return new Promise((resolve, reject) => {
      script.addEventListener("load", resolve);
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  }, [url]);
}

function useAsync(callback, dependencies = []) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [value, setValue] = useState();

  const callbackMemoized = useCallback(() => {
    setLoading(true);
    setError(undefined);
    setValue(undefined);
    callback()
      .then(setValue)
      .catch(setError)
      .finally(() => setLoading(false));
  }, dependencies);

  useEffect(() => {
    callbackMemoized();
  }, [callbackMemoized]);

  return { loading, error, value };
}
export {
  useForm,
  useAsync,
  useScript,
  useFormNotAuth,
  useShallowEqualSelector,
  useDebounce,
  useTimeout,
  useUsersNotification,
  useUserCleanUp,
  useOnlineStatus,
  useEventListener,
  usePreferenceCleanUp,
  usePreferenceNotification,
  useCloseMobileSibar,
};
